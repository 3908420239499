<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="11"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <!-- Form -->
                  <v-form
                    @submit.prevent
                    v-model="formValid"
                    class="d-flex flex-column"
                    ref="form"
                  >
                    <h1 class="primary--text mb-5">{{ this.theatername }}</h1>
                    <h4 class="primary--text mb-5">gegevens theater</h4>

                    <v-card
                      class="pa-6 mb-10"
                      flat
                    >
                      <v-row class="mt-0">
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <!-- Name -->
                          <v-text-field
                            class="primary--text"
                            label="naam"
                            v-model="editTheaterForm.name"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <!-- Geactiveerd -->
                          <v-checkbox
                            v-model="editTheaterForm.isMember"
                            label="klant DIP"
                            class="checkbox primary--text ma-0"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <!-- Selecteer datum -->
                          <date-picker
                            v-if="datePickerDataLoaded"
                            v-model="editTheaterForm.isMemberSince"
                            :passedDate="editTheaterForm.isMemberSince"
                            rules="date"
                          ></date-picker>
                        </v-col>
                      </v-row>

                      <!-- Branchevereniging bijwerken -->
                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-autocomplete
                            label="selecteer branchevereniging"
                            v-model="editTheaterForm.branchAssociationId"
                            :items="branchAssociations"
                            outlined
                            clearable
                            dense
                          ></v-autocomplete>
                        </v-col>
                      </v-row>

                      <!-- Ticketing systeem bijwerken -->
                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-autocomplete
                            label="selecteer ticketing systeem"
                            v-model="editTheaterForm.ticketSystemId"
                            :items="ticketSystems"
                            outlined
                            clearable
                            dense
                          ></v-autocomplete>
                        </v-col>
                      </v-row>

                      <!-- Kaartstanden bijwerken -->
                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-autocomplete
                            label="kaartstanden bijwerken"
                            v-model="editTheaterForm.ticketLinkType"
                            :items="ticketLinkTypeOptions"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                      </v-row>

                      <!-- Ticketmatic ClientName -->
                      <v-row v-if="editTheaterForm.ticketLinkType == 3">
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-text-field
                            class="primary--text"
                            label="ticketmatic clientname"
                            v-model="editTheaterForm.ticketClientName"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <!-- Voorwaarden DIP geaccepteerd -->
                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-checkbox
                            label="voorwaarden DIP geaccepteerd"
                            v-model="editTheaterForm.agreedToTerms"
                            class="checkbox primary--text ma-0"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <!-- Publieksmonitor tonen -->
                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-checkbox
                            label="toon publieksmonitor"
                            v-model="editTheaterForm.hasPublicMonitorAccess"
                            class="checkbox primary--text ma-0"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <!-- Benchmark tonen -->
                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-checkbox
                            label="toon benchmark"
                            v-model="editTheaterForm.hasBenchmarkAccess"
                            class="checkbox primary--text ma-0"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-card>

                    <h4 class="primary--text mb-5">adres</h4>
                    <v-card
                      class="pa-6 mb-1"
                      flat
                    >
                      <v-row class="mt-0">
                        <v-col
                          cols="8"
                          class="py-0"
                        >
                          <!-- Straat -->
                          <v-text-field
                            label="straat"
                            v-model="editTheaterForm.street"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="4"
                          class="py-0"
                        >
                          <!-- Nummer -->
                          <v-text-field
                            label="huisnummer"
                            v-model="editTheaterForm.number"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="4"
                          class="py-0"
                        >
                          <!-- Postcode -->
                          <v-text-field
                            label="postcode"
                            v-model="editTheaterForm.zipCode"
                            :rules="formValidationRules.zipCodeRequired"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="8"
                          class="py-0"
                        >
                          <!-- Plaats -->
                          <v-text-field
                            label="plaats"
                            v-model="editTheaterForm.city"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <!-- Provincie -->
                          <v-autocomplete
                            label="provincie"
                            v-model="editTheaterForm.province"
                            :items="provincieOptions"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card>

                    <p class="grey--text text--lighten-1 mt-0">
                      <small
                        ><i>{{ this.lastEditedBy }}</i></small
                      >
                    </p>

                    <v-alert
                      v-if="formError != ''"
                      text
                      color="error"
                      >{{ formError }}</v-alert
                    >

                    <v-btn
                      rounded
                      large
                      elevation="0"
                      @click="submit"
                      :loading="formLoading"
                      color="secondary"
                      class="text-lowercase mb-5 align-self-start"
                      >Opslaan
                      <v-icon
                        small
                        right
                        >mdi-content-save</v-icon
                      ></v-btn
                    >
                  </v-form>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';
import DatePicker from '../../../components/DatePicker';

export default {
  name: 'AdminTheatersEdit',
  props: ['theaterId'],
  components: {
    Help,
    DatePicker,
  },
  data() {
    return {
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      date: null,
      menu: false,
      datePickerDataLoaded: false,
      editTheaterForm: {
        name: '',
        isMember: false,
        isMemberSince: '',
        branchAssociationId: null,
        ticketSystemId: null,
        ticketLinkType: '',
        ticketClientName: '',
        agreedToTerms: false,
        hasPublicMonitorAccess: false,
        hasBenchmarkAccess: false,
        street: '',
        number: '',
        zipCode: '',
        city: '',
        province: '',
      },
      lastEditedBy: '',
      provincieOptions: [],
      branchAssociations: [],
      ticketSystems: [],
      ticketLinkTypeOptions: [
        { value: 1, text: 'handmatig' },
        { value: 2, text: 'api' },
        { value: 3, text: 'ticketmatic' },
      ],
      formValidationRules: {},
      theatername: '',
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'theaters',
          disabled: false,
          href: '/admin/theaters',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getTheater();
    this.getProvinces();
    this.getBranchAssociations();
    this.getTicketSystems();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getTheater: function () {
      const vm = this;

      ApiService.get('/Theaters/Get', {
        theaterId: this.theaterId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            this.datePickerDataLoaded = true;

            const theater = response.data.result;
            this.breadcrumbs[2].text = theater.name;
            this.breadcrumbs[2].href = '/admin/theaters/' + theater.id;
            this.theatername = theater.name;
            this.editTheaterForm.name = theater.name;
            this.editTheaterForm.isMember = theater.isMember;
            this.editTheaterForm.isMemberSince = functions.getDateView(theater.isMemberSince);

            if (theater.branchAssociation != null) {
              this.editTheaterForm.branchAssociationId = theater.branchAssociation.id;
            }

            if (theater.ticketSystem != null) {
              this.editTheaterForm.ticketSystemId = theater.ticketSystem.id;
            }

            this.editTheaterForm.ticketLinkType = theater.ticketLinkType;
            this.editTheaterForm.ticketClientName = theater.ticketClientName;
            this.editTheaterForm.agreedToTerms = theater.agreedToTerms;
            this.editTheaterForm.street = theater.street;
            this.editTheaterForm.number = theater.number;
            this.editTheaterForm.zipCode = theater.zipCode;
            this.editTheaterForm.city = theater.city;
            this.editTheaterForm.province = theater.province.id;
            this.editTheaterForm.hasPublicMonitorAccess = theater.hasPublicMonitorAccess;
            this.editTheaterForm.hasBenchmarkAccess = theater.hasBenchmarkAccess;
            this.lastEditedBy = theater.lastEditedBy;

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getProvinces: function () {
      ApiService.get('/Provinces/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.provincieOptions.push({
              value: element.id,
              text: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getBranchAssociations: function () {
      ApiService.get('/BranchAssociations/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.branchAssociations.push({
              value: element.id,
              text: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getTicketSystems: function () {
      ApiService.get('/TicketSystems/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.ticketSystems.push({
              value: element.id,
              text: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.editTheaterForm;

        console.log(form.branchAssociationId);

        ApiService.put('/Theaters/Update', {
          id: this.theaterId,
          name: form.name,
          street: form.street,
          number: form.number,
          zipCode: form.zipCode,
          city: form.city,
          provinceId: form.province,
          isMember: form.isMember,
          isMemberSince: form.isMemberSince == '' ? null : functions.getDateDatabase(form.isMemberSince),
          branchAssociationId: form.branchAssociationId != null ? form.branchAssociationId : null,
          ticketSystemId: form.ticketSystemId != null ? form.ticketSystemId : null,
          ticketLinkType: form.ticketLinkType,
          ticketClientName: form.ticketLinkType == 3 ? form.ticketClientName : '',
          agreedToTerms: form.agreedToTerms,
          hasPublicMonitorAccess: form.hasPublicMonitorAccess,
          hasBenchmarkAccess: form.hasBenchmarkAccess,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();

            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch((error) => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    editTheaterForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
