<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row
          align="center"
          class="px-3 mb-4"
        >
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Een nieuwe producent toevoegen?</span>
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
              :to="{ name: 'AdminProducersAdd' }"
            >
              Aanmaken nieuwe producent
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="14"
            ></help>
          </v-col>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field
                v-model="tableProducersFilter.name"
                label="op naam zoeken"
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-text-field
                v-model="tableProducersFilter.type"
                label="zoek op type"
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-autocomplete
                v-model="tableProducersFilter.status"
                :items="[
                  { value: 1, text: 'ja' },
                  { value: 6, text: 'nee' },
                  { value: '', text: 'ja/nee' },
                ]"
                item-text="text"
                label="actief"
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tableProducersFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-on="on"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="primary--text">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="tableProducersFilter.name"
                      label="op naam zoeken"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                    <v-text-field
                      v-model="tableProducersFilter.type"
                      label="zoek op type"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                    <v-autocomplete
                      v-model="tableProducersFilter.status"
                      :items="[
                        { value: 1, text: 'ja' },
                        { value: 6, text: 'nee' },
                        { value: '', text: 'ja/nee' },
                      ]"
                      item-text="text"
                      label="actief"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tableProducersFilterDialog = false"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      outlined
                      dark
                      @click="clearFilterProducers()"
                      >reset filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableProducersHeaders"
          :items="tableProducersFiltered"
          :mobile-breakpoint="0"
          :loading="tableProducersLoading"
          :page.sync="tableProducersPaging.page"
          :items-per-page="tableProducersPaging.itemsPerPage"
          hide-default-footer
          @page-count="tableProducersPaging.pageCount = $event"
          @click:row="goToDetail"
          class="table-action row-is-clickable"
          fixed-header
          :height="tableProducersFiltered.length > 10 ? '55vh' : ''"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              v-if="item.status == 1"
              fab
              x-small
              depressed
              color="warning"
              class="ml-1"
              :to="{
                name: 'AdminProducersDetail',
                params: { producerId: item.id },
              }"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
            <v-btn
              v-if="item.status == 1"
              fab
              x-small
              depressed
              color="info"
              class="ml-1"
              :to="{
                name: 'AdminProducersEdit',
                params: { producerId: item.id },
              }"
            >
              <v-icon color="primary">mdi-square-edit-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="item.status == 1"
              fab
              x-small
              depressed
              color="error"
              class="ml-1"
              @click="(deleteItem = item), (deleteItemDialog = true);"
            >
              <v-icon color="#fff">mdi-delete</v-icon>
            </v-btn>
          </template>

          <v-data-footer></v-data-footer>
        </v-data-table>

        <!-- Table settings -->
        <v-row
          class="text-center pt-2"
          align="center"
          v-if="!tableProducersLoading"
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption primary--text ma-0 pa-0">
              totaal: {{ this.tableProducersFiltered.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableProducersFiltered.length > 25"
              @click="tableProducersPaging.itemsPerPage = 25"
              :color="tableProducersPaging.itemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tableProducersFiltered.length > 25"
              @click="tableProducersPaging.itemsPerPage = 50"
              :color="tableProducersPaging.itemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tableProducersFiltered.length > 50"
              @click="tableProducersPaging.itemsPerPage = 100"
              :color="tableProducersPaging.itemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tableProducersPaging.pageCount > 1"
              v-model="tableProducersPaging.page"
              :length="tableProducersPaging.pageCount"
              :total-visible="7"
              :elevation="0"
            ></v-pagination>
          </v-col>

          <!-- Table download -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex justify-center justify-sm-end"
          >
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
            >
              <vue-excel-xlsx
                class="v-btn font-weight-bold text-lowercase"
                :data="tableProducersFiltered"
                :columns="downloadExportColumns"
                :filename="'dip-producenten-export'"
                :sheetname="'Producenten'"
                >exporteer selectie <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.deleteItem.name }} verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je {{ this.deleteItem.name }} wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(deleteItemDialog = false), (deleteItem = '');"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemProducer()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
      elevation="0"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';

import Help from '../../../components/Help.vue';

export default {
  name: 'AdminProducer',
  components: {
    Help,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producenten',
          disabled: false,
          href: '/admin/producenten',
          bold: true,
        },
      ],
      producers: [],
      tableProducersFiltered: [],
      tableProducersLoading: true,
      tableProducersHeaders: [
        { text: 'id', value: 'id' },
        { text: 'naam', value: 'name' },
        { text: 'plaats', value: 'city' },
        { text: 'provincie', value: 'province.name' },
        { text: 'type', value: 'type.name' },
        { text: 'impresariaat koppeling', value: 'agencyLinks' },
        { text: 'branchevereniging', value: 'brancheAssociationName' },
        { text: 'actief', value: 'statusString' },
        { text: '', value: 'action', sortable: false, align: 'end' },
      ],
      tableProducersFilter: {
        name: '',
        type: '',
        status: 1,
      },
      tableProducersPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableProducersFilterDialog: false,
      downloadExportColumns: [
        { label: 'ID', field: 'id' },
        { label: 'PNL-ID', field: 'pnlId' },
        { label: 'naam', field: 'name' },
        { label: 'straat', field: 'street' },
        { label: 'huisnummer', field: 'number' },
        { label: 'postcode', field: 'zipCode' },
        { label: 'plaats', field: 'city' },
        { label: 'soort producent', field: 'type.name' },
        { label: 'voorwaarden', field: 'agreedToTerms' },
        { label: 'provincie', field: 'provinceName' },
        { label: 'branchevereniging', field: 'brancheAssociationName' },
        { label: 'actief', field: 'statusString' },
      ],
      deleteItemDialog: false,
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
    };
  },
  mounted() {
    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tableProducersFilter,
    });
    this.tableProducersFilter = this.getFilter(this.$options.name);

    this.getProducers();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'AdminProducersDetail',
        params: { producerId: row.id },
      });
    },
    getProducers: function () {
      const vm = this;
      ApiService.get('/Producers/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.producers = response.data.result;

          for (var i = 0; i < this.producers.length; i++) {
            vm.$set(this.producers[i], 'agreedToTerms', this.producers[i].agreedToTerms ? 'Ja' : 'Nee');
            vm.$set(this.producers[i], 'provinceName', this.producers[i].province.name);
            vm.$set(this.producers[i], 'statusString', this.producers[i].status == 1 ? 'Ja' : 'Nee');

            var agenciesString = '';
            for (var j = 0; j < this.producers[i].agencies.length; j++) {
              if (j > 0) {
                agenciesString += ', ';
              }
              agenciesString += this.producers[i].agencies[j].name;
            }

            vm.$set(this.producers[i], 'agencyLinks', agenciesString);

            if (this.producers[i].branchAssociation != null) {
              vm.$set(this.producers[i], 'brancheAssociationName', this.producers[i].branchAssociation.name);
            }
          }

          this.tableProducersFiltered = this.producers;

          // Paging
          this.checkFilter({
            viewName: this.$options.name + '-paging',
            filterObject: this.tableProducersPaging,
          });
          this.tableProducersPaging = this.getFilter(this.$options.name + '-paging');

          this.filterProducers();

          this.tableProducersLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterProducers: function () {
      const vm = this;
      this.tableProducersFiltered = this.producers.filter(function (producer) {
        return (
          cleanWords(producer.name).indexOf(cleanWords(vm.tableProducersFilter.name)) !== -1 &&
          cleanWords(producer.type.name).indexOf(cleanWords(vm.tableProducersFilter.type)) !== -1 &&
          (producer.status == vm.tableProducersFilter.status || vm.tableProducersFilter.status == '')
        );
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterProducers: function () {
      this.tableProducersFilter.name = '';
      this.tableProducersFilter.city = '';
      this.tableProducersFilter.status = 1;
      this.tableProducersFilterDialog = false;
    },
    deleteItemProducer: function () {
      ApiService.delete('/Producers/Delete', {
        producerId: this.deleteItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableProducersLoading = true;
            this.getProducers();

            this.snackbarText = this.deleteItem.name + ' is verwijderd';
            this.deleteItemDialog = false;
            this.deleteItem = '';
            this.snackbarColor = 'success';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('storage', ['getFilter']),
  },
  beforeDestroy() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tableProducersFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tableProducersPaging,
    });
  },
  watch: {
    tableProducersFilter: {
      handler: 'filterProducers',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
