import { render, staticRenderFns } from "./PerformanceAddBulk.vue?vue&type=template&id=f8d92596"
import script from "./PerformanceAddBulk.vue?vue&type=script&lang=js"
export * from "./PerformanceAddBulk.vue?vue&type=script&lang=js"
import style0 from "./PerformanceAddBulk.vue?vue&type=style&index=0&id=f8d92596&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports