import Vue from 'vue';
import Router from 'vue-router';
import Login from '../views/Auth/Login';
import ForgotPassword from '../views/Auth/ForgotPassword';
import ResetPassword from '../views/Auth/ResetPassword';
import TwoFactorAuth from '../views/Auth/TwoFactorAuth';
import Account from '../views/Account/Index';
import store from '../store/index';

Vue.use(Router);

// overwrite router push because of NavigationDuplicated error
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

function defaultRoutes() {
  router.addRoute({
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  });

  router.addRoute({
    path: '/wachtwoord-vergeten',
    name: 'forgotPassword',
    component: ForgotPassword,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  });

  router.addRoute({
    path: '/wachtwoord-instellen/:token',
    name: 'resetPassword',
    component: ResetPassword,
    props: true,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  });

  router.addRoute({
    path: '/twofactorauth',
    name: 'twofactorauth',
    component: TwoFactorAuth,
    props: true,
    meta: {
      public: true,
      onlyWhenLoggedOut: false,
    },
  });

  router.addRoute({
    path: '/account',
    name: 'account',
    component: Account,
    props: false,
    meta: {
      public: false,
      onlyWhenLoggedOut: false,
    },
  });
}

const createRouter = () =>
  new Router({
    mode: 'history',
    // routes: []
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
  defaultRoutes();
}

defaultRoutes();

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut);
  const loggedIn = store.state.auth.loggedIn;

  if (!isPublic && !loggedIn) {
    if (to.fullPath == '/') {
      return next({ path: '/login' });
    } else {
      return next({
        path: '/login',
        query: { redirect: to.fullPath }, // Store the full path to redirect the user to after login
      });
    }
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/');
  }

  next();
});

export default router;
