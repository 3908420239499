<template>
  <div>
    <h2 class="primary--text mb-2">voor welke voorstellingen geldt de afspraak?</h2>

    <!-- Form -->
    <v-form
      @submit.prevent
      v-model="formValid"
      class="d-flex flex-column"
      ref="form"
    >
      <v-card
        class="py-6 pb-0 px-3 mb-10"
        flat
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-autocomplete
                label="selecteer een productie"
                v-on:change="changeProduction"
                v-model="form.production.number"
                :items="productionOptions"
                :rules="formValidationRules.required"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-autocomplete
                label="uitvoeringen"
                v-model="form.performances"
                :items="performanceOptions"
                :rules="formValidationRules.required"
                multiple
                chips
                deletable-chips
                hide-selected
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ApiService from '../../../../services/api.service';
import { TokenService } from '../../../../services/storage.service';
import functions from '../../../../services/functions.service';

export default {
  props: ['form'],
  data() {
    return {
      formValid: false,
      formError: '',
      formValidationRules: {},
      performanceOptions: [],
      productions: [],
      productionOptions: [],
    };
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getData();
  },
  methods: {
    validateForm() {
      this.$refs.form.validate();
    },
    getData() {
      this.getProductions();
    },
    getProductions() {
      if (this.form.production.number != null) {
        this.getPerformances();
      }

      ApiService.get('/Contracts/GetProductions', {
        TheaterId: this.form.theater.id,
      }).then((response) => {
        TokenService.saveToken(response.data.token);
        ApiService.setHeader();

        const data = response.data.result;

        this.productions = data;
        this.productions.forEach((element) => {
          this.productionOptions.push({ value: element.number, text: element.title });
        });
      });
    },
    getPerformances() {
      this.performanceOptions = [];

      ApiService.get('/Contracts/GetPerformances', {
        ProductionNumber: this.form.production.number,
        TheaterId: this.form.theater.id,
      }).then((response) => {
        TokenService.saveToken(response.data.token);
        ApiService.setHeader();

        const data = response.data.result;

        data.forEach((element) => {
          var value = {
            id: element.id,
            startDate: element.startDate,
            area: element.area,
            theaterFee: element.theaterFee,
            consumptionFee: element.consumptionFee,
            serviceFee: element.serviceFee,
            wardrobeFee: element.wardrobeFee,
          };
          this.performanceOptions.push({
            value: value,
            text: element.number + ' / ' + functions.getDateTimeView(element.startDate) + ' (' + element.area + ')',
          });
        });
      });
    },
    changeProduction() {
      this.form.performances = [];
      this.getPerformances();
      const vm = this;

      if (this.form.production.number != '') {
        var production = this.productions.filter(function (option) {
          return option.number == vm.form.production.number;
        });
        this.form.production.title = production[0].title;
        this.form.producer.id = production[0].producer.id;
        this.form.producer.name = production[0].producer.name;
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('form', this.form);
      },
    },
    formValid: function () {
      this.$emit('valid', this.formValid);
    },
  },
};
</script>

<style scoped></style>