var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"py-6 px-3 pb-0 mb-4",attrs:{"flat":""}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isTheaterSurchargeRejected ? 'error--text ' : 'primary--text '},[_vm._v(" theatertoeslag ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.theaterFee &&
              _vm.contract.previousApproved.theaterFee != null &&
              _vm.contract.previousApproved.theaterFee != _vm.contract.theaterFee
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.contract.isTheaterSurchargeRejected
                  ? 'error--text no-dash'
                  : 'primary--text text-decoration-line-through mb-0 pr-2'},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.previousApproved.theaterFee))+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(_vm._s(_vm.getEuroSignView(_vm.contract.theaterFee)))])]):_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.contract.isTheaterSurchargeRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.theaterFee))+" ")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isRentRejected ? 'error--text ' : 'primary--text '},[_vm._v(" huur (excl BTW) ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.rent &&
              _vm.contract.previousApproved.rent != null &&
              _vm.contract.previousApproved.rent != _vm.contract.rent
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.contract.isTheaterSurchargeRejected
                  ? 'error--text no-dash'
                  : 'primary--text text-decoration-line-through mb-0 pr-2'},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.previousApproved.rent))+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(_vm._s(_vm.getEuroSignView(_vm.contract.rent)))])]):_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.contract.isRentRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.rent))+" ")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0 mb-8",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isOtherCostsRejected ? 'error--text ' : 'primary--text '},[_vm._v(" overige kosten ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.otherCosts &&
              _vm.contract.previousApproved.otherCosts != null &&
              _vm.contract.previousApproved.otherCosts != _vm.contract.otherCosts
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.contract.isTheaterSurchargeRejected
                  ? 'error--text no-dash'
                  : 'primary--text text-decoration-line-through mb-0 pr-2'},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.previousApproved.otherCosts))+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(_vm._s(_vm.getEuroSignView(_vm.contract.otherCosts)))])]):_c('h3',{staticClass:"border-bottom pb-4 mb-0",class:_vm.contract.isOtherCostsRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.otherCosts))+" ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }