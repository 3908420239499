<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="63"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <!-- Notification Details -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <h1 class="primary--text mb-5">{{ this.notification.title }}</h1>
                  <h4 class="primary--text mb-2 font-weight-regular">gegevens notificatie</h4>

                  <v-card
                    class="pa-6 mb-10"
                    flat
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>titel:</small><br />
                          <strong>{{ notification.title }}</strong>
                        </p>
                      </v-col>
                    </v-row>

                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>datum:</small><br />
                          <strong>{{ notification.date }}</strong>
                        </p>
                      </v-col>
                    </v-row>

                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>tekst:</small><br />
                          <strong>{{ notification.text }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';

import Help from '../../../components/Help.vue';

export default {
  name: 'LibraryNotificationsDetail',
  props: ['notificationId'],
  components: {
    Help,
  },
  data() {
    return {
      notification: {
        title: '',
        text: '',
        date: '',
      },
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'notificaties',
          disabled: false,
          href: '/beheer/notificaties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryNotificationsDetail');
    this.getNotification();
  },
  methods: {
    getNotification: function () {
      ApiService.get('/Notifications/Get', {
        id: this.notificationId,
      })
        .then((response) => {
          if (response.data.success) {
            const notification = response.data.result;
            this.breadcrumbs[2].text = notification.title;
            this.notification.title = notification.title;
            this.notification.date = functions.getDateTimeView(notification.showDate);
            this.notification.text = notification.text;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
