var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"py-6 px-3 pb-0 mb-4",attrs:{"flat":""}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isTheaterSurchargeRejected ? 'error--text ' : 'primary--text '},[_vm._v(" theatertoeslag ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.theaterFee &&
              _vm.contract.previousApproved.theaterFee != null &&
              _vm.contract.previousApproved.theaterFee != _vm.contract.theaterFee
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.contract.isTheaterSurchargeRejected
                  ? 'error--text no-dash'
                  : 'primary--text text-decoration-line-through mb-0 pr-2'},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.previousApproved.theaterFee))+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.theaterFee))+" ")])]):_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.contract.isTheaterSurchargeRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.theaterFee))+" ")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isCopyrightRejected ? 'error--text ' : 'primary--text '},[_vm._v(" auteursrechten ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.copyrightPercentage &&
              _vm.contract.previousApproved.copyrightPercentage != null &&
              _vm.contract.previousApproved.copyrightPercentage != _vm.contract.copyrightPercentage
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.contract.isCopyrightRejected
                  ? 'error--text no-dash'
                  : 'primary--text text-decoration-line-through mb-0 pr-2'},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.previousApproved.copyrightPercentage))+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.copyrightPercentage))+" ")])]):_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.contract.isCopyrightRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.getPercentageView(_vm.contract.copyrightPercentage))+" ")])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isCopyrightCalculationRejected ? 'error--text ' : 'primary--text '},[_vm._v(" AR percentageberekening ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.copyrightPercentage &&
              _vm.contract.previousApproved.copyrightPercentage != null &&
              _vm.contract.previousApproved.copyrightPercentage != _vm.contract.copyrightPercentage
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.contract.isCopyrightRejected
                  ? 'error--text no-dash'
                  : 'primary--text text-decoration-line-through mb-0 pr-2'},[(_vm.contract.calculateCopyrightPercentage)?_c('span',[_vm._v(_vm._s(parseFloat(_vm.getDecimalDatabase(_vm.contract.previousApproved.copyrightPercentage)) + '/1' + parseFloat(_vm.getDecimalDatabase(_vm.contract.previousApproved.copyrightPercentage)).toLocaleString( 'en-US', { minimumIntegerDigits: 2, useGrouping: false } )))]):_c('span',[_vm._v(_vm._s(parseFloat(_vm.getDecimalDatabase(_vm.contract.previousApproved.copyrightPercentage)) + '/100'))])]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[(_vm.contract.calculateCopyrightPercentage)?_c('span',[_vm._v(_vm._s(parseFloat(_vm.getDecimalDatabase(_vm.contract.copyrightPercentage)) + '/1' + parseFloat(_vm.getDecimalDatabase(_vm.contract.copyrightPercentage)).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false, })))]):_c('span',[_vm._v(_vm._s(parseFloat(_vm.getDecimalDatabase(_vm.contract.copyrightPercentage)) + '/100'))])])]):_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.contract.isCopyrightCalculationRejected ? 'error--text ' : 'primary--text text-decoration-line-through'},[(_vm.contract.calculateCopyrightPercentage)?_c('span',[_vm._v(_vm._s(parseFloat(_vm.getDecimalDatabase(_vm.contract.copyrightPercentage)) + '/1' + parseFloat(_vm.getDecimalDatabase(_vm.contract.copyrightPercentage)).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false, })))]):_c('span',[_vm._v(_vm._s(parseFloat(_vm.getDecimalDatabase(_vm.contract.copyrightPercentage)) + '/100'))])])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isAmountRejected ? 'error--text ' : 'primary--text '},[_vm._v(" aanvulling tot (excl. BTW) ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.amount &&
              _vm.contract.previousApproved.amount != null &&
              _vm.contract.previousApproved.amount != _vm.contract.amount
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.contract.isAmountRejected
                  ? 'error--text no-dash'
                  : 'primary--text text-decoration-line-through mb-0 pr-2'},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.previousApproved.amount))+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.amount))+" ")])]):_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.contract.isAmountRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.amount))+" ")])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isMaximumAmountRejected ? 'error--text ' : 'primary--text '},[_vm._v(" maximale aanvulling (excl. BTW) ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.maximumAmount &&
              _vm.contract.previousApproved.maximumAmount != null &&
              _vm.contract.previousApproved.maximumAmount != _vm.contract.maximumAmount
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.contract.isMaximumAmountRejected
                  ? 'error--text no-dash'
                  : 'primary--text text-decoration-line-through mb-0 pr-2'},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.previousApproved.maximumAmount))+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.maximumAmount))+" ")])]):_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.contract.isMaximumAmountRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.maximumAmount))+" ")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isProviderPartageRejected ? 'error--text ' : 'primary--text '},[_vm._v(" partage aanbieder ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.partageProviderPercentage &&
              _vm.contract.previousApproved.partageProviderPercentage != null &&
              _vm.contract.previousApproved.partageProviderPercentage != _vm.contract.partageProviderPercentage
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.contract.isProviderPartageRejected
                  ? 'error--text no-dash'
                  : 'primary--text text-decoration-line-through mb-0 pr-2'},[_vm._v(" "+_vm._s(_vm.getPercentageView(_vm.contract.previousApproved.partageProviderPercentage))+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(" "+_vm._s(_vm.getPercentageView(_vm.contract.partageProviderPercentage))+" ")])]):_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.contract.isProviderPartageRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.getPercentageView(_vm.contract.partageProviderPercentage))+" ")])]),_c('v-col',{staticClass:"py-0 mb-8",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isGuaranteePerPerformanceRejected ? 'error--text ' : 'primary--text '},[_vm._v(" aanvullingsberekening ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.guaranteePerPerformance &&
              _vm.contract.previousApproved.guaranteePerPerformance != null &&
              _vm.contract.previousApproved.guaranteePerPerformance != _vm.contract.guaranteePerPerformance
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.contract.isGuaranteePerPerformanceRejected ? 'error--text no-dash' : 'primary--text mb-0 pr-2'},[_vm._v(" "+_vm._s(_vm.contract.previousApproved.guaranteePerPerformance ? 'afspraak per voorstelling' : 'afspraak over het totaal')+" ")]),_c('h3',{staticClass:"error--text pb-4 border-bottom"},[_vm._v(" "+_vm._s(_vm.contract.guaranteePerPerformance)+" ")])]):_c('h3',{staticClass:"border-bottom pb-4 mb-0",class:_vm.contract.isGuaranteePerPerformanceRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.contract.guaranteePerPerformance ? 'afspraak per voorstelling' : 'afspraak over het totaal')+" ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }