<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="77"
            ></help>
          </v-col>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-autocomplete
                v-if="user.organisationTypeId !== 2"
                v-model="tableSalesMonitorFilter.theaterId"
                :items="theaters"
                label="selecteer een theater"
                clearable
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tableSalesMonitorFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-on="on"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="primary--text">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <v-autocomplete
                      v-model="tableSalesMonitorFilter.theaterId"
                      :items="theaters"
                      label="selecteer een theater"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tableSalesMonitorFilterDialog = false"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      outlined
                      dark
                      @click="clearFilterProductions()"
                      >reset filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableSalesMonitorHeadersFiltered"
          :items="tableProductionPerformancesFiltered"
          :mobile-breakpoint="0"
          :loading="tableProductionPerformancesLoading"
          :page.sync="tableSalesMonitorPage"
          :items-per-page="tableSalesMonitorItemsPerPage"
          hide-default-footer
          @page-count="tableSalesMonitorPageCount = $event"
          @click:row="goToDetail"
          class="table-action row-is-clickable"
          fixed-header
          :height="tableProductionPerformancesFiltered.length > 10 ? '55vh' : ''"
        >
          <template v-slot:[`item.localOccupancyRate`]="{ item }">
            <td class="tooltip-container">
              <span class="tooltip"
                ><table>
                  <tbody>
                    <tr>
                      <th scope="row">capaciteit</th>
                      <td>{{ item.capacity }}</td>
                    </tr>
                    <tr>
                      <th scope="row">%bezet</th>
                      <td>
                        {{
                          item.occupancyRate === 0 ? item.occupancyRate + '%' : getPercentageView(item.occupancyRate)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">%bezet landelijk</th>
                      <td>
                        {{
                          item.nationalOccupancyRate === 0
                            ? item.nationalOccupancyRate + '%'
                            : getPercentageView(item.nationalOccupancyRate)
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
              <v-progress-linear
                class="custom_progress"
                :background-color="'#e1e7f2'"
                :height="20"
                :width="200"
                :rounded="true"
                :value="item.occupancyRate"
                :color="'#0abfb8'"
              >
                <div
                  class="target"
                  :style="{ left: item.nationalOccupancyRate + '%' }"
                  v-if="item.nationalOccupancyRate > 0"
                ></div>
              </v-progress-linear>
            </td>
          </template>

          <template v-slot:[`item.startDate`]="{ item }">
            {{ item.startDateView }}
          </template>

          <template v-slot:[`item.averageTicketPrice`]="{ item }">
            <span>{{ getEuroSign(item.averageTicketPrice) }}</span>
          </template>

          <template v-slot:[`item.totalTickets`]="{ item }">
            <td
              class="tooltip-container textual"
              v-if="item.averageTotalTickets != null"
            >
              <span class="tooltip"
                ><table>
                  <tbody>
                    <tr>
                      <th scope="row">landelijk gemiddelde</th>
                      <td>
                        {{ formatThousandsWithDot(item.averageTotalTickets) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
              <span>{{ formatThousandsWithDot(item.totalTickets) }}</span>
            </td>
            <div v-else>
              <span>{{ formatThousandsWithDot(item.totalTickets) }}</span>
            </div>
          </template>

          <template v-slot:[`item.totalRevenueInVat`]="{ item }">
            <span>{{ getEuroSign(item.totalRevenueInVat) }}</span>
          </template>

          <template v-slot:[`item.reservedTickets`]="{ item }">
            <span>{{ formatThousandsWithDot(item.reservedTickets) }}</span>
          </template>

          <template v-slot:[`item.blockedTickets`]="{ item }">
            <span>{{ formatThousandsWithDot(item.blockedTickets) }}</span>
          </template>

          <template v-slot:[`item.localPerformances`]="{ item }">
            <span>{{ formatThousandsWithDot(item.localPerformances) }}</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              fab
              x-small
              depressed
              color="warning"
              class="ml-1"
              :to="{
                name: 'SalesMonitorPerformancesDetail',
                params: {
                  productionNumber: productionNumber,
                  performanceId: item.id,
                },
              }"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
          </template>

          <template
            slot="body.append"
            v-if="tableSalesMonitorFilterActive"
          >
            <tr class="table-selection-total">
              <th class="title">selectie</th>
              <th
                class=""
                v-if="user.organisationTypeId !== 2"
              ></th>
              <th
                class=""
                v-if="user.organisationTypeId !== 2"
              ></th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorSelected.totalTickets) }}
              </th>
              <th class="text">
                {{ getEuroSign(this.tableSalesMonitorSelected.totalRevenueInVat) }}
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorSelected.reservedTickets) }}
              </th>
              <th class="text">
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorSelected.blockedTickets) }}
              </th>
              <th
                class=""
                v-for="header in tableSalesMonitorHeadersFiltered.slice(user.organisationTypeId === 2 ? 6 : 8)"
                :key="header.value"
              ></th>
            </tr>
          </template>

          <template
            slot="body.append"
            v-if="!tableProductionPerformancesLoading"
          >
            <tr class="table-total">
              <th class="title">totaal</th>
              <th
                class=""
                v-if="user.organisationTypeId !== 2"
              ></th>
              <th
                class=""
                v-if="user.organisationTypeId !== 2"
              ></th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorTotal.totalTickets) }}
              </th>
              <th class="text">
                {{ getEuroSign(this.tableSalesMonitorTotal.totalRevenueInVat) }}
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorTotal.reservedTickets) }}
              </th>
              <th class="text">
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorTotal.blockedTickets) }}
              </th>
              <th
                class=""
                v-for="header in tableSalesMonitorHeadersFiltered.slice(user.organisationTypeId === 2 ? 6 : 8)"
                :key="header.value"
              ></th>
            </tr>
          </template>

          <v-data-footer></v-data-footer>
        </v-data-table>

        <!-- Table settings -->
        <v-row
          class="text-center pt-2"
          align="center"
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption primary--text ma-0 pa-0">
              totaal: {{ this.tableProductionPerformancesFiltered.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableProductionPerformancesFiltered.length > 25"
              @click="tableSalesMonitorItemsPerPage = 25"
              :color="tableSalesMonitorItemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tableProductionPerformancesFiltered.length > 25"
              @click="tableSalesMonitorItemsPerPage = 50"
              :color="tableSalesMonitorItemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tableProductionPerformancesFiltered.length > 50"
              @click="tableSalesMonitorItemsPerPage = 100"
              :color="tableSalesMonitorItemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tableSalesMonitorPageCount > 1"
              v-model="tableSalesMonitorPage"
              :length="tableSalesMonitorPageCount"
              :total-visible="7"
              :elevation="0"
            ></v-pagination>
          </v-col>

          <!-- Table download -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex justify-center justify-sm-end"
          >
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
            >
              <vue-excel-xlsx
                class="v-btn font-weight-bold text-lowercase"
                :data="tableProductionPerformancesFiltered"
                :columns="downloadExportColumns"
                :filename="'dip-verkoopmonitor-voorstellingen-export'"
                :sheetname="'V-P-voorstellingen'"
                >exporteer selectie <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import Help from '../../components/Help.vue';
import functions from '../../services/functions.service';

export default {
  name: 'SalesMonitorProductions',
  props: ['productionNumber'],
  components: {
    Help,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'verkoopmonitor',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '/verkoopmonitor/producties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      performances: [],
      tableSalesMonitorSelected: {
        totalTickets: 0,
        ticketsSold: 0,
        reservedTickets: 0,
        blockedTickets: 0,
        totalRevenueInVat: 0,
      },
      tableSalesMonitorTotal: {
        totalTickets: 0,
        ticketsSold: 0,
        reservedTickets: 0,
        blockedTickets: 0,
        totalRevenueInVat: 0,
      },
      tableProductionPerformancesFiltered: [],
      theaters: [],
      tableProductionPerformancesLoading: true,
      tableSalesMonitorHeaders: [
        { text: 'datum en tijd', value: 'startDate' },
        { text: 'theater', value: 'theaterName', showFor: [1, 3] },
        { text: 'plaats', value: 'areaCity', showFor: [1, 3] },
        { text: 'verkocht', value: 'totalTickets' },
        { text: 'recette', value: 'totalRevenueInVat' },
        { text: 'gereserv.', value: 'reservedTickets' },
        { text: 'gem. ticketprijs', value: 'averageTicketPrice' },
        { text: 'geblok.', value: 'blockedTickets' },
        {
          text: '% bezet',
          value: 'localOccupancyRate',
          sortable: false,
          width: '125px',
        },
        { text: 'status', value: 'state' },
        { text: 'zaal', value: 'areaName', showFor: [2] },
        { text: 'producent', value: 'producerName' },
        { text: 'co-productie', value: 'coProduction', showFor: [1, 3] },
        { text: 'impresariaat', value: 'agencyName', showFor: [2, 3] },
        { text: '', value: 'action', sortable: false, align: 'end' },
      ],
      tableSalesMonitorFilterActive: false,
      tableSalesMonitorFilter: {
        theaterId: '',
      },
      tableSalesMonitorPage: 1,
      tableSalesMonitorPageCount: 0,
      tableSalesMonitorItemsPerPage: 25,
      tableSalesMonitorFilterDialog: false,
      downloadExportColumns: [
        { label: 'voorstellings nr', field: 'number' },
        { label: 'aanvangstijd', field: 'startDate' },
        { label: 'zaal', field: 'areaName' },
        { label: 'plaats', field: 'areaCity' },
        { label: 'theater', field: 'theaterName' },
        { label: 'capaciteit', field: 'capacity' },
        { label: 'verkocht', field: 'totalTickets' },
        {
          label: 'verkocht landelijk gemiddelde',
          field: 'averageTotalTickets',
        },
        { label: 'recette', field: 'totalRevenueInVat' },
        { label: 'gereserv.', field: 'reservedTickets' },
        { label: 'geblok.', field: 'blockedTickets' },
        { label: '%bezet', field: 'occupancyRate' },
        { label: '%bezet landelijk', field: 'nationalOccupancyRate' },
        { label: 'gem. ticketprijs', field: 'averageTicketPrice' },
        { label: 'status', field: 'state' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('SalesMonitor');
    this.getProductionPerformances();
  },
  methods: {
    goToDetail(row) {
      this.$router.push({
        name: 'SalesMonitorPerformancesDetail',
        params: {
          productionNumber: row.productionNumber,
          performanceId: row.id,
        },
      });
    },
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageViewWithoutDecimals(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    calculateArrayAvg: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop] / items.length;
      }, 0);
    },
    getProductionPerformances: function () {
      const vm = this;
      this.performances = [];
      this.tableProductionPerformancesFiltered = [];
      this.tableProductionPerformancesLoading = true;

      ApiService.get('/SalesMonitor/GetProduction', {
        ProductionNumber: this.productionNumber,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          const production = response.data.result;
          this.breadcrumbs[2].text = production.title;

          this.performances = response.data.result.performances;

          for (var i = 0; i < this.performances.length; i++) {
            vm.$set(this.performances[i], 'startDateView', functions.getDateTimeView(this.performances[i].startDate));

            if (this.performances[i].coProduction == true) {
              this.performances[i].coProduction = 'Ja';
            } else {
              this.performances[i].coProduction = 'Nee';
            }

            vm.$set(this.performances[i], 'state', functions.changeState(this.performances[i].state));

            vm.theaters.push({
              value: this.performances[i].theaterId,
              text: `${this.performances[i].theaterName} (${this.performances[i].theaterCity})`,
            });
          }

          this.tableSalesMonitorTotal.totalTickets = this.calculateArraySum(this.performances, 'totalTickets');
          this.tableSalesMonitorTotal.reservedTickets = this.calculateArraySum(this.performances, 'reservedTickets');
          this.tableSalesMonitorTotal.blockedTickets = this.calculateArraySum(this.performances, 'blockedTickets');
          this.tableSalesMonitorTotal.averageTicketPrice = this.calculateArraySum(
            this.performances,
            'averageTicketPrice'
          );
          this.tableSalesMonitorTotal.totalRevenueInVat = this.calculateArraySum(
            this.performances,
            'totalRevenueInVat'
          );
          this.tableProductionPerformancesFiltered = this.performances;

          this.filterProductions();
          this.tableProductionPerformancesLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    filterProductions: function () {
      const vm = this;

      if (this.tableSalesMonitorFilter.theaterId == '' || this.tableSalesMonitorFilter.theaterId == undefined) {
        this.tableSalesMonitorFilterActive = false;
      } else {
        this.tableSalesMonitorFilterActive = true;
      }

      this.tableProductionPerformancesFiltered = this.performances.filter(function (performance) {
        return (
          performance.theaterId == vm.tableSalesMonitorFilter.theaterId ||
          vm.tableSalesMonitorFilter.theaterId == '' ||
          vm.tableSalesMonitorFilter.theaterId == undefined
        );
      });

      this.tableSalesMonitorSelected.totalTickets = this.calculateArraySum(
        this.tableProductionPerformancesFiltered,
        'totalTickets'
      );
      this.tableSalesMonitorSelected.sold = this.calculateArraySum(
        this.tableProductionPerformancesFiltered,
        'totalTickets'
      );
      this.tableSalesMonitorSelected.reservedTickets = this.calculateArraySum(
        this.tableProductionPerformancesFiltered,
        'reservedTickets'
      );
      this.tableSalesMonitorSelected.blockedTickets = this.calculateArraySum(
        this.tableProductionPerformancesFiltered,
        'blockedTickets'
      );
      this.tableSalesMonitorSelected.averageTicketPrice = this.calculateArrayAvg(
        this.tableProductionPerformancesFiltered,
        'averageTicketPrice'
      );
      this.tableSalesMonitorSelected.totalRevenueInVat = this.calculateArraySum(
        this.tableProductionPerformancesFiltered,
        'totalRevenueInVat'
      );
    },
    clearFilterProductions: function () {
      this.tableSalesMonitorFilter.name = '';
      this.tableSalesMonitorFilter.agencyId = '';
      this.tableSalesMonitorFilter.theaterId = '';
      this.tableSalesMonitorFilter.producerId = '';
      this.tableSalesMonitorFilter.genreId = '';
      this.tableSalesMonitorFilterActive = false;
      this.tableSalesMonitorFilterDialog = false;
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
    tableSalesMonitorHeadersFiltered() {
      return this.tableSalesMonitorHeaders.filter(
        (h) => !h.showFor || h.showFor.includes(this.user.organisationTypeId)
      );
    },
  },
  watch: {
    tableSalesMonitorFilter: {
      handler: 'filterProductions',
      deep: true,
    },
  },
};
</script>
