<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :page.sync="tablePublicMonitorPage"
      :items-per-page="tableItemsPerPage"
      :filter-active="filterActive"
      :selected="selected"
      :total="total"
      :downloads="downloads"
      class="table-action row-is-clickable"
      :height="items.length > 10 ? '55vh' : ''"
      @page-count="tablePublicMonitorPageCount = $event"
      @click:row="goToDetail"
      fixed-header
      hide-default-footer
    >
      <template v-slot:[`item.startDate`]="{ item }">
        {{ item.startDateView }}
      </template>

      <template v-slot:[`item.tickets`]="{ item }">
        {{ formatThousandsWithDot(item.tickets) }}
      </template>

      <template v-slot:[`item.performances`]="{ item }">
        {{ formatThousandsWithDot(item.performances) }}
      </template>

      <template v-slot:[`item.capacity`]="{ item }">
        {{ formatThousandsWithDot(item.capacity) }}
      </template>

      <template v-slot:[`item.purchases`]="{ item }">
        {{ formatThousandsWithDot(item.purchases) }}
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          v-if="item.number != null && item.number != 'no_export'"
          fab
          x-small
          depressed
          color="info"
          class="ml-1"
          :to="{
            name: 'SalesMonitorProductionsDetail',
            params: { productionNumber: item.number },
          }"
        >
          <v-icon color="primary">mdi-link-variant</v-icon>
        </v-btn>

        <v-btn
          v-if="!item.hasTooFewPurchases && detailPage == 'BenchmarkProductionsDetail'"
          fab
          x-small
          depressed
          color="warning"
          class="ml-1"
          :to="{
            name: 'BenchmarkProductionsDetail',
            params: { productionNumber: item.number },
          }"
        >
          <v-icon color="primary">mdi-eye</v-icon>
        </v-btn>

        <v-btn
          v-if="!item.hasTooFewPurchases && detailPage == 'PublicMonitorProductionsDetail'"
          fab
          x-small
          depressed
          color="warning"
          class="ml-1"
          :to="{
            name: 'PublicMonitorProductionsDetail',
            params: { productionNumber: item.id },
          }"
        >
          <v-icon color="primary">mdi-eye</v-icon>
        </v-btn>
      </template>

      <template
        slot="body.append"
        v-if="filterActive"
      >
        <tr class="table-selection-total">
          <th class="title">selectie</th>
          <th class=""></th>
          <th class="text">
            {{ formatThousandsWithDot(selected.sold) }}
          </th>
          <th class="text">
            {{ formatThousandsWithDot(selected.performancesCount) }}
          </th>
          <th class="text">
            {{ formatThousandsWithDot(selected.capacity) }}
          </th>
          <th class="text">
            {{ formatThousandsWithDot(selected.purchases) }}
          </th>
          <th class="text"></th>
          <th class="text"></th>
        </tr>
      </template>

      <template
        slot="body.append"
        v-if="!loading"
      >
        <tr class="table-total">
          <th class="title">totaal</th>
          <th></th>

          <th class="text">
            {{ formatThousandsWithDot(total.sold) }}
          </th>
          <th class="text">
            {{ formatThousandsWithDot(total.performancesCount) }}
          </th>
          <th class="text">
            {{ formatThousandsWithDot(total.capacity) }}
          </th>
          <th class="text">
            {{ formatThousandsWithDot(total.purchases) }}
          </th>
          <th class=""></th>
          <th class=""></th>
        </tr>
      </template>

      <v-data-footer></v-data-footer>
    </v-data-table>

    <!-- Table settings -->
    <v-row
      class="text-center pt-2"
      align="center"
    >
      <!-- Table totaal -->
      <v-col cols="12">
        <p class="font-weight-medium text-left caption primary--text ma-0 pa-0">totaal: {{ this.items.length }}</p>
      </v-col>

      <!-- Table buttons items per page -->
      <v-col
        cols="12"
        sm="4"
        class="d-flex align-center justify-center justify-sm-start"
      >
        <v-btn
          v-if="items.length > 25"
          @click="tableItemsPerPage = 25"
          :color="tableItemsPerPage == 25 ? 'primary' : '#fff'"
          height="45"
          width="45"
          elevation="0"
          x-small
          class="pa-0 mr-1 font-weight-bold body-2"
          >25</v-btn
        >
        <v-btn
          v-if="items.length > 25"
          @click="tableItemsPerPage = 50"
          :color="tableItemsPerPage == 50 ? 'primary' : '#fff'"
          height="45"
          width="45"
          elevation="0"
          x-small
          class="pa-0 mr-1 font-weight-bold body-2"
          >50</v-btn
        >
        <v-btn
          v-if="items.length > 50"
          @click="tableItemsPerPage = 100"
          :color="tableItemsPerPage == 100 ? 'primary' : '#fff'"
          height="45"
          width="45"
          elevation="0"
          x-small
          class="pa-0 mr-1 font-weight-bold body-2"
          >100</v-btn
        >
      </v-col>

      <!-- Table pagination -->
      <v-col
        cols="12"
        sm="4"
      >
        <v-pagination
          v-if="tablePublicMonitorPageCount > 1"
          v-model="tablePublicMonitorPage"
          :length="tablePublicMonitorPageCount"
          :total-visible="7"
          :elevation="0"
        ></v-pagination>
      </v-col>

      <!-- Table download -->
      <download-excel
        :data="items"
        :columns="downloads"
        :filename="'dip-verkoop-en-publiek-producties-export'"
        :sheetname="'publieksmonitor producties'"
      >
      </download-excel>
    </v-row>
  </div>
</template>

<script>
import DownloadExcel from '../../../../components/ui/Misc/DownloadExcel.vue';

export default {
  name: 'test',
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    filterActive: {
      type: Boolean,
      required: true,
    },
    selected: {
      type: Object,
      required: true,
    },
    total: {
      type: Object,
      required: true,
    },
    downloads: {
      type: Array,
      required: true,
    },
    detailPage: {
      type: String,
      required: false,
    },
  },
  components: {
    DownloadExcel,
  },
  data() {
    return {
      tablePublicMonitorPage: 1,
      tablePublicMonitorPageCount: 0,
      tableItemsPerPage: 25,
    };
  },
  methods: {
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    goToDetail(row) {
      if (this.detailPage == 'PublicMonitorProductionsDetail') {
        this.$router.push({
          name: 'PublicMonitorProductionsDetail',
          params: { productionNumber: row.id },
        });
      } else {
        this.$router.push({
          name: 'BenchmarkProductionsDetail',
          params: { productionNumber: row.number },
        });
      }
    },
  },
};
</script>
