<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
      :loading="keyFiguresLoading"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="primary--text font-weight-medium mb-0">kengetallen</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>

        <v-row
          flex
          justify="center"
          class="py-2 flex-grow-1"
        >
          <template v-if="chart.loaded">
            <v-col cols="6">
              <p class="primary--text font-weight-medium mb-3">aantal producties</p>
              <h2 class="primary--text">{{ formatThousandsWithDot(productionCount) }}</h2>
            </v-col>

            <v-col cols="6">
              <p class="primary--text font-weight-medium mb-3">aantal voorstellingen</p>
              <h2 class="primary--text">{{ formatThousandsWithDot(performanceCount) }}</h2>
            </v-col>

            <v-col cols="6">
              <p class="primary--text font-weight-medium mb-3">gemiddelde ticketprijs</p>
              <h2 class="primary--text">{{ getEuroSign(averageTicketPrice) }}</h2>
            </v-col>

            <v-col cols="6">
              <p class="primary--text font-weight-medium mb-3">totale recette</p>
              <h2 class="primary--text">{{ getEuroSign(totalRevenueInVat, 0) }}</h2>
            </v-col>
          </template>

          <template v-else>
            <p class="primary--text font-weight-medium mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import functions from '../../services/functions.service';

import Help from '../Help.vue';

export default {
  props: ['helpId', 'colsMobile', 'cols-Tablet', 'colsDesktop', 'filterPeriod'],
  components: {
    Help,
  },
  data() {
    return {
      chart: {
        loaded: false,
      },
      productionCount: 0,
      performanceCount: 0,
      averageTicketPrice: 0,
      totalRevenueInVat: 0,
      keyFiguresLoading: true,
    };
  },
  methods: {
    getEuroSign: function (value, digits) {
      return functions.getEuroSignView(value, digits);
    },
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getData() {
      this.chart.loaded = false;

      ApiService.get('/Dashboard/GetKeyFigures', {
        StartDate: this.filterPeriod.from,
        EndDate: this.filterPeriod.to,
      }).then((response) => {
        TokenService.saveToken(response.data.token);
        ApiService.setHeader();

        const data = response.data.result;

        this.productionCount = data.productionCount;
        this.performanceCount = data.performanceCount;
        this.averageTicketPrice = data.averageTicketPrice;
        this.totalRevenueInVat = data.totalRevenueInVat;

        // Load chart
        this.chart.loaded = true;
        this.keyFiguresLoading = false;
      });
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    filterPeriod: function () {
      this.getData();
    },
  },
};
</script>

<style scoped></style>
