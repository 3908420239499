<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
      :loading="capacityLoading"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="primary--text font-weight-medium mb-0">capaciteit</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>

        <v-row
          flex
          align="center"
          justify="center"
          class="py-2 flex-column flex-grow-1"
        >
          <template v-if="chart.loaded & (capacity != 0)">
            <v-col cols="11">
              <h2 class="primary--text">totale capaciteit: {{ formatThousandsWithDot(capacity) }}</h2>
            </v-col>

            <v-col cols="12">
              <doughnut-chart
                v-if="chart.loaded"
                :chart-data="chart.data"
                :chart-options="chart.options"
              ></doughnut-chart>
            </v-col>
          </template>

          <template v-else>
            <p class="primary--text font-weight-medium mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';

import Help from '../Help.vue';
import DoughnutChart from '../../plugins/chartjs/DoughnutChartDatalabels.js';

export default {
  props: ['helpId', 'colsMobile', 'cols-Tablet', 'colsDesktop', 'filterPeriod'],
  components: {
    Help,
    DoughnutChart,
  },
  data() {
    return {
      capacity: 0,
      chart: {
        loaded: false,
        data: {},
        options: {},
      },
      capacityLoading: true,
    };
  },
  mounted() {},
  methods: {
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getData() {
      this.chart.loaded = false;
      ApiService.get('/Dashboard/GetTicketSales', {
        StartDate: this.filterPeriod.from,
        EndDate: this.filterPeriod.to,
      }).then((response) => {
        TokenService.saveToken(response.data.token);
        ApiService.setHeader();

        const data = response.data.result;

        this.capacity = data.capacity;

        var labels = data.tickets.map(function (e) {
          return e.type;
        });
        var dataCapacity = data.tickets.map(function (e) {
          return e.count;
        });
        var dataCapacityPercentage = data.tickets.map(function (e) {
          return e.rate;
        });

        // Set chart options
        this.chart.options = {
          responsive: true,
          maintainAspectRatio: true,
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
          },
          cutout: '60%',
          textCenter: '',
          plugins: {
            datalabels: {
              color: '#fff',
            },
            legend: {
              display: true,
              position: 'top',
            },
            tooltip: {
              enabled: true,
              intersect: true,
              mode: 'index',
              position: 'nearest',
              callbacks: {
                label: function (tooltipItem) {
                  var dataPercentage = tooltipItem.dataset.dataPercentages[tooltipItem.dataIndex];
                  var data = tooltipItem.dataset.data[tooltipItem.dataIndex];

                  var label = `${tooltipItem.label}: ${dataPercentage}% / ${data}`;

                  return label;
                },
              },
            },
          },
        };

        // Set chart data
        this.chart.data = {
          labels: labels,
          datasets: [
            {
              data: dataCapacity,
              dataPercentages: dataCapacityPercentage,
              backgroundColor: ['#fff453', '#0abfb8', '#021e73', '#D92853', '#782562', '#FF8F2E', '#EAEAEA'],
              borderWidth: 0,
              weight: 1,
              datalabels: {
                color: [
                  'transparent',
                  'transparent',
                  'transparent',
                  'transparent',
                  'transparent',
                  'transparent',
                  'transparent',
                ],
              },
            },
          ],
        };

        // Load chart
        this.chart.loaded = true;
        this.capacityLoading = false;
      });
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    filterPeriod: function () {
      this.getData();
    },
  },
};
</script>

<style scoped></style>
