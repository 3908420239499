<template>
  <v-menu
    :close-on-click="true"
    :close-on-content-click="false"
    v-model="datePickerDialog"
    max-width="290px"
    min-width="auto"
    nudge-top="-40"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        v-on="on"
        v-bind="attrs"
        :label="datePickerLabel ?? 'startdatum'"
        placeholder="DD-MM-JJJJ"
        outlined
        dense
        @input="dateTyped"
        :rules="datePickerDateRequired ? formValidationRules.dateRequired : formValidationRules.date"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="datePickerStart"
      no-title
      first-day-of-week="1"
      @input="datePicked"
      color="success"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex';
import functions from '../services/functions.service';

export default {
  props: ['passedDate', 'rules', 'label'],
  data() {
    return {
      formattedDate: this.passedDate ? this.passedDate : '',
      datePickerDialog: false,
      formValidationRules: {},
      datePickerLabel: this.label,
      datePickerDateRequired: false,
      datePickerStart: this.passedDate
        ? this.passedDate.split('-').reverse().join('-')
        : new Date().toISOString().substring(0, 10),
    };
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();

    if (this.rules == 'date' || this.rules === undefined) {
      this.datePickerDateRequired = false;
    } else {
      this.datePickerDateRequired = true;
    }
  },
  methods: {
    datePicked(date) {
      if (!date) return;

      this.formattedDate = this.formatDateForInput(date);
      this.datePickerDialog = false;

      this.$emit('input', this.formattedDate);
    },
    formatDateForInput(date) {
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    dateTyped(date) {
      if (!date || date.length !== 10) return;
      this.datePickerStart = this.formatDateForPicker(date);
      this.$emit('input', this.formattedDate);
    },
    formatDateForPicker(date) {
      const [day, month, year] = date.split('-');
      return `${year}-${month}-${day}`;
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
};
</script>

<style>
.v-date-picker-table--date td {
  padding: 5px 0;
}
</style>
