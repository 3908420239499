<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="flex align-center pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="1117"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-col
                cols="12"
                md="6"
                sm="10"
              >
                <!-- Form -->
                <v-form
                  @submit.prevent
                  v-model="formValid"
                  class="d-flex flex-column"
                  ref="form"
                >
                  <h1 class="primary--text mb-5">{{ this.production.title }}</h1>
                  <h4 class="primary--text mb-2 font-weight-regular">capaciteit wijzigen</h4>

                  <v-card
                    class="pa-6 mb-10"
                    flat
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-10">
                          Pas hier de zaalcapaciteit voor de gekozen voorstelling aan. Dat kan op twee manieren:<br /><br />
                          1) Selecteer een van je vaste opstellingen. Hierdoor wordt de bijbehorende zaalcapaciteit
                          automatisch opgehaald. Klik op Opslaan.<br />
                          2) Sla het bovenste invoerveld over en typ direct het juiste aantal kaarten in. Klik op
                          Opslaan.
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-autocomplete
                          clearable
                          label="Kies de juiste opstelling en bijbehorende zaalcapaciteit"
                          v-on:change="
                            editProductionPerformanceCapacityForm.totalCapacity =
                              editProductionPerformanceCapacityForm.areacapacity
                          "
                          v-model="editProductionPerformanceCapacityForm.areacapacity"
                          :items="areacapacities"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row v-if="editProductionPerformanceCapacityForm.areacapacity">
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-text-field
                          label="Totaal aantal beschikbare kaarten"
                          v-on:change="
                            editProductionPerformanceCapacityForm.totalCapacity =
                              editProductionPerformanceCapacityForm.areacapacity
                          "
                          v-model="editProductionPerformanceCapacityForm.totalCapacity"
                          disabled
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-text-field
                          label="Totaal aantal beschikbare kaarten"
                          v-model="editProductionPerformanceCapacityForm.totalCapacity"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-alert
                    v-if="formError != ''"
                    text
                    color="error"
                    >{{ formError }}</v-alert
                  >

                  <v-btn
                    rounded
                    large
                    elevation="0"
                    @click="submit"
                    :loading="formLoading"
                    color="secondary"
                    class="text-lowercase mb-5 align-self-start"
                    >Opslaan
                    <v-icon
                      small
                      right
                      >mdi-content-save</v-icon
                    ></v-btn
                  >
                </v-form>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';

export default {
  name: 'LibraryProductionsPerformanceCapacity',
  props: ['productionNumber', 'performanceId'],
  components: {
    Help,
  },
  data() {
    return {
      production: {
        name: '',
      },
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      editProductionPerformanceCapacityForm: {
        areacapacity: '',
        areaId: '',
      },
      areacapacities: [],
      areas: [],
      performanceStatus: [
        { text: 'interesse', value: 'interest' },
        { text: 'optie', value: 'option' },
        { text: 'geaccepteerd', value: 'agreed' },
        { text: 'succesoptie', value: 'success' },
        { text: 'annulering', value: 'cancellation' },
      ],
      performanceType: [
        { text: 'normaal', value: 'normal' },
        { text: 'tryout', value: 'tryout' },
        { text: 'premiere', value: 'premiere' },
        { text: 'montage', value: 'montage' },
        { text: 'derniere', value: 'derniere' },
      ],
      formValidationRules: {},
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '/beheer/producties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'capaciteit bewerken',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryProductionsPerformanceCapacity');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.getProduction();
    this.getOrganisations();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    getProduction: function () {
      ApiService.get('/Productions/Get', {
        productionNumber: this.productionNumber,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const production = response.data.result;
            this.breadcrumbs[2].text = production.title;
            this.breadcrumbs[2].href = '/beheer/producties/' + this.productionNumber + '/';
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    getOrganisations: function () {
      ApiService.get('/Performances/GetAreaCapacities', {
        performanceId: this.performanceId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.areacapacities.push({ value: element.total, text: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.editProductionPerformanceCapacityForm;
        ApiService.put('/Performances/UpdateCapacity', {
          performanceId: this.performanceId,
          capacity: form.totalCapacity,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch((error) => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
  watch: {
    editProductionPerformanceCapacityForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
