<template>
  <div>
      <!-- Surcharges -->
      <v-card class="py-6 px-3 pb-0 mb-4" flat>
        <v-container> 
          <v-row v-if="contract.type === 'Uitkoop'">
            <detail-part label="theatertoeslag" :contract="contract" :is-rejected="contract.isTheaterSurchargeRejected"
                         :getter="c => c.theaterFee" :format="getEuroSignView" />
          </v-row>

          <v-row>
            <detail-part label="consumptietoeslag" :contract="contract" :is-rejected="contract.isConsumptionSurchargeRejected"
                         :getter="c => c.consumptionSurcharge" :format="getEuroSignView" />
          </v-row>
          <v-row>
            <detail-part label="servicetoeslag" :contract="contract" :is-rejected="contract.isServiceSurchargeRejected"
                         :getter="c => c.serviceSurcharge" :format="getEuroSignView" />
          </v-row>
          <v-row>
            <detail-part label="garderobetoeslag" :contract="contract" :is-rejected="contract.isWardrobeSurchargeRejected"
                         :getter="c => c.wardrobeSurcharge" :format="getEuroSignView" colClass="mb-8" />
          </v-row>
        </v-container>
      </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import functions from '../../../../services/functions.service';
import DetailPart from "@/views/Contracts/components/details/DetailPart.vue";

export default {
  components: {DetailPart},
  props: ['contract'],
  data() {
    return {};
  },
  methods: {
    getEuroSignView(value) {
        return functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(value)))
      }
  },
  computed: {
    ...mapState('auth', ['user']),
  }
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #e1e7f2;
}
</style>