import { render, staticRenderFns } from "./OpenContracts.vue?vue&type=template&id=064fb308&scoped=true"
import script from "./OpenContracts.vue?vue&type=script&lang=js"
export * from "./OpenContracts.vue?vue&type=script&lang=js"
import style0 from "./OpenContracts.vue?vue&type=style&index=0&id=064fb308&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064fb308",
  null
  
)

export default component.exports