var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.isRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(
            _vm.contract.previousApproved &&
            _vm.getter(_vm.contract.previousApproved).theaterFee &&
            _vm.getter(_vm.contract.previousApproved) !== _vm.getter(_vm.contract)
          )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.isRejected
                ? 'error--text no-dash'
                : 'primary--text text-decoration-line-through mb-0 pr-2'},[_vm._v(" "+_vm._s(_vm.renderOldValue)+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(_vm._s(_vm.renderValue(_vm.contract)))])]):_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.isRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.renderValue)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }