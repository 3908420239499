var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"d-flex",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center py-2"},[_c('v-breadcrumbs',{staticClass:"pa-0 pl-4",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-chevron-right")])]},proxy:true},{key:"item",fn:function({ item }){return [_c('v-breadcrumbs-item',{attrs:{"to":{ path: item.href },"exact":true,"disabled":item.disabled}},[_c('span',{staticClass:"primary--text",class:{ 'font-weight-bold': item.bold }},[_vm._v(_vm._s(item.text))])])]}}])}),(_vm.contract != null && _vm.contract.type == 'Huur')?_c('help',{attrs:{"bg-color":"#fff","help-id":"1106"}}):(_vm.contract != null && _vm.contract.type == 'Partage')?_c('help',{attrs:{"bg-color":"#fff","help-id":"1107"}}):(_vm.contract != null && _vm.contract.type == 'Staffel')?_c('help',{attrs:{"bg-color":"#fff","help-id":"1108"}}):(_vm.contract != null && _vm.contract.type == 'Suppletie')?_c('help',{attrs:{"bg-color":"#fff","help-id":"1109"}}):(_vm.contract != null && _vm.contract.type == 'Uitkoop')?_c('help',{attrs:{"bg-color":"#fff","help-id":"1110"}}):_vm._e()],1)],1),(_vm.contract != null)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('details-contract',{attrs:{"contract":_vm.contract}}),_c('message',{attrs:{"contract":_vm.contract}}),(_vm.formError != '')?_c('v-alert',{attrs:{"text":"","color":"error"}},[_vm._v(_vm._s(_vm.formError))]):_vm._e(),(
              _vm.rights.edit &&
              _vm.contract.canEdit &&
              _vm.user.organisationTypeId != 2 &&
              _vm.contract.state == 'rejected' &&
              _vm.contract.isLatestVersion
            )?_c('v-btn',{staticClass:"text-lowercase mb-2 mr-2 align-self-start not-visible-on-print",attrs:{"rounded":"","large":"","elevation":"0","loading":_vm.formLoading,"color":"secondary"},on:{"click":_vm.submitConcept}},[_vm._v("Afspraak aanpassen "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-arrow-right")])],1):(
              _vm.rights.edit &&
              _vm.contract.canEdit &&
              _vm.user.organisationTypeId != 2 &&
              _vm.contract.state == 'approved' &&
              _vm.contract.isLatestVersion
            )?_c('v-btn',{staticClass:"text-lowercase primary--text mb-2 mr-2 align-self-start not-visible-on-print",attrs:{"rounded":"","large":"","elevation":"0","loading":_vm.formLoading,"color":"warning"},on:{"click":_vm.submitConcept}},[_vm._v("Afspraak herzien "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-arrow-right")])],1):_vm._e(),(_vm.rights.edit && _vm.contract.state == 'concept')?_c('v-btn',{staticClass:"text-lowercase mb-2 mr-2 align-self-start not-visible-on-print",attrs:{"rounded":"","large":"","outlined":"","elevation":"0","color":"primary","to":{ name: 'ContractsEdit', params: { type: _vm.contract.type.toLowerCase(), contractId: _vm.contract.id } },"loading":_vm.formLoading}},[_vm._v("Afspraak bewerken "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-file-edit-outline")])],1):_vm._e(),(_vm.rights.edit && _vm.contract.state == 'concept')?_c('v-btn',{staticClass:"text-lowercase mb-2 mr-2 align-self-start not-visible-on-print",attrs:{"rounded":"","large":"","elevation":"0","color":"secondary","loading":_vm.formLoading},on:{"click":_vm.submitProposed}},[_vm._v("Afspraak voorstellen "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-arrow-right")])],1):_vm._e(),(
              (_vm.rights.edit || _vm.rights.approve) &&
              _vm.contract.canEdit &&
              _vm.user.organisationTypeId == 2 &&
              (_vm.contract.state == 'proposed' || _vm.contract.state == 'revised') &&
              _vm.contract.isLatestVersion
            )?_c('comment',{attrs:{"contract":_vm.contract,"rights":_vm.rights}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[(
              _vm.contract.state == 'approved' &&
              _vm.contract.isLatestApproved &&
              _vm.contract.tickets != null &&
              _vm.contract.tickets.other.length > 0 &&
              _vm.contract.performances.length > 0
            )?_c('results',{attrs:{"contract":_vm.contract}}):_vm._e(),_c('log',{attrs:{"contract":_vm.contract}}),_c('v-btn',{staticClass:"font-weight-bold text-lowercase mr-2",attrs:{"color":"primary","rounded":"","depressed":""},on:{"click":_vm.downloadPDF}},[_vm._v("Download PDF"),_c('v-icon',{staticClass:"ml-1",attrs:{"right":""}},[_vm._v("mdi-file-move")])],1),_c('v-btn',{staticClass:"font-weight-bold text-lowercase not-visible-on-print",attrs:{"color":"primary","rounded":"","depressed":""}},[_c('vue-excel-xlsx',{staticClass:"v-btn font-weight-bold text-lowercase",attrs:{"data":_vm.exportContract,"columns":_vm.exportContractColumns,"filename":'dip-contract-export',"sheetname":'Contract' + _vm.contract.id}},[_vm._v("exporteer contract "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-file-move")])],1)],1)],1)],1):_vm._e()],1)],1),_c('v-snackbar',{attrs:{"top":"","color":"success"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarText))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }