var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"py-6 px-3 pb-0 mb-4",attrs:{"flat":""}},[_c('v-container',[_c('v-row',[(_vm.contract.theaterContact && _vm.contract.theaterContact.fullName != '')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"primary--text font-weight-medium mb-0"},[_vm._v("contactpersoon afnemer")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.theaterContact &&
              _vm.contract.previousApproved.theaterContact.fullName != '' &&
              _vm.contract.previousApproved.theaterContact.fullName != _vm.contract.theaterContact.fullName
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{staticClass:"primary--text mb-0 pr-2"},[_vm._v(_vm._s(_vm.contract.previousApproved.theaterContact.fullName))]),_c('h3',{staticClass:"error--text pb-4 border-bottom"},[_vm._v(_vm._s(_vm.contract.theaterContact.fullName))])]):_c('h3',{staticClass:"primary--text border-bottom pb-4 mb-4"},[_vm._v(" "+_vm._s(_vm.contract.theaterContact.fullName)+" ")])]):_vm._e(),(_vm.contract.theaterContact && _vm.contract.theaterContact.fullName != '')?_c('v-col',{staticClass:"py-0 mb-8",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"primary--text font-weight-medium mb-0"},[_vm._v("e-mailadres afnemer")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.theaterContact &&
              _vm.contract.previousApproved.theaterContact.email != '' &&
              _vm.contract.previousApproved.theaterContact.email != _vm.contract.theaterContact.email
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{staticClass:"primary--text mb-0 pr-2"},[_vm._v(_vm._s(_vm.contract.previousApproved.theaterContact.email))]),_c('h3',{staticClass:"error--text pb-4 border-bottom"},[_vm._v(_vm._s(_vm.contract.theaterContact.email))])]):_c('h3',{staticClass:"primary--text border-bottom pb-4 mb-0"},[_vm._v(" "+_vm._s(_vm.contract.theaterContact.email)+" ")])]):(_vm.contract.theaterContactEmail != '')?_c('v-col',{staticClass:"py-0 mb-8",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"primary--text font-weight-medium mb-0"},[_vm._v("e-mailadres afnemer")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.theaterContactEmail != '' &&
              _vm.contract.previousApproved.theaterContactEmail != _vm.contract.theaterContactEmail
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{staticClass:"primary--text mb-0 pr-2"},[_vm._v(_vm._s(_vm.contract.previousApproved.theaterContactEmail))]),_c('h3',{staticClass:"error--text pb-4 border-bottom"},[_vm._v(_vm._s(_vm.contract.theaterContactEmail))])]):_c('h3',{staticClass:"primary--text border-bottom pb-4 mb-0"},[_vm._v(" "+_vm._s(_vm.contract.theaterContactEmail)+" ")])]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }