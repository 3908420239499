import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import nl from 'vuetify/es5/locale/nl';

Vue.component('my-component', {
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = 'nl';
    },
  },
});

export default new Vuetify({
  lang: {
    locales: { nl },
    current: 'nl',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#021e72',
        secondary: '#0abfb8',
        accent: '#e1e7f2',
        error: '#d92853',
        info: '#f4f7fc',
        success: '#0abfb8',
        warning: '#fff453',
      },
    },
  },
});
