<template>
    <v-layout
      class="d-flex flex-column align-center justify-center"
      style="min-height: 100vh"
    >
      <v-flex class="d-flex flex-column align-center justify-center pa-5">
        <div>
          <v-img
            src="/images/dip-logo.svg"
            contain
            aspect-ratio="1.6"
            width="115"
            max-width="100%"
            class="ma-5"
          ></v-img>
        </div>
        <v-card
          class="d-flex"
          flat
        >
          <v-flex
            md6
            d-md-flex
            d-none
          >
            <v-img
              src="/images/img-login.png"
              position="right bottom"
              height="100%"
            ></v-img>
          </v-flex>
          <v-flex
            md6
            sm12
          >
            <v-container
              pa-6
              pt-8
              class="d-flex flex-column align-center justify-space-between"
            >
              <h1 class="text-center primary--text mb-2">Tweefactorauthenticatie</h1>
              <v-col
                cols="12"
                class="pa-0 mb-2 text-center"
              >
              <p>Voer de verificatiecode uit de authenticatie-app in. Heb je vragen? Neem contact op met <a href="mailto:support@dip.nl">support@dip.nl</a> en we helpen je graag verder.</p>
              <h4 class="pt-16">Verificatiecode</h4>
              </v-col>
              <div class="ma-auto position-relative" style="max-width: 300px">
                <v-otp-input
                    v-model="otp"
                    :disabled="loading"
                    @finish="onFinish"
                ></v-otp-input>
                <v-overlay absolute :value="loading">
                    <v-progress-circular
                    indeterminate
                    color="primary"
                    ></v-progress-circular>
                </v-overlay>
              </div>
              <v-alert
                text
                type="error"
                v-if="authenticationError"
                >{{ authenticationError }}</v-alert
              >
            </v-container>
          </v-flex>
        </v-card>
      </v-flex>
    </v-layout>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  
  export default {
    name: 'two-factor-auth',
    data() {
      return {
        loading: false,
        otp: '',
      };
    },
    computed: {
      ...mapGetters('auth', ['authenticating', 'authenticationError', 'authenticationErrorCode']),
    },
    methods: {
      ...mapActions('auth', ['twoFactorAuth']),
      onFinish () {
        this.loading = true;
        this.twoFactorAuth({ code: this.otp });
        this.loading = false;
      },
    },
  };
  </script>
  
  <style scoped></style>
  