<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="55"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <!-- Form -->
              <v-form
                @submit.prevent
                v-model="formValid"
                class="d-flex flex-column col col-6"
                ref="form"
              >
                <h1 class="primary--text mb-5">{{ this.areaname }}</h1>
                <h4 class="primary--text mb-2 font-weight-regular">Zaal gegevens</h4>

                <v-card
                  class="pa-6 mb-1"
                  flat
                >
                  <v-row class="mt-0">
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        class="primary--text"
                        label="Naam"
                        v-model="editAreaForm.name"
                        :rules="formValidationRules.required"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- Same adres -->
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-checkbox
                        v-on:change="changeSameAdres()"
                        label="gebruik theater adres"
                        v-model="editAreaForm.sameAdres"
                        class="checkbox primary--text ma-0"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card>

                <template v-if="!editAreaForm.sameAdres">
                  <h4 class="primary--text mb-2 mt-10 font-weight-regular">adres</h4>
                  <v-card
                    class="pa-6 mb-1"
                    flat
                  >
                    <v-row class="mt-0">
                      <v-col
                        cols="8"
                        class="py-0"
                      >
                        <!-- Straat -->
                        <v-text-field
                          label="straat"
                          v-model="editAreaForm.street"
                          :rules="formValidationRules.required"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="4"
                        class="py-0"
                      >
                        <!-- Nummer -->
                        <v-text-field
                          label="huisnummer"
                          v-model="editAreaForm.number"
                          :rules="formValidationRules.required"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="4"
                        class="py-0"
                      >
                        <!-- Postcode -->
                        <v-text-field
                          label="postcode"
                          v-model="editAreaForm.zipCode"
                          :rules="formValidationRules.zipCodeRequired"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="8"
                        class="py-0"
                      >
                        <!-- Plaats -->
                        <v-text-field
                          label="plaats"
                          v-model="editAreaForm.city"
                          :rules="formValidationRules.required"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <!-- Provincie -->
                        <v-autocomplete
                          label="provincie"
                          v-model="editAreaForm.province"
                          :items="provincieOptions"
                          :rules="formValidationRules.required"
                          required
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card>
                </template>

                <p class="grey--text text--lighten-1 mt-0">
                  <small
                    ><i>{{ this.lastEditedBy }}</i></small
                  >
                </p>

                <v-alert
                  v-if="formError != ''"
                  text
                  color="error"
                  >{{ formError }}</v-alert
                >

                <v-btn
                  rounded
                  x-large
                  elevation="0"
                  @click="submit"
                  :loading="formLoading"
                  color="secondary"
                  class="text-lowercase mb-5 align-self-start"
                  >Opslaan
                  <v-icon
                    small
                    right
                    >mdi-content-save</v-icon
                  ></v-btn
                >
              </v-form>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';

export default {
  name: 'AdminTheatersAreaEdit',
  props: ['theaterId', 'areaId'],
  components: {
    Help,
  },
  data() {
    return {
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      date: null,
      menu: false,
      editAreaForm: {
        name: '',
        sameAdres: true,
        street: '',
        number: '',
        zipCode: '',
        city: '',
        province: null,
      },
      lastEditedBy: '',
      provincieOptions: [],
      formValidationRules: {},
      title: 'Zalen',
      areaname: '',
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'theaters',
          disabled: false,
          href: '/admin/theaters',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getArea();
    this.getProvinces();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getArea: function () {
      const vm = this;

      ApiService.get('/TheaterAreas/Get', {
        theaterAreaId: this.areaId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const area = response.data.result;
            this.breadcrumbs[2].text = area.theater.name;
            this.breadcrumbs[2].href = '/admin/theaters/' + this.theaterId;
            this.breadcrumbs[3].text = area.name;
            this.editAreaForm.name = area.name;
            this.editAreaForm.sameAdres = area.onPremise;
            this.editAreaForm.street = area.street;
            this.editAreaForm.number = area.number;
            this.editAreaForm.zipCode = area.zipCode;
            this.editAreaForm.city = area.city;
            this.editAreaForm.province = area.provinceId;
            this.lastEditedBy = area.lastEditedBy;

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getProvinces: function () {
      ApiService.get('/Provinces/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.provincieOptions.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.editAreaForm;

        ApiService.put('/TheaterAreas/Update', {
          id: this.areaId,
          name: form.name,
          street: form.street,
          number: form.number,
          zipCode: form.zipCode,
          city: form.city,
          provinceId: form.province,
          onPremise: form.sameAdres,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch((error) => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
    changeSameAdres() {
      if (this.editAreaForm.sameAdres) {
        this.editAreaForm.street = '';
        this.editAreaForm.number = '';
        this.editAreaForm.zipCode = '';
        this.editAreaForm.city = '';
        this.editAreaForm.province = null;
      }
    },
  },
  computed: {},
  watch: {
    editAreaForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
