<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row
          flex
          align="center"
          justify="space-between"
          class="px-3 mb-4"
        >
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Meer cijfers?</span>
            <div>
              <v-btn
                v-if="this.user.organisationTypeId != 2"
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsSummary' }"
              >
                samenvatting
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
              <v-btn
                v-else
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsProductions' }"
              >
                producties
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
              <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsPerformances' }"
              >
                voorstellingen
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
              <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsProductions10weeks' }"
              >
                producties laatste 10 weken
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="1128"
            ></help>
          </v-col>
        </v-row>

        <div v-if="!tableProductionsLoading">
          <!-- Table -->
          <v-data-table
            :headers="tableProductionsHeaders"
            :items="tableProductions"
            :mobile-breakpoint="0"
            :loading="tableProductionsLoading"
            :page.sync="tableProductionsPage"
            :items-per-page="tableProductionsItemsPerPage"
            hide-default-footer
            @page-count="tableProductionsPageCount = $event"
            class="table-expand"
            item-key="key"
            single-expand
            show-expand
            fixed-header
            :height="tableProductions.length > 10 ? '55vh' : ''"
          >
            <template v-slot:[`item.firstPerformanceDate`]="{ item }">
              {{ item.firstPerformanceDate_formatted }}
            </template>

            <template v-slot:[`item.capacity`]="{ item }">
              <span>{{ formatThousandsWithDot(item.capacity) }}</span>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                class="pa-0"
              >
                <v-data-table
                  :headers="tableProductionsDetailsHeaders"
                  :items="item.details"
                  :mobile-breakpoint="0"
                  hide-default-footer
                  :items-per-page="-1"
                  class="table-action"
                >
                  <template v-slot:[`item.type`]="{ item }">
                    <strong>{{ item.type }}</strong>
                  </template>
                </v-data-table>
              </td>
            </template>

            <v-data-footer></v-data-footer>
          </v-data-table>

          <!-- Table settings -->
          <v-row
            class="text-center pt-2"
            align="center"
          >
            <!-- Table totaal -->
            <v-col cols="12">
              <p class="font-weight-medium text-left caption primary--text ma-0 pa-0">
                totaal: {{ this.tableProductions.length }}
              </p>
            </v-col>

            <!-- Table buttons items per page -->
            <v-col
              cols="12"
              sm="4"
              class="d-flex align-center justify-center justify-sm-start"
            >
              <v-btn
                v-if="tableProductions.length > 25"
                @click="tableProductionsItemsPerPage = 25"
                :color="tableProductionsItemsPerPage == 25 ? 'primary' : '#fff'"
                height="45"
                width="45"
                elevation="0"
                x-small
                class="pa-0 mr-1 font-weight-bold body-2"
                >25</v-btn
              >
              <v-btn
                v-if="tableProductions.length > 25"
                @click="tableProductionsItemsPerPage = 50"
                :color="tableProductionsItemsPerPage == 50 ? 'primary' : '#fff'"
                height="45"
                width="45"
                elevation="0"
                x-small
                class="pa-0 mr-1 font-weight-bold body-2"
                >50</v-btn
              >
              <v-btn
                v-if="tableProductions.length > 50"
                @click="tableProductionsItemsPerPage = 100"
                :color="tableProductionsItemsPerPage == 100 ? 'primary' : '#fff'"
                height="45"
                width="45"
                elevation="0"
                x-small
                class="pa-0 mr-1 font-weight-bold body-2"
                >100</v-btn
              >
            </v-col>

            <!-- Table pagination -->
            <v-col
              cols="12"
              sm="4"
            >
              <v-pagination
                v-if="tableProductionsPageCount > 1"
                v-model="tableProductionsPage"
                :length="tableProductionsPageCount"
                :total-visible="7"
                :elevation="0"
              ></v-pagination>
            </v-col>

            <!-- Table download -->
            <v-col
              cols="12"
              sm="4"
              class="d-flex justify-center justify-sm-end"
            >
              <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase mr-2"
              >
                <vue-excel-xlsx
                  class="v-btn font-weight-bold text-lowercase"
                  :data="exportProductions"
                  :columns="exportProductionsColumns"
                  :file-name="'dip-verkoopmutaties-producties-laatste-7-dagen'"
                  :sheet-name="'producties laatste 7 dagen'"
                  >exporteer<v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
                >
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <div class="text-center progress-circular">
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <h4 class="primary--text mt-5">Een moment geduld aub, je gegevens worden berekend. Dit kan even duren.</h4>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import Help from '../../components/Help.vue';
import functions from '../../services/functions.service';

export default {
  name: 'SalesMutationsProductions7days',
  components: {
    Help,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'verkoopmutaties',
          disabled: false,
          href: '/verkoopmutaties',
          bold: false,
        },
        {
          text: 'producties laatste 7 dagen',
          disabled: true,
          href: '',
          bold: true,
        },
      ],
      inPast: false,
      tableProductions: [],
      tableProductionsLoading: true,
      tableProductionsHeaders: [
        { text: 'productienummer', value: 'productionNumber' },
        { text: 'eerste speeldatum', value: 'firstPerformanceDate' },
        { text: 'productie', value: 'productionTitle' },
        { text: 'genre', value: 'genreName' },
        { text: 'capaciteit', value: 'capacity' },
        { text: '', value: 'data-table-expand', align: 'end' },
      ],
      tableProductionsPage: 1,
      tableProductionsPageCount: 0,
      tableProductionsItemsPerPage: 25,
      tableProductionsDetailsHeaders: [
        { text: '', value: 'type' },
        { text: 'Zondag 25-10', value: 'dag1' },
        { text: 'Zaterdag 24-10', value: 'dag2' },
        { text: 'Vrijdag 23-10', value: 'dag3' },
        { text: 'Donderdg 22-10', value: 'dag4' },
        { text: 'Woensdag 21-10', value: 'dag5' },
        { text: 'Dinsdag 20-10', value: 'dag6' },
        { text: 'Maandag 20-10', value: 'dag7' },
      ],
      exportProductions: [],
      exportProductionsColumns: [],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('SalesMutations');
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    this.getProductions();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageView(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    handleResize() {
      this.screenTableHeight = document.body.scrollHeight - 350;
    },
    getProductions: function () {
      const vm = this;
      this.tableProductions = [];

      ApiService.get('/SalesMutations/GetProductionsForLastSevenDays')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.tableProductions = response.data.result;

          for (var i = 0; i < this.tableProductions.length; i++) {
            vm.$set(
              this.tableProductions[i],
              'firstPerformanceDate_formatted',
              functions.getDateView(this.tableProductions[i].firstPerformanceDate)
            );
            vm.$set(this.tableProductions[i], 'key', i);

            var performanceDetails = [
              {
                type: 'aantal',
              },
              {
                type: 'recette',
              },
              {
                type: 'verschil',
              },
            ];
            for (var j = 0; j < this.tableProductions[i].days.length; j++) {
              performanceDetails[0]['dag' + (j + 1)] = this.formatThousandsWithDot(
                this.tableProductions[i].days[j].ticketCount
              );
              performanceDetails[1]['dag' + (j + 1)] = functions.getEuroSignView(
                this.tableProductions[i].days[j].revenueInVat
              );
              performanceDetails[2]['dag' + (j + 1)] = this.formatThousandsWithDot(
                this.tableProductions[i].days[j].mutation
              );
              this.tableProductionsDetailsHeaders[j + 1].text = functions.getDateView(
                this.tableProductions[i].days[j].date
              );
            }
            this.tableProductions[i].details = performanceDetails;
          }
          this.tableProductionsLoading = false;
          this.createExportData();
        })
        .catch((error) => {
          //eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    createExportData: function () {
      if (this.tableProductions.length > 0) {
        this.exportProductionsColumns = [];
        this.exportProductions = [];

        // create export columns
        for (var i = 1; i < 32; i++) {
          this.exportProductionsColumns.push({ label: '', field: 'column' + i });
        }

        // create export data
        let date = new Date();
        this.exportProductions.push({
          column1: this.user.organisationName,
          column3:
            'Gegenereerd op: ' +
            date.getDate() +
            '-' +
            (date.getMonth() + 1) +
            '-' +
            date.getFullYear() +
            ' ' +
            date.getHours() +
            ':' +
            (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()),
        });
        this.exportProductions.push({});

        this.exportProductions.push({
          column1: 'productienummer',
          column2: 'datum',
          column3: 'productie',
          column4: 'genre',
          column5: '# capaciteit',
        });

        var dayColumstotal = 7 * 3;
        var dayCount = 0;
        for (var y = 0; y < dayColumstotal; y++) {
          var columnNummber = y + 6;
          var columnTitle = '';

          var dayPosition = (y + 1) % 3;
          if (dayPosition == 1) {
            columnTitle = 'aantal';
          } else if (dayPosition == 2) {
            columnTitle = 'recette';
          } else if (dayPosition == 0) {
            columnTitle = 'verschil';
          }
          this.exportProductions[1]['column' + columnNummber] = functions.getDateView(
            this.tableProductions[0].days[dayCount].date
          );
          this.exportProductions[2]['column' + columnNummber] = columnTitle;
          if (dayPosition == 0) {
            dayCount++;
          }
        }

        this.tableProductions.forEach((item) => {
          this.exportProductions.push({
            column1: item.productionNumber,
            column2: item.firstPerformanceDate_formatted,
            column3: item.productionTitle,
            column4: item.genreName,
            column5: item.capacity,

            column6: item.days[0].ticketCount,
            column7:
              item.days[0].revenueInVat != null && item.days[0].revenueInVat != ''
                ? parseFloat(item.days[0].revenueInVat.toFixed(2))
                : '',
            column8: item.days[0].mutation,

            column9: item.days[1].ticketCount,
            column10:
              item.days[1].revenueInVat != null && item.days[1].revenueInVat != ''
                ? parseFloat(item.days[1].revenueInVat.toFixed(2))
                : '',
            column11: item.days[1].mutation,

            column12: item.days[2].ticketCount,
            column13:
              item.days[2].revenueInVat != null && item.days[2].revenueInVat != ''
                ? parseFloat(item.days[2].revenueInVat.toFixed(2))
                : '',
            column14: item.days[2].mutation,

            column15: item.days[3].ticketCount,
            column16:
              item.days[3].revenueInVat != null && item.days[3].revenueInVat != ''
                ? parseFloat(item.days[3].revenueInVat.toFixed(2))
                : '',
            column17: item.days[3].mutation,

            column18: item.days[4].ticketCount,
            column19:
              item.days[4].revenueInVat != null && item.days[4].revenueInVat != ''
                ? parseFloat(item.days[4].revenueInVat.toFixed(2))
                : '',
            column20: item.days[4].mutation,

            column21: item.days[5].ticketCount,
            column22:
              item.days[5].revenueInVat != null && item.days[5].revenueInVat != ''
                ? parseFloat(item.days[5].revenueInVat.toFixed(2))
                : '',
            column23: item.days[5].mutation,

            column24: item.days[6].ticketCount,
            column25:
              item.days[6].revenueInVat != null && item.days[6].revenueInVat != ''
                ? parseFloat(item.days[6].revenueInVat.toFixed(2))
                : '',
            column26: item.days[6].mutation,
          });
        });
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
  watch: {
    tableProductionsFilter: {
      handler: 'filterProductions',
      deep: true,
    },
  },
};
</script>
