var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"d-flex",attrs:{"cols":_vm.colsMobile,"sm":_vm.colsTablet,"md":_vm.colsDesktop}},[_c('v-card',{staticClass:"px-4 py-0 mb-1",attrs:{"flat":"","width":"100%","loading":_vm.performancesThisWeekLoading}},[_c('v-container',[_c('v-row',{staticClass:"block-head"},[_c('v-col',{staticClass:"d-flex align-center justify-space-between",attrs:{"cols":"12"}},[_c('p',{staticClass:"primary--text font-weight-medium mb-0"},[_vm._v("voorstellingen deze week")]),(_vm.helpId != '')?_c('help',{attrs:{"bg-color":"","help-id":_vm.helpId}}):_vm._e()],1)],1),_c('v-row',{staticClass:"py-2 flex-grow-1",attrs:{"flex":"","justify":"center"}},[(_vm.chart.loaded)?[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.performances.length)?_c('v-list',{attrs:{"flat":""}},_vm._l((_vm.performances.slice(0, 5)),function(item,i){return _c('v-list-item',{key:i,staticClass:"tooltip-container",attrs:{"link":"","to":{
                  name: 'SalesMonitorPerformancesDetail',
                  params: {
                    productionNumber: item.productionNumber,
                    performanceId: item.id,
                  },
                },"dense":""}},[_c('span',{staticClass:"tooltip"},[_c('table',[_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("%bezet")]),_c('td',[_vm._v(" "+_vm._s(item.occupancyRate === 0 ? item.occupancyRate + '%' : _vm.getPercentageView(item.occupancyRate))+" ")])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("%bezet landelijk")]),_c('td',[_vm._v(" "+_vm._s(item.totalOccupancyRate === 0 ? item.totalOccupancyRate + '%' : _vm.getPercentageView(item.totalOccupancyRate))+" ")])])])])]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(item.productionTitle)+" ")]),_c('v-list-item-subtitle',{staticClass:"my-1"},[_vm._v(" "+_vm._s(item.startDate_formatted)+" ")]),_c('v-progress-linear',{staticClass:"custom_progress my-2",attrs:{"background-color":'#e1e7f2',"height":20,"width":200,"rounded":true,"value":item.occupancyRate,"color":'#0abfb8'}},[(item.occupancyRate != 0)?_c('div',{staticClass:"progress-text"},[_vm._v(" bezettingspercentage "+_vm._s(_vm.getPercentageView(item.occupancyRate))+" ")]):_vm._e(),(item.totalOccupancyRate > 0)?_c('div',{staticClass:"target",style:({
                        left: item.totalOccupancyRate + '%',
                      })}):_c('div',{staticClass:"progress-text"},[_vm._v(" bezettingspercentage 0% ")])])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-chevron-right")])],1)],1)}),1):_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"primary--text"},[_vm._v(" er zijn geen voorstellingen deze week ")])],1)],1)],1),(_vm.performances.length > 5)?_c('div',{staticClass:"col col-12 pb-0"},[_c('v-btn',{staticClass:"text-lowercase mb-2",attrs:{"rounded":"","small":"","elevation":"0","to":{ name: 'SalesMonitorPerformances' },"color":"primary"}},[_vm._v("alle voorstellingen")])],1):_vm._e()],1)]:[_c('p',{staticClass:"primary--text font-weight-medium mb-0"},[_vm._v("geen data beschikbaar")])]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }