<template>
  <div class="d-flex">
    <v-btn
      rounded
      x-large
      elevation="0"
      color="secondary"
      class="text-lowercase mb-5 mr-2 align-self-start"
      href="https://support.dip.nl/support/solutions"
      target="_blank"
      >instructies</v-btn
    >
    <v-btn
      rounded
      x-large
      elevation="0"
      color="secondary"
      class="text-lowercase mb-5 mr-2 align-self-start"
      href="https://dip.nl/actualiteiten/meldingen"
      target="_blank"
    >
      meldingen
    </v-btn>
    <v-btn
      rounded
      x-large
      elevation="0"
      color="secondary"
      class="text-lowercase mb-5 mr-2 align-self-start"
      href="javascript:void(0)"
      onclick="FreshWidget.show(); return false;"
      >support</v-btn
    >
  </div>
</template>

<script>
export default {
  name: 'HelpButtons',
};
</script>

<style scoped></style>
