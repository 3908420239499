<template>
  <v-layout
    class="d-flex flex-column align-center justify-center"
    style="min-height: 100vh"
  >
    <v-flex class="d-flex flex-column align-center justify-center pa-5 col col-12">
      <div>
        <v-img
          src="/images/dip-logo.svg"
          contain
          aspect-ratio="1.6"
          width="115"
          max-width="100%"
          class="ma-5"
        ></v-img>
      </div>
      <v-card
        class="d-flex col col-12 col-md-6"
        flat
      >
        <v-flex>
          <v-container
            pa-6
            pt-12
            class="d-flex flex-column align-center"
          >
            <h1 class="text-center primary--text mb-5">Wachtwoord instellen</h1>
            <v-form
              @submit.prevent
              v-model="formValid"
              class="col col-12"
              ref="form"
            >
              <v-text-field
                label="nieuw wachtwoord"
                v-model="password"
                :rules="formValidationRules.password1"
                required
                outlined
                min="8"
                :type="showPassword1 ? 'text' : 'password'"
                :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword1 = !showPassword1"
              ></v-text-field>

              <v-text-field
                label="nieuw wachtwoord bevestiging"
                v-model="passwordConfirm"
                :rules="formValidationRules.password2"
                required
                outlined
                min="8"
                :type="showPassword2 ? 'text' : 'password'"
                :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword2 = !showPassword2"
              ></v-text-field>

              <v-btn
                rounded
                x-large
                elevation="0"
                @click="submit"
                :loading="formLoading"
                color="secondary"
                class="text-lowercase font-weight-bold mb-5 mr-5"
                >verstuur</v-btn
              >
            </v-form>
          </v-container>
        </v-flex>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiService from '../../services/api.service';
import router from '../../router/router.js';
// import functions from '../services/functions.service';

export default {
  name: 'resetPassword',
  props: ['token'],
  data() {
    return {
      formValid: false,
      formLoading: false,
      password: '',
      passwordConfirm: '',
      showPassword1: false,
      showPassword2: false,
      formValidationRules: {
        password1: [
          (v) => !!v || 'dit veld is verplicht',
          (v) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/i.test(v) ||
            'minimaal 8 tekens met minstens één hoofdletter, één cijfer en één speciaal teken: !@#$%^&*',
        ],
        password2: [
          (v) => !!v || 'dit veld is verplicht',
          (v) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/i.test(v) ||
            'minimaal 8 tekens met minstens één hoofdletter, één cijfer en één speciaal teken: !@#$%^&*',
          (v) => v == this.password || 'wachtwoorden komen niet overeen',
        ],
      },
    };
  },
  computed: {},
  mounted() {
    let self = this;
    window.addEventListener('keyup', function (event) {
      if (event.keyCode === 13) {
        self.submit();
      }
    });
  },
  methods: {
    submit() {
      this.formLoading = true;
      if (this.formValid) {
        const requestData = {
          method: 'post',
          url: '/Account/ResetPassword',
          data: {
            forgotPasswordToken: this.token,
            newPassword: this.password,
          },
        };
        ApiService.customRequest(requestData)
          .then((response) => {
            if (response.data.success) {
              router.push('/login');
            } else {
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch((error) => {
            this.formError = error;
            // eslint-disable-next-line
            console.log('error ' + error);
            this.errored = true;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
};
</script>

<style scoped></style>
