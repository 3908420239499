import { UserService } from '../services/user.service';
import { TokenService } from '../services/storage.service';
import router from '../router/router';

const state = {
  authenticating: false,
  loggedIn: TokenService.getToken() != '' ? true : false,
  accessToken: TokenService.getToken(),
  authenticationErrorCode: 0,
  authenticationError: '',
  navigation: [],
  user: {},
  navigationStatus: 'test',
};

const getters = {
  authenticationErrorCode: (state) => {
    return state.authenticationErrorCode;
  },

  authenticationError: (state) => {
    return state.authenticationError;
  },

  authenticating: (state) => {
    return state.authenticating;
  },

  getComponentRights: (state) => (componentName) => {
    var result = {
      edit: false,
      approve: false,
    };
    function checkRights(navigationcomponent) {
      navigationcomponent.filter(function (item) {
        if (item.router.component == componentName) {
          result = item.rights;
        } else if (item.children) {
          checkRights(item.children);
        }
      });
    }
    checkRights(state.navigation);

    return result;
  },

  getNavigationStatus: (state) => {
    return state.navigationStatus;
  },
};

const actions = {
  async login({ commit }, { email, password }) {
    commit('loginRequest');

    try {
      const token = await UserService.login(email, password);
      if (token.errorCode != null) {
        const error = token;
        commit('loginError', error);
      } else {
        commit('loginSuccess', token);
        var redirect = setInterval(function () {
          if (state.user.twoFactorEnabled != undefined) {
            clearInterval(redirect);
            if (state.user.twoFactorEnabled) {
              router.push(router.history.current.query.redirect || '/twofactorauth');
            }
          }
          if (state.user.isSuperUser != undefined) {
            clearInterval(redirect);
            commit('loginTrue');
            if (state.user.isSuperUser) {
              router.push(router.history.current.query.redirect || '/');
            } else if (state.user.agreedToTerms) {
              router.push(router.history.current.query.redirect || '/dashboard');
            }
          }
        }, 100);
      }
      return true;
    } catch (e) {
      return false;
    }
  },

  logout({ commit }) {
    if (UserService.logout()) {
      // hotjar
      // window.hj('identify', null, {});

      commit('logoutSuccess');
    }
  },

  async twoFactorAuth({ commit }, { code }) {
    commit('loginRequest');

    try {
      const token = await UserService.twoFactorAuth(code);
      if (token.errorCode != null) {
        const error = token;
        commit('loginError', error);
      } else {
        commit('loginSuccess', token);
        var redirect = setInterval(function () {
          if (state.user.isSuperUser != undefined) {
            clearInterval(redirect);
            commit('loginTrue');
            if (state.user.isSuperUser) {
              router.push(router.history.current.query.redirect || '/');
            } else if (state.user.agreedToTerms) {
              router.push(router.history.current.query.redirect || '/dashboard');
            }
          }
        }, 100);
      }
      return true;
    } catch (e) {
      return false;
    }
  },

  switchRole({ commit }, { userId, userRoleId, organisationId, organisationTypeId }) {
    commit('switchRole');
    UserService.switchRole(userId, userRoleId, organisationId, organisationTypeId);

    // delete cached storage when switching role/organisation
    commit('storage/clearStoredSalesMonitor', null, { root: true });

    if (router.currentRoute.path != '/') {
      router.push('/');
    }
  },

  fetchNavigation(context, navigation) {
    context.commit('setNavigation', navigation);
  },

  fetchNavigationStatus(context) {
    var currentLocation = window.location.host;
    var status = null;

    if (currentLocation.includes('localhost')) {
      status = 4;
    } else if (
      currentLocation.includes('dip-dashboard-tst.tc8l.dev') ||
      currentLocation.includes('dip-test.innovatievooronderwijs.nl') ||
      currentLocation.includes('tst-dashboard.dip.nl') ||
      currentLocation.includes('api-mr-') ||
      currentLocation.includes('ui-mr-')
    ) {
      status = 3;
    } else if (currentLocation.includes('acc-dashboard.dip.nl')) {
      status = 2;
    } else if (currentLocation.includes('dashboard.dip.nl') || currentLocation.includes('snd-dashboard.dip.nl')) {
      status = 1;
    }

    context.commit('setNavigationStatus', status);
  },

  fetchUser(context, user) {
    // hotjar
    // window.hj('identify', user.userId, {
    //     'admin': user.isSuperUser,
    //     'organisatie type id': user.organisationTypeId,
    //     'organisatie type naam': user.organisationTypeId == 1 ? "impresariaat" : user.organisationTypeId == 2 ? "theater" : user.organisationTypeId == 3 ? "producent" : "admin",
    //     'organisatie id': user.organisationId,
    //     'organisatie naam': user.organisationName,
    //     'gebruikers rol': user.roleName,
    // });

    window.userGuiding.identify(user.userId, {
      organisationTypeId: user.organisationTypeId,
      role: user.roleName,
    });

    context.commit('setUser', user);
  },

  fetchAuthenticating(context) {
    context.commit('loginTrue');
  },

};

const mutations = {
  loginRequest(state) {
    state.authenticating = true;
    state.authenticationError = '';
    state.authenticationErrorCode = 0;
  },

  loginSuccess(state, accessToken) {
    state.accessToken = accessToken;
  },

  loginTrue(state) {
    state.loggedIn = true;
    state.authenticating = false;
  },

  loginError(state, { errorCode, errorMessage }) {
    state.authenticating = false;
    state.authenticationErrorCode = errorCode;
    state.authenticationError = errorMessage;
  },

  logoutSuccess(state) {
    state.loggedIn = false;
    state.authenticating = false;
    state.accessToken = '';
    state.navigation = [];
    state.user = {};
    state.accessToken = [];
    router.push('/login');
    setTimeout(function () {
      TokenService.changeCanSetCookie(true);
    }, 1000);
  },

  switchRole(state) {
    state.authenticating = true;
    state.navigation = [];
    state.user = {};
  },

  setNavigation(state, navigation) {
    state.navigation = navigation;
  },

  setNavigationStatus(state, status) {
    state.navigationStatus = status;
  },

  setUser(state, user) {
    state.user = user;
  },
};

const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default auth;
