<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="1144"
            ></help>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <!-- Filter -->
            <v-col class="py-0 d-flex align-center justify-start">
              <div class="d-none d-sm-flex flex-sm-wrap">
                <v-col
                  sm="4"
                  md="2"
                  class="pa-0"
                >
                  <v-autocomplete
                    v-model="filterWidgets.buyerAge"
                    :items="buyerAges"
                    label="leeftijdsopbouw"
                    clearable
                    hide-details
                    dense
                    outlined
                    background-color="#fff"
                    class="ma-1 input-sm"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  sm="4"
                  md="2"
                  class="pa-0"
                >
                  <v-autocomplete
                    v-model="filterWidgets.purchasePeriod"
                    :items="purchasePeriods"
                    label="moment van aankoop"
                    clearable
                    hide-details
                    dense
                    outlined
                    background-color="#fff"
                    class="ma-1 input-sm"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  sm="4"
                  md="2"
                  class="pa-0"
                >
                  <v-autocomplete
                    v-if="this.user.organisationTypeId != 2"
                    v-model="filterWidgets.salesType"
                    :items="salesTypes"
                    label="zoek op prijssoort"
                    clearable
                    hide-details
                    dense
                    outlined
                    background-color="#fff"
                    class="ma-1 input-sm"
                  ></v-autocomplete>
                  <v-autocomplete
                    v-else
                    v-model="filterWidgets.originalSalesType"
                    :items="salesTypes"
                    label="zoek op prijssoort"
                    clearable
                    hide-details
                    dense
                    outlined
                    background-color="#fff"
                    class="ma-1 input-sm"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  sm="4"
                  md="2"
                  class="pa-0"
                >
                  <v-autocomplete
                    v-model="filterWidgets.buyerLoyalty"
                    :items="buyerLoyalties"
                    label="loyaliteit"
                    clearable
                    hide-details
                    dense
                    outlined
                    background-color="#fff"
                    class="ma-1 input-sm"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  sm="4"
                  md="2"
                  class="pa-0"
                >
                  <v-autocomplete
                    v-model="filterWidgets.buyerBehavior"
                    :items="buyerBehaviors"
                    label="aantal aankopen"
                    clearable
                    hide-details
                    dense
                    outlined
                    background-color="#fff"
                    class="ma-1 input-sm"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  sm="4"
                  md="2"
                  class="pa-0"
                >
                  <v-autocomplete
                    v-model="filterWidgets.buyerGender"
                    :items="buyerGenders"
                    label="geslacht kaartkopers"
                    clearable
                    hide-details
                    dense
                    outlined
                    background-color="#fff"
                    class="ma-1 input-sm"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  sm="4"
                  md="2"
                  class="pa-0"
                >
                  <v-autocomplete
                    v-model="filterWidgets.buyerDistance"
                    :items="buyerDistances"
                    label="geografische spreiding"
                    clearable
                    hide-details
                    dense
                    outlined
                    background-color="#fff"
                    class="ma-1 input-sm"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  sm="4"
                  md="2"
                  class="pa-0"
                >
                  <v-autocomplete
                    v-model="filterWidgets.ticketCount"
                    :items="ticketCounts"
                    label="aantal gekochte kaarten"
                    clearable
                    hide-details
                    dense
                    outlined
                    background-color="#fff"
                    class="ma-1 input-sm"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  sm="4"
                  md="2"
                  class="pa-0"
                >
                  <v-autocomplete
                    v-model="filterWidgets.dayOfPurchase"
                    :items="dayOfPurchases"
                    label="dag van aankoop"
                    clearable
                    hide-details
                    dense
                    outlined
                    background-color="#fff"
                    class="ma-1 input-sm"
                  ></v-autocomplete>
                </v-col>
              </div>

              <!-- Filter dialog -->
              <div class="d-sm-none">
                <v-dialog
                  v-model="widgetFilterDialog"
                  width="500"
                  fullscreen
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="success"
                      rounded
                      dark
                      v-on="on"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                  </template>

                  <v-card>
                    <v-card-title class="headline dark"><h5 class="primary--text">filter overzicht</h5></v-card-title>
                    <v-card-text>
                      <v-autocomplete
                        v-if="this.user.organisationTypeId != 2"
                        v-model="filterWidgets.salesType"
                        :items="salesTypes"
                        label="zoek op prijssoort"
                        clearable
                        hide-details
                        dense
                        outlined
                        background-color="#fff"
                        class="ma-1 input-sm"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-else
                        v-model="filterWidgets.originalSalesType"
                        :items="salesTypes"
                        label="zoek op prijssoort"
                        clearable
                        hide-details
                        dense
                        outlined
                        background-color="#fff"
                        class="ma-1 input-sm"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-model="filterWidgets.purchasePeriod"
                        :items="purchasePeriods"
                        label="moment van aankoop"
                        clearable
                        hide-details
                        dense
                        outlined
                        background-color="#fff"
                        class="ma-1 input-sm"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-model="filterWidgets.buyerAge"
                        :items="buyerAges"
                        label="leeftijdsopbouw"
                        clearable
                        hide-details
                        dense
                        outlined
                        background-color="#fff"
                        class="ma-1 input-sm"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-model="filterWidgets.buyerLoyalty"
                        :items="buyerLoyalties"
                        label="loyaliteit"
                        clearable
                        hide-details
                        dense
                        outlined
                        background-color="#fff"
                        class="ma-1 input-sm"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-model="filterWidgets.buyerBehavior"
                        :items="buyerBehaviors"
                        label="aantal aankopen"
                        clearable
                        hide-details
                        dense
                        outlined
                        background-color="#fff"
                        class="ma-1 input-sm"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-model="filterWidgets.buyerGender"
                        :items="buyerGenders"
                        label="geslacht kaartkopers"
                        clearable
                        hide-details
                        dense
                        outlined
                        background-color="#fff"
                        class="ma-1 input-sm"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-model="filterWidgets.buyerDistance"
                        :items="buyerDistances"
                        label="geografische spreiding"
                        clearable
                        hide-details
                        dense
                        outlined
                        background-color="#fff"
                        class="ma-1 input-sm"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-model="filterWidgets.ticketCount"
                        :items="ticketCounts"
                        label="aantal gekochte kaarten"
                        clearable
                        hide-details
                        dense
                        outlined
                        background-color="#fff"
                        class="ma-1 input-sm"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-model="filterWidgets.dayOfPurchase"
                        :items="dayOfPurchases"
                        label="dag van aankoop"
                        clearable
                        hide-details
                        dense
                        outlined
                        background-color="#fff"
                        class="ma-1 input-sm"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-if="user.organisationTypeId != 2"
                        v-model="filterWidgets.theaterId"
                        :items="theaters"
                        label="selecteer een theater"
                        clearable
                        hide-details
                        dense
                        outlined
                        background-color="#fff"
                        class="ma-1 input-sm"
                      ></v-autocomplete>
                    </v-card-text>

                    <v-card-actions class="justify-center flex-column">
                      <v-btn
                        class="font-weight-bold text-lowercase mb-5"
                        color="success"
                        depressed
                        @click="widgetFilterDialog = false"
                        >filter overzicht
                        <v-icon
                          small
                          right
                          >mdi-filter</v-icon
                        ></v-btn
                      >
                      <v-btn
                        class="font-weight-bold text-lowercase"
                        color="error"
                        outlined
                        dark
                        @click="clearFilterWidgets()"
                        >reset filter
                        <v-icon
                          small
                          right
                          >mdi-close</v-icon
                        ></v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
          </v-col>
        </v-row>

        <v-row
          align="center"
          class="px-3 mb-4 print-hide"
          :class="!hasTooFewPurchases ? '' : 'hide'"
          v-if="this.user.organisationTypeId == 2"
        >
          <v-col class="d-flex justify-end align-center flex-wrap flex-md-nowrap">
            <filter-period
              :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
              :selectedDefault="reportPeriod.from != null ? 'custom' : 'thisSeason'"
              :customPeriod="reportPeriod.from != null ? reportPeriod : null"
              @changePeriod="filterPeriod = $event"
            ></filter-period>
            <v-autocomplete
              v-model="filterWidgets.compareWith"
              :items="compareWithOptions"
              label="vergelijken met"
              clearable
              hide-details
              dense
              outlined
              background-color="#fff"
              class="mr-2 input-sm flex-grow-0"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row
          v-else
          align="center"
          class="px-3 mb-4 print-hide"
          :class="!hasTooFewPurchases ? '' : 'hide'"
        >
          <v-col class="d-flex justify-end align-center flex-wrap flex-md-nowrap">
            <filter-period
              :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
              :selectedDefault="reportPeriod.from != null ? 'custom' : 'thisSeason'"
              :customPeriod="reportPeriod.from != null ? reportPeriod : null"
              @changePeriod="filterPeriod = $event"
            ></filter-period>
          </v-col>
        </v-row>

        <div v-show="!this.tableReportPerformancesLoading">
          <template v-if="hasTooFewPurchases">
            <!-- hasTooFewPurchases  -->
            <v-row flex>
              <v-col cols="12">
                <div class="too-few-purchases-fallback">
                  <span>De huidige selectie bevat minder dan 5 kaartkopers</span>
                </div>
              </v-col>
            </v-row>
            <v-row flex>
              <v-col md="6">
                <v-card
                  class="py-5 px-3 pb-0 mb-18"
                  flat
                  height="100%"
                >
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                        class="py-0"
                      >
                        <p class="primary--text font-weight-medium mb-0">aantal voorstellingen</p>
                        <h3 class="primary--text border-bottom pb-0 mb-4">
                          {{ this.performances.length }}
                        </h3>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        class="py-0"
                      >
                        <v-btn
                          rounded
                          large
                          elevation="0"
                          color="secondary"
                          class="text-lowercase mb-4"
                          :to="{
                            name: 'PublicMonitorReportsEdit',
                            params: { reportNumber: this.report.id },
                          }"
                        >
                          bewerk rapport</v-btn
                        >
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        class="py-0"
                      >
                        <p
                          class="primary--text font-weight-medium mb-0"
                          v-if="!this.report.playDay"
                        >
                          eerste speeldatum
                        </p>
                        <p
                          class="primary--text font-weight-medium mb-0"
                          v-else
                        >
                          speeldatum
                        </p>
                        <h3 class="primary--text border-bottom pb-0 mb-4">
                          {{ getDateTimeView(this.report.firstStartDate) }}
                        </h3>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        class="py-0"
                      >
                        <div v-if="!this.report.playDay">
                          <p class="primary--text font-weight-medium mb-0">laatste speeldatum</p>
                          <h3 class="primary--text border-bottom pb-0 mb-4">
                            {{ getDateTimeView(this.report.lastStartDate) }}
                          </h3>
                        </div>
                        <div v-else>
                          <p class="primary--text font-weight-medium mb-0">speeldag</p>
                          <h3 class="primary--text border-bottom pb-0 mb-4">
                            {{ this.report.playDay }}
                          </h3>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </div>

        <div v-show="!this.tableReportPerformancesLoading">
          <v-row
            flex
            v-if="!hasTooFewPurchases"
          >
            <!-- Basis informatie -->
            <v-col md="6">
              <v-card
                class="py-5 px-3 pb-0 mb-18"
                flat
                height="100%"
              >
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      class="py-0"
                    >
                      <p class="primary--text font-weight-medium mb-0">aantal voorstellingen</p>
                      <h3 class="primary--text border-bottom pb-0 mb-4">
                        {{ this.performances.length }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="py-0"
                    >
                      <v-btn
                        rounded
                        large
                        elevation="0"
                        color="secondary"
                        class="text-lowercase mb-4 print-hide"
                        :to="{
                          name: 'PublicMonitorReportsEdit',
                          params: { reportNumber: this.report.id },
                        }"
                      >
                        bewerk rapport</v-btn
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="py-0"
                    >
                      <p
                        class="primary--text font-weight-medium mb-0"
                        v-if="!this.report.playDay"
                      >
                        eerste speeldatum
                      </p>
                      <p
                        class="primary--text font-weight-medium mb-0"
                        v-else
                      >
                        speeldatum
                      </p>
                      <h3 class="primary--text border-bottom pb-0 mb-4">
                        {{ getDateTimeView(this.report.firstStartDate) }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="py-0"
                    >
                      <div v-if="!this.report.playDay">
                        <p class="primary--text font-weight-medium mb-0">laatste speeldatum</p>
                        <h3 class="primary--text border-bottom pb-0 mb-4">
                          {{ getDateTimeView(this.report.lastStartDate) }}
                        </h3>
                      </div>
                      <div v-else>
                        <p class="primary--text font-weight-medium mb-0">speeldag</p>
                        <h3 class="primary--text border-bottom pb-0 mb-4">
                          {{ this.report.playDay }}
                        </h3>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>

            <!-- Bezettingsgraad -->
            <v-col
              cols="12"
              sm="12"
              md="6"
              class="pa-0 ma-0 d-flex flex-grow-1 align-stretch hw-print"
              v-if="
                this.publicMonitorOccupancyRate.capacity != 0 ||
                this.publicMonitorOccupancyRate.totalTickets != 0 ||
                this.publicMonitorOccupancyRate.purchases != 0
              "
            >
              <v-col class="d-flex">
                <v-card
                  class="pa-5 mb-1"
                  flat
                  width="100%"
                  height="100%"
                >
                  <v-container>
                    <v-row
                      flex
                      class="d-flex flex-grow-1"
                    >
                      <template>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          class="py-0"
                        >
                          <p class="primary--text font-weight-medium mb-0">capaciteit</p>
                          <h3 class="primary--text border-bottom pb-0 mb-4 d-flex">
                            {{ formatThousandsWithDot(this.publicMonitorOccupancyRate.capacity) }}
                            <div
                              v-if="this.publicMonitorOccupancyRate.comparison != null"
                              class=""
                            >
                              /{{ formatThousandsWithDot(this.publicMonitorOccupancyRate.comparison.capacity) }}
                            </div>
                          </h3>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          class="py-0"
                        >
                          <p class="primary--text font-weight-medium mb-0">Aantal verkochte kaarten</p>
                          <h3 class="primary--text border-bottom pb-0 mb-4 d-flex">
                            {{ formatThousandsWithDot(this.publicMonitorOccupancyRate.tickets) }}
                            <div
                              v-if="
                                this.publicMonitorOccupancyRate.tickets != this.publicMonitorOccupancyRate.totalTickets
                              "
                            >
                              /{{ formatThousandsWithDot(this.publicMonitorOccupancyRate.totalTickets) }}
                            </div>
                            <div v-if="this.publicMonitorOccupancyRate.comparison != null">
                              /{{ formatThousandsWithDot(this.publicMonitorOccupancyRate.comparison.tickets) }}
                            </div>
                          </h3>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          class="py-0"
                          v-if="this.publicMonitorOccupancyRate != null"
                        >
                          <p class="primary--text font-weight-medium mb-0">Aantal kaartkopers</p>
                          <h3 class="primary--text border-bottom pb-0 mb-4 d-flex">
                            {{ formatThousandsWithDot(this.publicMonitorOccupancyRate.purchases) }}
                            <div
                              v-if="
                                this.publicMonitorOccupancyRate.purchases !=
                                this.publicMonitorOccupancyRate.totalPurchases
                              "
                            >
                              /{{ formatThousandsWithDot(this.publicMonitorOccupancyRate.totalPurchases) }}
                            </div>
                            <div v-if="this.publicMonitorOccupancyRate.comparison != null">
                              /{{ formatThousandsWithDot(this.publicMonitorOccupancyRate.comparison.purchases) }}
                            </div>
                          </h3>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="12"
                          md="6"
                          class="py-0"
                          v-if="this.publicMonitorOccupancyRate != null"
                        >
                          <p class="primary--text font-weight-medium mb-0">aantal nieuwe kaartkopers</p>
                          <h3 class="primary--text border-bottom pb-0 mb-4 d-flex">
                            {{ formatThousandsWithDot(this.publicMonitorOccupancyRate.newPurchases) }} ({{ this.newPurchasesPercentage }}% van totaal)
                          </h3>
                        </v-col>
                        <v-col
                          cols="12"
                          class="pt-1 pb-4"
                          v-if="this.publicMonitorOccupancyRate.rate > 0"
                        >
                          <v-progress-linear
                            class="custom_progress"
                            :background-color="'#e1e7f2'"
                            :height="20"
                            :width="200"
                            :rounded="true"
                            :value="this.publicMonitorOccupancyRate.rate"
                            :color="'#0abfb8'"
                          >
                            <div
                              class="target"
                              :style="{
                                left: this.publicMonitorOccupancyRate.comparison.rate + '%',
                              }"
                              v-if="this.publicMonitorOccupancyRate.comparison != null"
                            ></div>
                          </v-progress-linear>
                          <i
                            v-if="
                              this.publicMonitorOccupancyRate.rate > 0 &&
                              this.publicMonitorOccupancyRate.comparison == null
                            "
                          >
                            bezettingspercentage
                            {{ getPercentageView(this.publicMonitorOccupancyRate.rate) }}
                          </i>
                          <i
                            v-else-if="
                              this.publicMonitorOccupancyRate.rate > 0 &&
                              this.publicMonitorOccupancyRate.comparison != null
                            "
                          >
                            {{
                              `bezettingspercentage ${getPercentageView(
                                this.publicMonitorOccupancyRate.rate
                              )} (alle voorstellingen: ${getPercentageView(
                                this.publicMonitorOccupancyRate.comparison.rate
                              )})`
                            }}
                          </i>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-col>

            <!-- Leeftijd per klant -->
            <v-col
              cols="12"
              sm="12"
              md="4"
              class="pa-0 ma-0 d-block d-sm-flex d-md-block hw-print"
            >
              <age-visitors
                :cols-desktop="12"
                :cols-tablet="12"
                :cols-mobile="12"
                :help-id="1133"
                :widget-data="publicMonitorCustomerAges"
              ></age-visitors>
            </v-col>

            <!-- Moment van aankoop -->
            <v-col
              cols="12"
              sm="12"
              md="4"
              class="pa-0 ma-0 d-block d-sm-flex d-md-block hw-print"
            >
              <purchase-periods
                :cols-desktop="12"
                :cols-tablet="12"
                :cols-mobile="12"
                :help-id="1134"
                :widget-data="publicMonitorPurchasePeriods"
                :fill-height="true"
              ></purchase-periods>
            </v-col>

            <!-- Prijssoorten -->
            <v-col
              cols="12"
              sm="12"
              md="4"
              class="pa-0 ma-0 d-block d-sm-flex d-md-block hw-print"
            >
              <sales-types
                :cols-desktop="12"
                :cols-tablet="12"
                :cols-mobile="12"
                :help-id="1135"
                :widget-data="publicMonitorTicketSorts"
              ></sales-types>
            </v-col>

            <!-- Loyaliteit -->
            <v-col
              cols="12"
              sm="12"
              md="4"
              class="pa-0 ma-0 d-flex hw-print"
            >
              <loyalties
                :cols-desktop="12"
                :cols-tablet="12"
                :cols-mobile="12"
                :help-id="1136"
                :widget-data="publicMonitorLoyalties"
              ></loyalties>
            </v-col>

            <!-- Kaarten per klant -->
            <v-col
              cols="12"
              sm="12"
              md="4"
              class="pa-0 ma-0 d-block d-sm-flex d-md-block hw-print"
            >
              <tickets-per-customer
                :cols-desktop="12"
                :cols-tablet="12"
                :cols-mobile="12"
                :help-id="1137"
                :widget-data="publicMonitorTicketsPerCustomer"
              ></tickets-per-customer>
            </v-col>

            <!-- Gedrag kaartkopers -->
            <v-col
              cols="12"
              sm="12"
              md="4"
              class="pa-0 ma-0 d-block d-sm-flex d-md-block hw-print"
            >
              <buyer-behaviors
                :cols-desktop="12"
                :cols-tablet="12"
                :cols-mobile="12"
                :help-id="1138"
                :widget-data="publicMonitorBuyerBehaviors"
              ></buyer-behaviors>
            </v-col>

            <!-- Geslacht kaartkopers -->
            <v-col
              cols="12"
              sm="12"
              md="4"
              class="pa-0 ma-0 d-flex hw-print"
            >
              <buyer-genders
                :cols-desktop="12"
                :cols-tablet="12"
                :cols-mobile="12"
                :help-id="1139"
                :widget-data="publicMonitorBuyerGenders"
              ></buyer-genders>
            </v-col>

            <!-- Geografische spreiding -->
            <v-col
              cols="12"
              sm="12"
              md="4"
              class="pa-0 ma-0 d-flex hw-print"
            >
              <buyer-distances
                :cols-desktop="12"
                :cols-tablet="12"
                :cols-mobile="12"
                :help-id="1140"
                :widget-data="publicMonitorBuyerDistances"
              ></buyer-distances>
            </v-col>

            <!-- Dag van aankoop  -->
            <v-col
              cols="12"
              sm="12"
              md="4"
              class="pa-0 ma-0 d-flex hw-print"
            >
              <day-of-purchase
                :cols-desktop="12"
                :cols-tablet="12"
                :cols-mobile="12"
                :help-id="1140"
                :widget-data="publicMonitorDayOfPurchases"
              ></day-of-purchase>
            </v-col>

            <!-- Verkoop per gemeente -->
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="pa-0 ma-0 d-flex print-big-chart"
            >
              <map-chart-municipalities
                :cols-desktop="12"
                :cols-tablet="12"
                :cols-mobile="12"
                :help-id="1141"
                :mapData="mapDataMunicipalities"
              ></map-chart-municipalities>
            </v-col>

            <!-- Kaartverkoop over tijd  -->
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="pa-0 ma-0 d-flex print-big-chart"
            >
              <ticket-sales-over-time
                :cols-desktop="12"
                :cols-tablet="12"
                :cols-mobile="12"
                :help-id="1140"
                :widget-data="publicMonitorTicketSalesOverTime"
              ></ticket-sales-over-time>
            </v-col>
          </v-row>

          <div
            v-if="!hasTooFewPurchases"
            class="pt-5"
          >
            <p class="primary--text font-weight-medium mb-0">voorstellingen</p>
          </div>

          <!-- Table -->
          <v-data-table
            v-if="!hasTooFewPurchases"
            :headers="tablePublicMonitorHeadersFiltered"
            :items="tableReportPerformancesFiltered"
            :mobile-breakpoint="0"
            :loading="tableReportPerformancesLoading"
            :items-per-page="-1"
            hide-default-footer
            class="table-action row-is-clickable mt-5"
            @click:row="goToPerformance"
            fixed-header
            :height="tableReportPerformancesFiltered.length > 10 ? '55vh' : ''"
          >
            <template v-slot:[`item.startDate`]="{ item }">
              {{ item.startDateView }}
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="
                  item.number != null &&
                  item.number != 'no_export' &&
                  item.dipId != null &&
                  item.hasTooFewPurchases == false
                "
                fab
                x-small
                depressed
                color="info"
                class="ml-1"
                :to="{
                  name: 'SalesMonitorPerformancesDetail',
                  params: {
                    productionNumber: item.production.number,
                    performanceId: item.dipId,
                  },
                }"
              >
                <v-icon color="primary">mdi-link-variant</v-icon>
              </v-btn>

              <v-btn
                fab
                x-small
                depressed
                color="warning"
                class="ml-1"
                :to="{
                  name: 'PublicMonitorPerformancesDetail',
                  params: {
                    productionNumber: item.production.id,
                    performanceId: item.id,
                  },
                }"
              >
                <v-icon color="primary">mdi-eye</v-icon>
              </v-btn>
            </template>
            <v-data-footer></v-data-footer>
          </v-data-table>

          <v-row class="my-1 print-hide">
            <v-col
              class="d-flex align-center justify-end"
              cols="12"
              sm="12"
              md="12"
            >
              <!-- Download PDF -->
              <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase mr-2"
                @click="printPage"
              >
                Download PDF
                <v-icon
                  class="ml-1"
                  right
                  >mdi-file-move</v-icon
                >
              </v-btn>

              <!-- Download Excel -->
              <v-btn
                color="warning"
                rounded
                depressed
                class="font-weight-bold text-lowercase primary--text"
              >
                <vue-excel-xlsx
                  class="v-btn font-weight-bold text-lowercase"
                  :data="exportData"
                  :columns="exportDataColumns"
                  :file-name="'dip-publieksmonitor-rapportages'"
                  :sheet-name="'publieksmonitor rapportages'"
                  >download XLS <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
                >
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-show="this.tableReportPerformancesLoading">
          <div class="text-center progress-circular">
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <h4 class="primary--text mt-5">Een moment geduld aub, je gegevens worden berekend. Dit kan even duren.</h4>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import { TokenService } from '@/services/storage.service';
import ApiService from '@/services/api.service';
import Help from '@/components/Help';
import functions from '../../services/functions.service';
import FilterPeriod from '../../components/FilterPeriod';
import createExportData from './components/ExportData/createExportData';

// Widgets
import AgeVisitors from '../../components/widgets/PublicMonitor/AgeVisitors.vue';
import PurchasePeriods from '../../components/widgets/PublicMonitor/PurchasePeriod.vue';
import Loyalties from '../../components/widgets/PublicMonitor/Loyalties.vue';
import TicketsPerCustomer from '../../components/widgets/PublicMonitor/TicketsPerCustomer.vue';
import SalesTypes from '../../components/widgets/PublicMonitor/SalesTypes.vue';
import BuyerBehaviors from '../../components/widgets/PublicMonitor/BuyerBehaviors.vue';
import MapChartMunicipalities from '../../components/widgets/PublicMonitor/MapChartMunicipalities.vue';
import BuyerGenders from '../../components/widgets/PublicMonitor/BuyerGenders.vue';
import BuyerDistances from '../../components/widgets/PublicMonitor/BuyerDistances.vue';
import DayOfPurchase from '../../components/widgets/PublicMonitor/DayOfPurchase.vue';
import TicketSalesOverTime from '../../components/widgets/PublicMonitor/TicketSaleOverTime.vue';

export default {
  name: 'PublicMonitorReportsDetail',
  props: ['reportNumber'],
  components: {
    Help,
    FilterPeriod,
    AgeVisitors,
    PurchasePeriods,
    Loyalties,
    TicketsPerCustomer,
    SalesTypes,
    BuyerBehaviors,
    MapChartMunicipalities,
    BuyerGenders,
    BuyerDistances,
    DayOfPurchase,
    TicketSalesOverTime,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'publieksmonitor',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'rapportages',
          disabled: false,
          href: '/publieksmonitor/rapportages',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      report: {
        id: 0,
        title: '',
        genre: '',
        firstStartDate: '',
        lastStartDate: '',
        playDay: '',
      },
      performances: [],
      reportPeriod: {
        from: null,
        to: null,
      },
      filterPeriod: {
        from: '',
        to: '',
      },
      widgetFilterDialog: false,
      tableReportPerformancesFiltered: [],
      tableReportPerformancesLoading: true,
      tablePublicMonitorHeaders: [
        { text: 'startdatum', value: 'startDate' },
        { text: 'productie', value: 'production.title' },
        { text: 'verkocht', value: 'tickets' },
        { text: 'theater', value: 'theater.name', showFor: [1, 3] },
        { text: 'zaal', value: 'area' },
        { text: 'gem. tickets', value: 'averageTickets' },
        { text: 'gem. leeftijd', value: 'averageAge' },
        { text: '', value: 'action', sortable: false, align: 'end' },
      ],
      tablePublicMonitorPage: 1,
      tablePublicMonitorPageCount: 0,
      tablePublicMonitorItemsPerPage: 25,
      tablePublicMonitorFilterDialog: false,
      publicMonitorCustomerAges: [],
      publicMonitorPurchasePeriods: [],
      publicMonitorLoyalties: [],
      publicMonitorTicketsPerCustomer: [],
      publicMonitorTicketSorts: [],
      publicMonitorOccupancyRate: {},
      publicMonitorBuyerBehaviors: [],
      publicMonitorBuyerGenders: [],
      publicMonitorBuyerDistances: [],
      publicMonitorDayOfPurchases: [],
      publicMonitorTicketSalesOverTime: [],
      filterWidgets: {
        theaterId: '',
        salesType: '',
        originalSalesTypes: '',
        purchasePeriod: '',
        buyerAge: '',
        buyerLoyalty: '',
        buyerBehavior: '',
        buyerGender: '',
        buyerDistance: '',
        ticketCount: '',
        compareWith: '',
        dayOfPurchase: '',
      },
      theaters: [],
      salesTypes: [],
      originalSalesTypes: [],
      buyerAges: [],
      buyerLoyalties: [],
      buyerBehaviors: [],
      purchasePeriods: [],
      buyerGenders: [],
      buyerDistances: [],
      dayOfPurchases: [],
      ticketSalesOverTimes: [],
      ticketCounts: [],
      compareWithOptions: [],
      mapDataMunicipalities: null,
      hasTooFewPurchases: false,
      exportData: [],
      exportDataColumns: [],
    };
  },
  created() {
    if (this.$route.query.startDate) {
      this.reportPeriod.from = new Date(this.$route.query.startDate);
    }
    if (this.$route.query.endDate) {
      this.reportPeriod.to = new Date(this.$route.query.endDate);
    }
  },
  mounted() {
    this.rights = this.getComponentRights('PublicMonitor');
    this.createExportData(this, 'report');
  },
  destroyed() {
    const tooltips = document.body.querySelectorAll('.tooltip');
    if (tooltips && tooltips.length > 0) {
      tooltips.forEach((el) => {
        document.body.removeChild(el);
      });
    }
  },
  beforeDestroy() {
    this.clearStoredFilterPeriod();
  },
  methods: {
    ...mapMutations('storage', ['clearStoredFilterPeriod']),
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageViewWithoutDecimals(value);
    },
    getDateTimeView(date) {
      return functions.getDateTimeView(date);
    },
    getProductionPerformances: function () {
      const vm = this;
      this.performances = [];
      this.tableReportPerformancesFiltered = [];
      this.tableReportPerformancesLoading = true;

      ApiService.get('/PublicMonitor/GetReportPerformances', {
        startDate: functions.jsDateToStringDate(this.filterPeriod.from),
        endDate: functions.jsDateToStringDate(this.filterPeriod.to),
        ReportId: this.reportNumber,
        TheaterId: this.filterWidgets.theaterId,
        SalesType: this.filterWidgets.salesType,
        OriginalSalesType: this.filterWidgets.originalSalesType,
        BuyerAge: this.filterWidgets.buyerAge,
        BuyerLoyalty: this.filterWidgets.buyerLoyalty,
        BuyerBehavior: this.filterWidgets.buyerBehavior,
        PurchasePeriod: this.filterWidgets.purchasePeriod,
        BuyerGender: this.filterWidgets.buyerGender,
        BuyerDistance: this.filterWidgets.buyerDistance,
        Ticket: this.filterWidgets.ticketCount,
        CompareWith: this.filterWidgets.compareWith,
        DayOfPurchase: this.filterWidgets.dayOfPurchase,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          const report = response.data.result;

          this.report.id = report.id;
          this.report.name = report.name;
          this.report.firstStartDate = report.firstStartDate;
          this.report.lastStartDate = report.lastStartDate;
          this.report.playDay = report.playDay;
          this.breadcrumbs[2].text = report.name;
          this.hasTooFewPurchases = report.hasTooFewPurchases;

          this.mapDataMunicipalities = response.data.result.municipalities;

          return response;
        })
        .then((response) => {
          this.performances = response.data.result.performances;

          for (var i = 0; i < this.performances.length; i++) {
            vm.$set(this.performances[i], 'startDateView', functions.getDateTimeView(this.performances[i].startDate));

            vm.theaters.push({
              value: this.performances[i].theater.id,
              text: this.performances[i].theater.name,
            });
          }

          this.tableReportPerformancesFiltered = this.performances;

          this.publicMonitorCustomerAges = response.data.result.buyerAges;
          this.publicMonitorPurchasePeriods = response.data.result.purchasePeriods;

          this.publicMonitorLoyalties = response.data.result.buyerLoyalties;
          this.publicMonitorTicketsPerCustomer = response.data.result.tickets;

          // if theater show raw ticket data
          if (this.user.organisationTypeId == 2) {
            this.publicMonitorTicketSorts = response.data.result.originalSalesTypes;
          } else {
            this.publicMonitorTicketSorts = response.data.result.salesTypes;
          }

          this.publicMonitorOccupancyRate = response.data.result.occupancy;
          this.publicMonitorBuyerBehaviors = response.data.result.buyerBehaviors;
          this.publicMonitorBuyerGenders = response.data.result.buyerGenders;
          this.publicMonitorBuyerDistances = response.data.result.buyerDistances;
          this.publicMonitorDayOfPurchases = response.data.result.dayOfPurchases;
          this.publicMonitorTicketSalesOverTime = response.data.result.salesOverTimes;

          // FILTERS
          this.salesTypes =
            this.user.organisationTypeId == 2
              ? response.data.result.originalSalesTypeSelect
              : response.data.result.salesTypeSelect;
          this.buyerAges = response.data.result.buyerAgeSelect;
          this.buyerLoyalties = response.data.result.buyerLoyaltySelect;
          this.buyerBehaviors = response.data.result.buyerBehaviorSelect;
          this.purchasePeriods = response.data.result.purchasePeriodSelect;
          this.buyerGenders = response.data.result.buyerGenderSelect;
          this.buyerDistances = response.data.result.buyerDistanceSelect;
          this.ticketCounts = response.data.result.ticketSelect;
          this.dayOfPurchases = response.data.result.dayOfPurchaseSelect;

          for (var k = 0; k < response.data.result.compareWithSelect.length; k++) {
            if (response.data.result.compareWithSelect[k].value === 'SameGenre') {
              response.data.result.compareWithSelect.splice(k--, 1);
            }
          }
          this.compareWithOptions = response.data.result.compareWithSelect;

          this.tableReportPerformancesLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    clearFilterWidgets: function () {
      this.filterWidgets.theaterId = '';
      this.filterWidgets.salesType = '';
      this.filterWidgets.originalSalesType = '';
      this.filterWidgets.buyerAge = '';
      this.filterWidgets.buyerLoyalty = '';
      this.filterWidgets.buyerBehavior = '';
      this.filterWidgets.purchasePeriod = '';
      this.filterWidgets.buyerGender = '';
      this.filterWidgets.buyerDistance = '';
      this.filterWidgets.ticketCount = '';
      this.filterWidgets.compareWith = '';
      this.filterWidgets.dayOfPurchase = '';

      this.widgetFilterDialog = false;
    },
    goToPerformance(row) {
      this.$router.push({
        name: 'PublicMonitorPerformancesDetail',
        params: {
          productionNumber: row.production.id,
          performanceId: row.id,
        },
      });
    },

    createExportData,

    printPage() {
      this.$store.commit('storage/toggleDrawerState', false);
      setTimeout(() => {
        window.onbeforeprint = this.beforePrinting();
        window.print();
        window.onafterprint = this.afterPrinting();
      }, 500);
    },
    beforePrinting() {
      document.getElementsByTagName('header')[0].style.display = 'none';
      for (const x of document.querySelectorAll('.hw-print.col-sm-12')) {
        x.className = x.className.replace('col-sm-12', 'col-sm-6');

        var canvas = x.querySelectorAll('canvas')[0];
        if (canvas != null) {
          canvas.style.width = '275px';
          canvas.style.height = '275px';
        }
      }
    },
    afterPrinting() {
      document.getElementsByTagName('header')[0].style.display = 'block';
      for (const x of document.querySelectorAll('.hw-print.col-sm-6')) {
        x.className = x.className.replace('col-sm-6', 'col-sm-12');

        var canvas = x.querySelectorAll('canvas')[0];
        if (canvas != null) {
          canvas.style.width = '275px';
          canvas.style.height = '275px';
        }
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),

    tablePublicMonitorHeadersFiltered() {
      return this.tablePublicMonitorHeaders.filter(
        (h) => !h.showFor || h.showFor.includes(this.user.organisationTypeId)
      );
    },
    newPurchasesPercentage() {
      const value = (this.publicMonitorOccupancyRate.newPurchases / this.publicMonitorOccupancyRate.purchases) * 100;
      return value.toFixed(2);
    },
  },
  watch: {
    filterWidgets: {
      handler: function (after) {
        this.filterWidgets.salesType = after.salesType;
        this.filterWidgets.originalSalesType = after.originalSalesType;
        this.filterWidgets.purchasePeriod = after.purchasePeriod;
        this.filterWidgets.buyerAge = after.buyerAge;
        this.filterWidgets.buyerLoyalty = after.buyerLoyalty;
        this.filterWidgets.buyerBehavior = after.buyerBehavior;
        this.filterWidgets.buyerGender = after.buyerGender;
        this.filterWidgets.buyerDistance = after.buyerDistance;
        this.filterWidgets.ticketCount = after.ticketCount;
        this.filterWidgets.compareWith = after.compareWith;
        this.filterWidgets.dayOfPurchase = after.dayOfPurchase;

        this.getProductionPerformances();
      },
      deep: true,
    },
    filterPeriod: function () {
      this.getProductionPerformances();
    },
  },
};
</script>

<style lang="scss">
.pa-0 {
  break-inside: avoid-page;
}

.page-break {
  display: none;
  height: 50px;
}

@media print {
  @page {
    size: auto; /* auto is the initial value */
    margin: 0mm; /* this affects the margin in the printer settings */
  }

  .v-main {
    padding: 0 !important;
  }

  .v-navigation-drawer {
    transform: translateX(-100%) !important;
  }

  .v-application--wrap {
    display: block;
  }

  .v-sheet {
    display: block;
    page-break-inside: avoid;
  }

  .print-hide {
    display: none;
  }

  @-moz-document url-prefix() {
    .page-break {
      display: block;
    }
  }

  .col-10,
  .col-11 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    padding: 0;
    justify-content: center;
    display: flex;
  }

  header {
    display: none !important;
  }

  .row.no-gutters > .col {
    display: none;
  }

  .row .legend {
    display: none;
  }

  html .header,
  html header {
    display: none;
  }

  .legend h3 {
    display: none;
  }

  .legend {
    display: none !important;
    position: absolute;
    left: 0;
    top: 50px;
  }

  .v-data-table__wrapper {
    height: inherit !important;
    overflow-x: hidden;
  }

  main {
    padding: 0 !important;
  }

  .col-10 > div,
  .col-11 > div {
    width: 260px;
  }

  .map-buttons {
    display: none;
  }

  header {
    display: none !important;
  }

  .row.no-gutters > .col {
    display: none;
  }

  .row.px-3.mb-4.align-center {
    position: absolute;
    top: -20px;
    right: 0;
  }

  button.ml-2.v-btn.v-btn--fab.v-btn--has-bg.v-btn--round.theme--light.v-size--x-small {
    display: none;
  }

  .print-big-chart {
    height: 297mm / 2;
    box-sizing: border-box;
    overflow: hidden;

    .col-3 {
      display: none;
    }

    canvas {
      display: block;
      max-width: 200mm;
      max-height: 150mm;
      transform-origin: left top;
    }
  }

  .v-data-table__wrapper {
    height: inherit;
    overflow-x: hidden;
  }

  .map-buttons {
    display: none;
  }

  main.v-main {
    padding: 0 !important;
  }
}
</style>
