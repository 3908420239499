<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="1136"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <!-- Form -->
                  <v-form
                    @submit.prevent
                    v-model="formValid"
                    class="d-flex flex-column"
                    ref="form"
                  >
                    <h4 class="primary--text mb-5 font-weight-regular">gegevens gebruiker</h4>
                    <v-card
                      class="pa-6 mb-10"
                      flat
                    >
                      <v-row class="mt-0">
                        <!-- naam -->
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-text-field
                            class="primary--text"
                            label="naam"
                            v-model="editThirdPartyForm.name"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>

                    <h4 class="primary--text mb-2 font-weight-regular">api rechten</h4>
                    <v-card
                      class="pa-6 mb-10"
                      flat
                    >
                      <v-row class="mt-0">
                        <!-- data overdracht FPK -->
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-checkbox
                            label="data overdracht FPK"
                            v-model="editThirdPartyForm.hasDataApi"
                            class="checkbox primary--text ma-0"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <!-- productiegegevens FPK -->
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-checkbox
                            label="productiegegevens FPK"
                            v-model="editThirdPartyForm.hasProducerApi"
                            class="checkbox primary--text ma-0"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-card>

                    <p class="grey--text text--lighten-1 mt-0">
                      <small
                        ><i>{{ this.lastEditedBy }}</i></small
                      >
                    </p>

                    <v-alert
                      v-if="formError != ''"
                      text
                      color="error"
                      >{{ formError }}</v-alert
                    >

                    <v-btn
                      rounded
                      large
                      elevation="0"
                      @click="submit"
                      :loading="formLoading"
                      color="secondary"
                      class="text-lowercase mb-5 align-self-start"
                      >Opslaan
                      <v-icon
                        small
                        right
                        >mdi-content-save</v-icon
                      ></v-btn
                    >
                  </v-form>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';

export default {
  name: 'AdminthirdPartiesEdit',
  props: ['thirdPartyId'],
  components: {
    Help,
  },
  data() {
    return {
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      editThirdPartyForm: {
        name: '',
        hasDataApi: false,
        hasProducerApi: false,
      },
      formValidationRules: {},
      lastEditedBy: '',
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'externe partijen',
          disabled: false,
          href: '/admin/externe-partijen',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getThirdParties();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getThirdParties: function () {
      const vm = this;

      ApiService.get('/ThirdPartyUsers/Get', {
        id: this.thirdPartyId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const thirdParty = response.data.result;

            this.editThirdPartyForm = thirdParty;

            this.breadcrumbs[2].text = this.editThirdPartyForm.name;
            this.breadcrumbs[2].href = '/admin/externe-partijen/' + this.thirdPartyId;

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          router.back();
        });
    },
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.editThirdPartyForm;

        ApiService.put('/ThirdPartyUsers/Update', {
          id: this.thirdPartyId,
          name: form.name,
          hasDataApi: form.hasDataApi,
          hasProducerApi: form.hasProducerApi,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();

            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch((error) => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    editThirdPartyForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
