<template>
  <v-app>
    <v-navigation-drawer
      class="px-4"
      v-if="loggedIn"
      v-model="drawer"
      width="350"
      app
      floating
      disable-resize-watcher
    >
      <div class="py-7 d-flex align-center">
        <v-img
          src="/images/dip-logo.svg"
          contain
          height="65"
        ></v-img>
      </div>

      <v-list nav>
        <template v-for="item in navigation">
          <template
            v-if="
              item.children.length > 0 &&
              item.menu &&
              item.children.some(function (e) {
                return e.menu;
              })
            "
          >
            <v-list-group
              :key="item.menu.name"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-icon class="mr-4">
                  <v-icon color="secondary">{{ item.menu.iconName }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.menu.name"
                    class="primary--text"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>

              <template v-if="item.menu.link">
                <v-list-item
                  dense
                  :key="item.menu.name"
                  link
                  :to="item.menu.link"
                >
                  <v-list-item-title
                    v-text="item.menu.name"
                    class="primary--text"
                  ></v-list-item-title>
                </v-list-item>
              </template>
              <template v-for="subItem in item.children">
                <template v-if="subItem.menu">
                  <v-list-item
                    dense
                    :key="subItem.menu.name"
                    link
                    :to="subItem.menu.link"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="subItem.menu.name"
                        class="primary--text"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </template>
            </v-list-group>
          </template>
          <template v-else-if="item.menu">
            <v-list-item
              :key="item.menu.name"
              link
              :to="item.menu.link"
            >
              <v-list-item-icon class="mr-4">
                <v-icon color="secondary">{{ item.menu.iconName }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.menu.name"
                  class="primary--text"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="loggedIn"
      app
      flat
      color="info"
      class="px-3"
      height="90"
    >
      <div class="d-flex align-center">
        <v-btn
          @click="drawer = !drawer"
          fab
          small
          depressed
          color="warning"
          class="not-visible-on-print"
        >
          <v-icon
            color="primary"
            v-text="drawer ? 'mdi-close' : 'mdi-menu'"
          ></v-icon>
        </v-btn>
        <span
          class="pl-2 font-weight-bold primary--text pointer not-visible-on-print"
          @click="drawer = !drawer"
          v-if="drawer"
          >sluit menu</span
        >
      </div>

      <v-spacer></v-spacer>

      <v-list color="info">
        <v-list-item two-line>
          <v-list-item-content class="text-right">
            <v-list-item-title class="font-weight-bold primary--text">{{ user.fullName }}</v-list-item-title>
            <v-list-item-subtitle class="primary--text">
              <template v-if="user.isSuperUser && (!user.loggedInAsUser || user.organisationId == 0)">admin</template>
              <template v-else-if="user.isSuperUser && user.roleName != undefined && user.roleName != ''"
                >Ingelogd als
                <i>{{ user.loggedInAsUserFullName }} ({{ user.roleName }})</i>
                bij {{ user.organisationName }}</template
              >
              <template v-else-if="user.roleName != undefined && user.roleName != ''">
                Ingelogd als <i>{{ user.roleName }}</i> bij {{ user.organisationName }}
              </template>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-menu
        transition="slide-y-transition"
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            fab
            small
            depressed
            color="#fff"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <template v-if="user.isSuperUser && user.loggedInAsUser">
              <v-list-item
                :to="{ path: '/' }"
                @click="
                  switchRole({
                    userId: user.userId,
                    userRoleId: 0,
                    organisationId: 0,
                    organisationTypeId: 0,
                  })
                "
              >
                <v-list-item-icon class="mr-3">
                  <v-icon color="primary">mdi-account-switch-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Uitloggen gebruiker</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-else-if="user.isSuperUser && !user.loggedInAsUser">
              <v-list-item :to="{ path: '/' }">
                <v-list-item-icon class="mr-3">
                  <v-icon color="primary">mdi-account-switch-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Inloggen als gebruiker</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-else-if="user.roles && user.roles.length > 1 && !user.isSuperUser">
              <v-list-group
                no-action
                class="switch-roles"
              >
                <template v-slot:activator>
                  <v-list-item-icon class="mr-3">
                    <v-icon color="primary">mdi-account-switch-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Wissel rol</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  dense
                  v-for="role in user.roles"
                  :key="role.roleId + role.organisationId"
                  @click="
                    switchRole({
                      userId: user.userId,
                      userRoleId: role.roleId,
                      organisationId: role.organisationId,
                      organisationTypeId: role.organisationTypeId,
                    })
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title
                      v-bind:class="[role.active ? 'secondary--text' : '']"
                      v-text="role.roleName + ' bij ' + role.organisationName"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </template>

            <v-list-item
              :to="{ path: '/account' }">
              <v-list-item-icon class="mr-3">
                <v-icon color="primary">mdi-account-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Mijn account</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              href="https://support.dip.nl/support/solutions"
              target="_blank"
            >
              <v-list-item-icon class="mr-3">
                <v-icon color="primary">mdi-help-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Instructies</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              href="https://dip.nl/actualiteiten/meldingen"
              target="_blank"
            >
              <v-list-item-icon class="mr-3">
                <v-icon color="primary">mdi-list-status</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Meldingen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              href="#"
              onclick="FreshWidget.show(); return false;"
            >
              <v-list-item-icon class="mr-3">
                <v-icon color="primary">mdi-face-agent</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Support</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="logout">
              <v-list-item-icon class="mr-3">
                <v-icon color="error">mdi-logout-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Uitloggen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'App',
  data() {
    return {};
  },
  methods: {
    ...mapActions('auth', ['logout', 'switchRole']),
    ...mapActions('storage', ['toggleDrawerState']),
  },
  computed: {
    ...mapState('auth', ['loggedIn', 'navigation', 'user']),

    drawer: {
      get() {
        return this.$store.state.storage.drawer;
      },
      set(value) {
        this.$store.commit('storage/toggleDrawerState', value);
      },
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'Axiforma', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f7fc;
  font-weight: 300;
  font-size: 14px;
}

b,
strong {
  font-weight: bold;
}

// Table
table {
  thead {
    th {
      background-color: var(--v-accent-base);
      color: var(--v-primary-base) !important;
      border-color: var(--v-info-base) !important;
      font-size: 10px !important;
      white-space: nowrap;
      line-height: 2;
    }
  }

  tbody {
    color: var(--v-primary-base);
    td {
      border-color: var(--v-info-base) !important;
      border-bottom-width: 2px !important;
      padding: 5px 16px;
      font-size: 12px !important;
    }
  }
}

.tablehead-no-bg-color table thead th {
  background-color: transparent;
}

.table-p-small table {
  thead th,
  tbody td {
    padding: 5px;
  }
}

.table-first-fixed {
  table {
    tbody {
      tr {
        &:hover {
          background-color: #fff !important;
        }
        td:first-of-type {
          position: sticky;
          left: 0;
          top: 0;
          background: linear-gradient(
            -90deg,
            rgba(255, 255, 255, 0) 0px,
            rgba(255, 255, 255, 1) 25px,
            rgba(255, 255, 255, 1) 100%
          );
        }
      }
    }
    thead {
      tr {
        th:first-of-type {
          position: sticky;
          left: 0;
          top: 0;
          background: linear-gradient(
            -90deg,
            rgba(255, 255, 255, 0) 0px,
            rgba(255, 255, 255, 1) 25px,
            rgba(255, 255, 255, 1) 100%
          );
        }
      }
    }
  }
}

.table-action {
  table {
    tbody {
      tr {
        &:hover {
          background-color: #fff !important;
        }
        td:last-of-type {
          position: sticky;
          right: 0;
          top: 0;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0px,
            rgba(255, 255, 255, 1) 25px,
            rgba(255, 255, 255, 1) 100%
          );
          padding: 5px 16px;
          min-width: 9rem;
        }
      }
    }
  }

  &.row-is-clickable {
    table tbody tr {
      &:not(.v-data-table__empty-wrapper) {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.table-action.table-expand table tbody tr.v-data-table__expanded.v-data-table__expanded__row {
  td:last-of-type {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0px, rgba(2, 29, 113, 1) 25px, rgba(2, 29, 113, 1) 100%);
    .v-icon.v-icon::after {
      opacity: 1;
      background-color: #fff;
      z-index: -1;
    }
  }

  .table-action table tbody tr td:last-of-type {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0px,
      rgba(255, 255, 255, 1) 25px,
      rgba(255, 255, 255, 1) 100%
    );
  }
}

.table-expand table tbody tr.v-data-table__expanded.v-data-table__expanded__row {
  td:last-of-type {
    .v-icon.v-icon::after {
      opacity: 1;
      background-color: #fff;
      z-index: -1;
    }
  }
}

.v-data-table tbody tr {
  &.v-data-table__expanded__content {
    box-shadow: none;
  }

  &.v-data-table__expanded.v-data-table__expanded__row {
    background-color: var(--v-primary-base) !important;
    color: #fff !important;
  }
}

// Breadcrumbs
.v-breadcrumbs li:nth-child(2n) {
  padding: 0 5px;
}

// Pagination
.v-pagination__item,
.v-pagination__navigation {
  -webkit-box-shadow: none;
  box-shadow: none;
}

// Form input
.v-label,
.v-input {
  font-size: 12px;
}

.v-list-item__title,
.v-list-item__subtitle {
  white-space: inherit;
}

.switch-roles .v-list-group__items .v-list-item {
  padding-left: 16px !important;
}

.suffix-border-left-number .v-text-field__suffix {
  border-left: 1px solid rgba(0, 0, 0, 0.38);
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-right: -12px;
}

//block dashboard
.block-head p {
  font-size: 14px;
}

//dividers
.accent-hr {
  background-color: var(--v-accent-base);
  height: 1.5px;
  border: none;
}

.primary-hr {
  background-color: var(--v-primary-base);
  height: 1px;
  border: none;
}

/* Change Autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: none;
  box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

/* Action row styling */
.add-row {
  background: #fff;
  border-radius: 2.3rem;
  box-shadow: 0 3px 1px -2px #e8eff9;
  font-size: 1rem;
  color: #021e73;
  font-weight: 700;
}

.widget-average {
  font-size: 1rem;
  color: #021e73;
  font-weight: 700;

  span {
    font-size: .8rem;
    font-weight: 500;
    display: inline-flex;
  }
}

// fix scrollbar issue in Firefox
.v-data-table {
  position: relative;
}

.input-sm {
  min-width: 10rem;
}

//Progress styling
.progress-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  line-height: 1;
}

/* Total row styling */
.v-application .custom_progress {
  border-radius: 3rem !important;
}

.table-selection-total {
  background: #e1e7f2;
  border-radius: 1.5rem;
}

.table-action .table-selection-total:hover {
  background: #e1e7f2 !important;
}

.v-application .table-selection-total .title {
  font-size: 1rem !important;
  color: #021e73;
}

.table-selection-total .text {
  color: #021e73;
}

.v-application .table-total .title {
  font-size: 1rem !important;
  color: #fff671;
}

.table-total .text {
  color: #fff;
}

.table-total {
  position: relative;
  z-index: 1;
  background: #021e72 !important;
}

.table-action .table-total:hover {
  background: #021e72 !important;
}

.orange {
  background-color: orange;
}

.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background-color: var(--v-accent-base);
  box-shadow: none;
}

.v-data-table--fixed-header {
  .table-selection-total th {
    position: sticky;
    bottom: 3rem;
    z-index: 7;
    background-color: #e1e7f2;
  }

  .table-total th {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 7;
    background: #021e72 !important;
  }
}

.table-total-head table thead th {
  background: #021e72 !important;
  color: #fff !important;
}

.v-application .table-total-head table thead th.title {
  font-size: 1rem !important;
  color: #fff671 !important;
}

.progress-circular {
  margin-top: calc(50vh - 15rem); //calculated from nav
}

.input-radio-row-label legend.v-label {
  width: 100%;
  padding-right: 0;
  margin-bottom: 10px;
}

.tooltip {
  display: none;
  padding: 0.25rem 1.25rem;
  background-color: #fff;
  border-radius: 6px;
  border: solid 1px #e0e5ef;
  box-shadow: 0 5px 10px rgb(171 171 171 / 75%);
  position: absolute;
  top: 0;
  left: calc(100% - 12px);
  transform: translateY(-25%);
  z-index: 99;
  padding: 0.5rem;
  white-space: nowrap;
  transition: all 0.3s ease;

  table {
    border-collapse: collapse;
    border-spacing: 0;

    tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;

      tr {
        line-height: 1.4;
        border-bottom: solid 1px #f0f2f7;
        th {
          font-weight: normal;
          text-align: left;
          width: 100px;
          padding: 0 !important;
        }

        td {
          font-weight: bold;
          text-align: right;
          border: 0;
          background: unset !important;
          min-width: unset !important;
        }
      }
    }
  }
}

.tooltip-container {
  position: relative;
  &:hover {
    .tooltip {
      display: inline-block;
    }
  }
}

.pointer {
  cursor: pointer;
}

.too-few-purchases-fallback {
  background-color: #fff;
  padding: 1rem;
}

//Contracts previous approved styling
.v-application .prev-approved--text {
  color: var(--v-secondary-base);
}

.widget-average h4 {
  text-transform: lowercase;
  font-weight: 400;
}

@media print {
  .v-navigation-drawer {
    width: 0 !important;
    transform: translateX(-100%) !important;
    display: none !important;
  }

  .v-main {
    padding: 90px 0px 0px 0 !important;
  }

  .not-visible-on-print {
    display: none !important;
  }
}
</style>
