<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="flex align-center pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="3"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-col
                cols="12"
                md="6"
                sm="10"
              >
                <!-- Form -->
                <v-form
                  @submit.prevent
                  v-model="formValid"
                  class="d-flex flex-column"
                  ref="form"
                >
                  <h4 class="primary--text mb-2 font-weight-regular">gegevens gebruiker</h4>

                  <v-card
                    class="pa-6 mb-10"
                    flat
                  >
                    <v-row class="mt-0">
                      <!-- Voornaam -->
                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-text-field
                          class="primary--text"
                          label="voornaam"
                          v-model="addUserForm.firstName"
                          :rules="formValidationRules.required"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <!-- Achternaam -->
                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-text-field
                          class="primary--text"
                          label="achternaam"
                          v-model="addUserForm.lastName"
                          :rules="formValidationRules.required"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Email -->
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-text-field
                          class="primary--text"
                          label="e-mailadres"
                          v-model="addUserForm.email"
                          :rules="formValidationRules.emailRequired"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Admin -->
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-checkbox
                          label="admin (medewerker DIP)"
                          v-model="addUserForm.isSuperUser"
                          class="checkbox primary--text ma-0"
                        ></v-checkbox>
                      </v-col>
                    </v-row>

                    <template v-if="!addUserForm.isSuperUser">
                      <v-row>
                        <!-- Type organisatie -->
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-autocomplete
                            label="type organisatie"
                            v-on:change="
                              changeOrganisationType();
                              changeAvailableRoles();
                            "
                            v-model="addUserForm.organisationType"
                            :items="organisationTypeOptions"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row>
                        <!-- Organisatie -->
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-autocomplete
                            label="organisatie"
                            v-model="addUserForm.organisationId"
                            :items="organisationOptions"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row>
                        <!-- Userrol -->
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-select
                            label="gebruikersrol"
                            v-model="addUserForm.roleId"
                            :items="roleOptions"
                            :rules="formValidationRules.required"
                            required
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>
                    </template>
                  </v-card>

                  <v-alert
                    v-if="formError != ''"
                    text
                    color="error"
                    >{{ formError }}</v-alert
                  >

                  <v-btn
                    rounded
                    large
                    elevation="0"
                    @click="submit"
                    :loading="formLoading"
                    color="secondary"
                    class="text-lowercase mb-5 align-self-start"
                    >Opslaan
                    <v-icon
                      small
                      right
                      >mdi-content-save</v-icon
                    ></v-btn
                  >
                </v-form>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';

export default {
  name: 'AdminUsersAdd',
  components: {
    Help,
  },
  data() {
    return {
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      addUserForm: {
        firstName: '',
        lastName: '',
        email: '',
        isSuperUser: false,
        organisationType: '',
        roleId: '',
        organisationId: '',
      },
      userRoleAgencieOptions: [],
      userRoleTheaterOptions: [],
      userRoleProducerOptions: [],
      organisationTypeOptions: [],
      organisationOptions: [],
      theaters: [],
      impresariaten: [],
      producenten: [],
      roleOptions: [],
      formValidationRules: {},
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruikers',
          disabled: false,
          href: '/admin/gebruikers',
          bold: false,
        },
        {
          text: 'toevoegen',
          disabled: false,
          href: '/admin/gebruikers/add',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getOrganisationTypes();
    this.getUserRoles();
    this.getOrganisations();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getUserRoles: function () {
      ApiService.get('/UserRoles/GetAll', {
        organisationTypeId: 1,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.userRoleAgencieOptions.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
      ApiService.get('/UserRoles/GetAll', {
        organisationTypeId: 2,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.userRoleTheaterOptions.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
      ApiService.get('/UserRoles/GetAll', {
        organisationTypeId: 3,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.userRoleProducerOptions.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getOrganisations: function () {
      ApiService.get('/Theaters/GetAllDropDown')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.theaters.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });

      ApiService.get('/Producers/GetAllDropDown')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.producenten.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });

      ApiService.get('/Agencies/GetAllDropDown')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.impresariaten.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        if (this.addUserForm.isSuperUser) {
          this.addUserForm.organisationId = 0;
          this.addUserForm.organisationType = '';
          this.addUserForm.roleId = 0;
          this.addUserForm.userRoleId = 0;
        }

        const form = this.addUserForm;

        ApiService.post('/Users/Add', {
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          api: '',
          isSuperUser: form.isSuperUser,
          userRoleId: form.roleId,
          organisationId: form.organisationId,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();

            if (response.data.success) {
              if (response.data.result.userAlreadyExists) {
                this.formError = 'Deze gebruiker bestaat al.';
                this.formLoading = false;
              } else {
                this.isEditing = false;
                router.back();
              }
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch((error) => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
    getOrganisationTypes: function () {
      ApiService.get('/OrganisationTypes/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.organisationTypeOptions.push({
              value: element.organisationTypeId,
              text: element.organisationTypeName,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    changeOrganisationType: function () {
      this.organisationOptions = [];
      this.addUserForm.organisationId = '';

      if (this.addUserForm.organisationType == 1) {
        this.organisationOptions = this.impresariaten;
      } else if (this.addUserForm.organisationType == 2) {
        this.organisationOptions = this.theaters;
      } else if (this.addUserForm.organisationType == 3) {
        this.organisationOptions = this.producenten;
      }
    },
    changeAvailableRoles: function () {
      this.roleOptions = [];
      this.addUserForm.roleId = '';

      if (this.addUserForm.organisationType == 1) {
        this.roleOptions = this.userRoleAgencieOptions;
      } else if (this.addUserForm.organisationType == 2) {
        this.roleOptions = this.userRoleTheaterOptions;
      } else if (this.addUserForm.organisationType == 3) {
        this.roleOptions = this.userRoleProducerOptions;
      }
    },
  },
  computed: {},
  watch: {
    addUserForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
