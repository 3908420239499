<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
      :loading="openContractsLoading"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="primary--text font-weight-medium mb-0">Openstaande financiële afspraken</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>

        <v-row
          flex
          justify="center"
          class="py-2 flex-grow-1"
        >
          <template v-if="chart.loaded">
            <v-col
              cols="12"
              class="pa-0"
            >
              <v-list
                flat
                v-if="contracts.length"
              >
                <v-list-item
                  v-for="(item, i) in contracts"
                  :key="i"
                  link
                  :to="item.link"
                  dense
                >
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.productionTitle"
                      class="primary--text"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="'laatste wijziging:' + ' ' + item.startDate_formatted"
                      class="my-1"
                    >
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="align-self-start">
                    <v-list-item-subtitle
                      class="counterparty pl-5"
                      v-text="item.counterparty"
                    >
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-chip
                      v-if="item.state == 'goedgekeurd'"
                      color="success"
                      small
                      label
                      ><strong>{{ item.state }}</strong></v-chip
                    >
                    <v-chip
                      v-else-if="item.state == 'voorstel'"
                      color="accent"
                      class="primary--text"
                      small
                      label
                      ><strong>{{ item.state }}</strong></v-chip
                    >
                    <v-chip
                      v-else-if="item.state == 'concept'"
                      class="primary--text"
                      small
                      label
                      ><strong>{{ item.state }}</strong></v-chip
                    >
                    <v-chip
                      v-else-if="item.state == 'aangepast voorstel'"
                      color="warning"
                      class="primary--text"
                      small
                      label
                      ><strong>{{ item.state }}</strong></v-chip
                    >
                    <v-chip
                      v-else-if="item.state == 'herzien'"
                      color="warning"
                      class="primary--text"
                      small
                      label
                      ><strong>{{ item.state }}</strong></v-chip
                    >
                    <v-chip
                      v-else-if="item.state == 'afgekeurd'"
                      color="error"
                      small
                      label
                      ><strong>{{ item.state }}</strong></v-chip
                    >

                    <v-icon
                      color="primary"
                      class="ml-2"
                      >mdi-chevron-right</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
              </v-list>

              <v-list v-else>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      v-text="'er zijn geen openstaande contracten'"
                      class="primary--text"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <div class="col col-12 pb-0">
                <v-btn
                  rounded
                  small
                  elevation="0"
                  :to="{ name: 'Contracts' }"
                  color="primary"
                  class="text-lowercase mb-2"
                  >alle afspraken</v-btn
                >
              </div>
            </v-col>
          </template>

          <template v-else>
            <p class="primary--text font-weight-medium mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import functions from '../../services/functions.service';

import Help from '../Help.vue';

export default {
  props: ['helpId', 'colsMobile', 'cols-Tablet', 'colsDesktop'],
  components: {
    Help,
  },
  data() {
    return {
      chart: {
        loaded: false,
      },
      contracts: [],
      openContractsLoading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const vm = this;
      this.chart.loaded = false;

      ApiService.get('/Dashboard/GetOpenContracts').then((response) => {
        TokenService.saveToken(response.data.token);
        ApiService.setHeader();

        this.contracts = response.data.result;

        for (var i = 0; i < this.contracts.length; i++) {
          vm.$set(
            this.contracts[i],
            'link',
            '/financiele-afspraken/' + this.contracts[i].type + '/' + this.contracts[i].id
          );
          vm.$set(this.contracts[i], 'state', functions.getStatusNameNL(this.contracts[i].state));
          vm.$set(this.contracts[i], 'startDate_formatted', functions.getDateTimeView(this.contracts[i].modifiedDate));
        }

        // Load chart
        this.chart.loaded = true;
        this.openContractsLoading = false;
      });
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
};
</script>

<style scoped>
.v-list-item__subtitle.my-1 {
  font-size: 13px;
  font-weight: 300;
}

.counterparty {
  font-size: 0.75rem;
  color: rgb(2, 30, 114) !important;
}
</style>
