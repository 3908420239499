import { Doughnut } from 'vue-chartjs/legacy';

export default {
  extends: Doughnut,
  props: ['options'],
  mounted() {
    //this.setTextCenter(this.options.textCenter)
    // this.addPlugin({
    //   id: 'text-center',
    //   beforeDraw: this.addTextCenter
    // })
    // this.addPlugin(ChartDataLabels)
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
  },
  methods: {
    addTextCenter(chart) {
      if (this.options.textCenter != null) {
        var width = chart.chart.width;
        var height = chart.chart.height;
        var ctx = chart.chart.ctx;

        ctx.restore();
        var fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + 'em sans-serif';
        ctx.textBaseline = 'middle';

        var text = this.options.textCenter;
        var textX = Math.round((width - ctx.measureText(text).width) / 2);
        var textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    },
  },
};
