//import ApiService from "../services/api.service"

const state = {
  drawer: true,
  filters: [],
  storedFilterPeriod: null,
  storedSalesMonitorProductions: null,
  storedSalesMonitorPerformances: null,
  storedSalesMonitorProductionsFilterPeriod: null,
  storedSalesMonitorPerformancesFilterPeriod: null,
};

const getters = {
  getFilter: (state) => (viewName) => {
    var filter = state.filters.find((item) => item.view === viewName);
    return filter.filter;
  },
  storedFilterPeriod: (state) => {
    return state.storedFilterPeriod;
  },
};

const mutations = {
  toggleDrawerState(state, value) {
    state.drawer = value;
  },
  setFilter(state, filter) {
    state.filters.push({
      view: filter.viewName,
      filter: filter.filterObject,
    });
  },
  updateFilter(state, filter) {
    const index = state.filters.findIndex((el) => el.view === filter.viewName);
    state.filters[index] = {
      view: filter.viewName,
      filter: filter.filterObject,
    };
  },
  clearStoredSalesMonitor(state) {
    state.storedSalesMonitorProductions = null;
    state.storedSalesMonitorPerformances = null;
    state.storedSalesMonitorProductionsFilterPeriod = null;
    state.storedSalesMonitorPerformancesFilterPeriod = null;
  },
  setStoredFilterPeriod(state, filterPeriod) {
    state.storedFilterPeriod = filterPeriod;
  },
  clearStoredFilterPeriod() {
    state.storedFilterPeriod = null;
  },
  // SalesMonitor
  getStoredSalesMonitorProductions(state, productions) {
    state.storedSalesMonitorProductions = productions;
  },
  getStoredSalesMonitorPerformances(state, performances) {
    state.storedSalesMonitorPerformances = performances;
  },
  getStoredSalesMonitorProductionsFilterPeriod(state, period) {
    state.storedSalesMonitorProductionsFilterPeriod = period;
  },
  getStoredSalesMonitorPerformancesFilterPeriod(state, period) {
    state.storedSalesMonitorPerformancesFilterPeriod = period;
  },
};

const actions = {
  checkFilter(context, filter) {
    var filters = context.state.filters;
    var filterExist = filters.find((item) => item.view === filter.viewName);
    if (!filterExist) {
      context.commit('setFilter', filter);
    }
  },
  updateFilter(context, filter) {
    var filterExist = context.state.filters.find((item) => item.view === filter.viewName);
    if (!filterExist) {
      context.commit('setFilter', filter);
    } else {
      context.commit('updateFilter', filter);
    }
  },
};

const storage = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default storage;
