<template>
  <v-layout
    class="d-flex flex-column align-center justify-center"
    style="min-height: 100vh"
  >
    <v-flex class="d-flex flex-column align-center justify-center pa-5">
      <div>
        <v-img
          src="/images/dip-logo.svg"
          contain
          aspect-ratio="1.6"
          width="115"
          max-width="100%"
          class="ma-5"
        ></v-img>
      </div>
      <v-card
        class="d-flex"
        flat
      >
        <v-flex
          md6
          d-md-flex
          d-none
        >
          <v-img
            src="/images/img-login.png"
            position="right bottom"
            height="100%"
          ></v-img>
        </v-flex>
        <v-flex
          md6
          sm12
        >
          <v-container
            pa-6
            pt-8
            class="d-flex flex-column align-center"
          >
            <h1 class="text-center primary--text mb-2">Inloggen</h1>
            <v-col
              cols="12"
              class="pa-0 mb-2 text-right"
            >
              <v-btn
                class="ml-2"
                width="24"
                height="24"
                fab
                x-small
                depressed
                light
                @click="dialogHelp = true"
              >
                <v-icon
                  color="primary"
                  x-small
                  >mdi-help</v-icon
                >
              </v-btn>

              <v-dialog
                v-model="dialogHelp"
                max-width="700px"
              >
                <v-card>
                  <v-btn
                    color="#fff"
                    class="mt-8"
                    top
                    right
                    absolute
                    fab
                    x-small
                    depressed
                    light
                    @click="dialogHelp = false"
                  >
                    <v-icon color="primary">mdi-close</v-icon>
                  </v-btn>
                  <v-card-title class="primary--text">Inloggen</v-card-title>
                  <v-card-text class="primary--text caption pre-wrap">
                    <p>
                      Welkom bij DIP, via de DIP-applicatie kan je de verkoopoverzichten van jouw organisatie bekijken
                      in het verkoopmutaties overzicht. Momenteel zijn we bezig met een gefaseerde oplevering, dit
                      betekent dat het invoeren van producties en voorstellingen en het aanmaken van financiële
                      afspraken nu nog gedaan moet worden via
                      <a
                        href="https://applicatie.podiumkunst.info/login"
                        target="_blank"
                        >Podiumkunst.info</a
                      >.
                    </p>
                    <p>
                      Inloggen doe je door je gebruikersnaam en wachtwoord in te vullen en vervolgens op ‘inloggen’ te
                      klikken. Ben je je wachtwoord vergeten dan kan je deze opnieuw aanmaken door te klikken op
                      ‘wachtwoord vergeten?’ <br />Lukt het inloggen niet, neem dan een kijkje op:
                      <a
                        href="http://support.podiumkunst.info/support/solutions/articles/13000036073"
                        target="_blank"
                        >http://support.podiumkunst.info/support/solutions/articles/13000036073</a
                      >
                    </p>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
            <v-form
              @submit.prevent
              v-model="formValid"
              class="d-flex flex-column"
              ref="form"
            >
              <v-text-field
                label="e-mailadres"
                validate-on-blur
                v-model="email"
                :rules="formValidationRules.emailRequired"
                required
                outlined
                append-icon="mdi-email-outline"
              ></v-text-field>
              <v-text-field
                label="Wachtwoord"
                validate-on-blur
                v-model="password"
                min="8"
                password
                :rules="formValidationRules.required"
                type="password"
                required
                outlined
                append-icon="mdi-lock"
              ></v-text-field>

              <v-alert
                text
                type="error"
                v-if="authenticationError"
                >{{ authenticationError }}</v-alert
              >

              <v-flex
                flex
                align-center
                justify-end
                text-right
                mb-2
              >
                <router-link :to="{ name: 'forgotPassword' }">Wachtwoord vergeten?</router-link>
              </v-flex>

              <v-btn
                rounded
                block
                x-large
                elevation="0"
                @click="submit"
                :loading="authenticating"
                color="secondary"
                class="text-lowercase font-weight-bold mb-5"
                >inloggen</v-btn
              >

              <v-alert
                text
                color="primary"
                class="mt-2 mb-0"
              >
                <h4 class="font-weight-bold mb-1">Nog geen account?</h4>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfi8znPDPdIRhBssoy0kJOGbaDXj7UAlmjwfuSX5RRmcOHn3Q/viewform"
                  target="_blank"
                  >Meld je nu aan via het registratieformulier.</a
                >
                <br />
                Kijk op
                <a
                  href="https://www.dip.nl"
                  target="_blank"
                  >www.dip.nl</a
                >
                voor meer informatie en tarieven.
              </v-alert>
            </v-form>
            <v-row class="pt-6 d-flex nowrap">
              <v-col cols="12">
                <HelpButtons />
              </v-col>
            </v-row>
          </v-container>
        </v-flex>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import functions from '../../services/functions.service';
import HelpButtons from '@/components/HelpButtons';

export default {
  name: 'login',
  components: { HelpButtons },
  data() {
    return {
      formValid: false,
      formValidationRules: {},
      email: '',
      password: '',
      dialogHelp: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['authenticating', 'authenticationError', 'authenticationErrorCode']),
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    let self = this;
    window.addEventListener('keyup', function (event) {
      if (event.code === 'Enter') {
        self.submit();
      }
    });
  },
  methods: {
    ...mapActions('auth', ['login']),
    submit() {
      if (this.$refs.form.validate()) {
        this.login({ email: this.email, password: this.password });
      }
    },
  },
};
</script>

<style scoped></style>
