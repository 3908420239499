<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
      :loading="areaOccupationLoading"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="primary--text font-weight-medium mb-0">zaalbezetting</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>

        <v-row
          flex
          align="center"
          justify="center"
          class="py-2 flex-column flex-grow-1"
        >
          <template v-if="chart.loaded">
            <v-col cols="12">
              <bar-chart
                v-if="chart.loaded"
                :chart-data="chart.data"
                :chart-options="chart.options"
              ></bar-chart>
            </v-col>
          </template>

          <template v-else>
            <p class="primary--text font-weight-medium mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import Help from '../Help.vue';
import BarChart from '../../plugins/chartjs/BarChartDatalabels.js';

export default {
  props: ['helpId', 'colsMobile', 'cols-Tablet', 'colsDesktop', 'filterPeriod'],
  components: {
    Help,
    BarChart,
  },
  data() {
    return {
      chart: {
        loaded: false,
        data: {},
        options: {},
      },
      chartData: [],
      areaOccupationLoading: true,
    };
  },
  methods: {
    getData() {
      this.chart.loaded = false;

      ApiService.get('/Dashboard/GetOccupancyRatePerGenre', {
        StartDate: this.filterPeriod.from,
        EndDate: this.filterPeriod.to,
      }).then((response) => {
        TokenService.saveToken(response.data.token);
        ApiService.setHeader();

        this.chartData = response.data.result;

        var labels = this.chartData.map(function (e) {
          return e.genreName;
        });
        var dataGenrelocalOccupancyRate = this.chartData.map(function (e) {
          return e.localOccupancyRate;
        });
        var dataGenrenationalOccupancyRate = this.chartData.map(function (e) {
          return e.nationalOccupancyRate;
        });

        // Set chart options
        this.chart.options = {
          onHover: (e, chartEl) => {
            e.native.target.style.cursor = chartEl[0] ? 'pointer' : 'default';
          },
          onClick: (e, activeEls, chart) => {
            // Navigate to sales monitor with selected genres
            const res = chart.getElementsAtEventForMode(e.native, 'nearest', { intersect: true }, true);

            if (res.length === 0) return;

            const selectedBarLabel = chart.data.labels[res[0].index];

            this.$router.push({
              name: 'SalesMonitorProductions',
              query: { genre: selectedBarLabel },
            });
          },
          responsive: true,
          maintainAspectRatio: this.user.organisationTypeId != 3 ? true : false,
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 20,
              bottom: 0,
            },
          },
          indexAxis: 'y',
          scales: {
            yAxes: [
              {
                barThickness: 50,
                barPercentage: 1,
              },
            ],
            xAxes: [
              {
                barThickness: 0.4,
              },
            ],
          },
          plugins: {
            datalabels: {
              align: 'end',
              anchor: 'end',
              color: '#021e73',
              formatter: function (value) {
                if (value > 5) {
                  return value;
                } else {
                  return '';
                }
              },
            },
            legend: {
              display: true,
              position: 'top',
            },
            tooltip: {
              enabled: true,
              intersect: true,
              mode: 'index',
              position: 'nearest',
              callbacks: {
                label: function (tooltipItem) {
                  var label = tooltipItem.dataset.label || '';

                  if (label) {
                    label += ': ';
                  }
                  label += tooltipItem.formattedValue + '%';
                  return label;
                },
              },
            },
          },
        };

        // Set chart data
        this.chart.data = {
          labels: labels,
          datasets: [
            {
              label: 'eigen',
              backgroundColor: '#021e73',
              data: dataGenrelocalOccupancyRate,
            },
            {
              label: 'landelijk',
              backgroundColor: '#e8eff9',
              data: dataGenrenationalOccupancyRate,
            },
          ],
        };

        // Load chart
        if (this.chartData.length > 0) {
          this.chart.loaded = true;
        }
        this.areaOccupationLoading = false;
      });
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    filterPeriod: function () {
      this.getData();
    },
  },
};
</script>

<style scoped></style>
