<template>
  <v-layout
    class="d-flex flex-column align-center justify-center"
    style="min-height: 100vh"
  >
    <v-flex class="d-flex flex-column align-center justify-center pa-5 col col-12">
      <div>
        <v-img
          src="/images/dip-logo.svg"
          contain
          aspect-ratio="1.6"
          width="115"
          max-width="100%"
          class="ma-5"
        ></v-img>
      </div>
      <v-card
        class="d-flex col col-12 col-md-6"
        flat
      >
        <v-flex>
          <v-container
            pa-6
            pt-12
            class="d-flex flex-column align-center"
          >
            <h1 class="text-center primary--text mb-5">Wachtwoord vergeten</h1>
            <v-form
              @submit.prevent
              v-model="formValid"
              class="col col-12"
              ref="form"
            >
              <v-text-field
                label="E-mailadres"
                v-model="email"
                :rules="formValidationRules.required"
                required
                outlined
              ></v-text-field>

              <v-btn
                rounded
                x-large
                elevation="0"
                @click="submit"
                :loading="formLoading"
                color="secondary"
                class="text-lowercase font-weight-bold mb-5 mr-5"
                >verstuur</v-btn
              >

              <v-btn
                rounded
                x-large
                outlined
                elevation="0"
                color="primary"
                class="text-lowercase font-weight-bold mb-5"
                :to="{ name: 'login' }"
                >terug</v-btn
              >
            </v-form>
          </v-container>
        </v-flex>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ApiService from '../../services/api.service';
import router from '../../router/router.js';
import functions from '../../services/functions.service';

export default {
  name: 'forgotPassword',
  data() {
    return {
      formValid: false,
      formLoading: false,
      email: '',
      formValidationRules: {},
    };
  },
  computed: {},
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
  },
  methods: {
    submit() {
      this.formLoading = true;
      if (this.formValid) {
        const requestData = {
          method: 'post',
          url: '/Account/ForgotPassword',
          data: {
            emailAddress: this.email,
          },
        };

        ApiService.customRequest(requestData)
          .then((response) => {
            if (response.data.success) {
              router.push('/login');
            } else {
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch((error) => {
            this.formError = error;
            // eslint-disable-next-line
            console.log('error ' + error);
            this.errored = true;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
};
</script>

<style scoped></style>
