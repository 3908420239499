<template>
  <div>
    <!-- Deployment Technicians -->
    <v-card
      v-if="contract.theaterTechnicians != null || contract.producerTechnicians != null"
      class="py-6 px-3 pb-0 mb-4"
      flat
    >
      <v-container>
        <v-row v-if="contract.theaterTechnicians != null">
          <detail-part label="aantal technici theater" :contract="contract"
                       :getter="c => c.theaterTechnicians" colClass="mb-8" />
        </v-row>

        <v-row v-if="contract.producerTechnicians != null">
          <detail-part label="aantal technici producent" :contract="contract"
                       :getter="c => c.producerTechnicians" colClass="mb-8" />
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DetailPart from "@/views/Contracts/components/details/DetailPart.vue";

export default {
  components: {DetailPart},
  props: ['contract'],
  data() {
    return {};
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {},
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #e1e7f2;
}
</style>