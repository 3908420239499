// INTERN VIEWS
import PageNotFound from '../views/PageNotFound';
import AcceptTerms from '../views/Auth/AcceptTerms';

// DASHBOARD
import Dashboard from '../views/Dashboard/Index';

///////////////////
//Sales monitor
///////////////////

import SalesMonitorProductions from '../views/SalesMonitor/Productions';
import SalesMonitorProductionsDetail from '../views/SalesMonitor/ProductionsDetail';
import SalesMonitorPerformances from '../views/SalesMonitor/Performances';
import SalesMonitorPerformancesDetail from '../views/SalesMonitor/PerformancesDetail';

///////////////////
//Sales mutations
///////////////////

import SalesMutationsSummary from '../views/SalesMutations/Summary';
import SalesMutationsProductions from '../views/SalesMutations/Productions';
import SalesMutationsPerformances from '../views/SalesMutations/Performances';
import SalesMutationsProductions10weeks from '../views/SalesMutations/SalesMutationsProductions10weeks';
import SalesMutationsProductions7days from '../views/SalesMutations/SalesMutationsProductions7days';
import SalesMutationsPerformances10weeks from '../views/SalesMutations/SalesMutationsPerformances10weeks';
import SalesMutationsPerformances7days from '../views/SalesMutations/SalesMutationsPerformances7days';

///////////////////
//Public Monitor
///////////////////

import PublicMonitorProductions from '../views/PublicMonitor/Productions';
import PublicMonitorReports from '../views/PublicMonitor/Reports';
import PublicMonitorReportsEdit from '../views/PublicMonitor/ReportsEdit';
import PublicMonitorReportsAdd from '../views/PublicMonitor/ReportsAdd';
import PublicMonitorReportsDetail from '../views/PublicMonitor/ReportsDetail';
import PublicMonitorProductionsDetail from '../views/PublicMonitor/ProductionsDetail';
import PublicMonitorPerformancesDetail from '../views/PublicMonitor/PerformancesDetail';
import PublicMonitorProductionsOverlapAnalysis from '../views/PublicMonitor/OverlapAnalysis';

///////////////////
//Benchmark
///////////////////

import BenchmarkProductions from '../views/Benchmark/Productions';
import BenchmarkProductionsDetail from '../views/Benchmark/ProductionsDetail';
import BenchmarkProductionsBenchmark from '../views/Benchmark/ProductionsBenchmark';

///////////////////
//Contracts
///////////////////

import Contracts from '../views/Contracts/Index';
import ContractsAdd from '../views/Contracts/Add';
import ContractsEdit from '../views/Contracts/Edit';
import ContractsDetail from '../views/Contracts/Detail';

///////////////////
//Library
///////////////////

// CONTACTS
import LibraryUsers from '../views/Library/Users/Index';
import LibraryUsersAdd from '../views/Library/Users/Add';
import LibraryUsersEdit from '../views/Library/Users/Edit';
import LibraryUsersDetail from '../views/Library/Users/Detail';

// AREAS
import LibraryAreas from '../views/Library/Areas/Index';
import LibraryAreasDetail from '../views/Library/Areas/Detail';
import LibraryAreasSurchargeAdd from '../views/Library/Areas/SurchargeAdd';
import LibraryAreasSurchargeEdit from '../views/Library/Areas/SurchargeEdit';

// PRODUCTIONS
import LibraryProductions from '../views/Library/Productions/Index';
import LibraryProductionsAdd from '../views/Library/Productions/Add';
import LibraryProductionsEdit from '../views/Library/Productions/Edit';
import LibraryProductionsDetail from '../views/Library/Productions/Detail';
import LibraryProductionsPerformanceAdd from '../views/Library/Productions/PerformanceAdd';
import LibraryProductionsPerformanceEdit from '../views/Library/Productions/PerformanceEdit';
import LibraryProductionsPerformanceAddBulk from '../views/Library/Productions/PerformanceAddBulk';
import LibraryProductionsPerformanceCapacity from '../views/Library/Productions/PerformanceCapacity';

// TICKET SYNC
import LibraryTicketSync from '../views/Library/TicketSync/Index';

// NOTIFICATIONS
import LibraryNotifications from '../views/Library/Notifications/Index';
import LibraryNotificationsDetail from '../views/Library/Notifications/Detail';

// AREA CAPACITIES
import LibraryAreaCapacities from '../views/Library/AreaCapacities/Index';
import LibraryAreaCapacitiesAdd from '../views/Library/AreaCapacities/Add';
import LibraryAreaCapacitiesEdit from '../views/Library/AreaCapacities/Edit';
import LibraryAreaCapacitiesDetail from '../views/Library/AreaCapacities/Detail';

///////////////////
// ADMIN
///////////////////

// LOGIN
import AdminLogin from '../views/Admin/Login/Index';

// USER
import AdminUsers from '../views/Admin/Users/Index';
import AdminUsersAdd from '../views/Admin/Users/Add';
import AdminUsersEdit from '../views/Admin/Users/Edit';
import AdminUsersDetail from '../views/Admin/Users/Detail';
import AdminUsersLinkAdd from '../views/Admin/Users/LinkAdd';
import AdminUsersLinkEdit from '../views/Admin/Users/LinkEdit';

// GENRES
import AdminGenres from '../views/Admin/Genres/Index';

// THEATER
import AdminTheaters from '../views/Admin/Theaters/Index';
import AdminTheatersAdd from '../views/Admin/Theaters/Add';
import AdminTheatersEdit from '../views/Admin/Theaters/Edit';
import AdminTheatersDetail from '../views/Admin/Theaters/Detail';
import AdminTheatersAreaAdd from '../views/Admin/Theaters/AreaAdd';
import AdminTheatersAreaEdit from '../views/Admin/Theaters/AreaEdit';
import AdminTheatersLinkAdd from '../views/Admin/Theaters/LinkAdd';
import AdminTheatersLinkEdit from '../views/Admin/Theaters/LinkEdit';

// PROCUCERS
import AdminProducers from '../views/Admin/Producers/Index';
import AdminProducersAdd from '../views/Admin/Producers/Add';
import AdminProducersEdit from '../views/Admin/Producers/Edit';
import AdminProducersDetail from '../views/Admin/Producers/Detail';
import AdminProducersLinkAdd from '../views/Admin/Producers/LinkAdd';
import AdminProducersLinkEdit from '../views/Admin/Producers/LinkEdit';
import AdminProducersAgencyLinkAdd from '../views/Admin/Producers/LinkAgencyAdd';
import AdminProducersProductions from '../views/Admin/Producers/Productions';
import AdminProducersProductionsAgencyLinkEdit from '../views/Admin/Producers/ProductionsLinkAgencyEdit';
import AdminProducersSubventionAdd from '../views/Admin/Producers/SubventionAdd';
import AdminProducersSubventionEdit from '../views/Admin/Producers/SubventionEdit';

// AGENCIES
import AdminAgencies from '../views/Admin/Agencies/Index';
import AdminAgenciesAdd from '../views/Admin/Agencies/Add';
import AdminAgenciesEdit from '../views/Admin/Agencies/Edit';
import AdminAgenciesDetail from '../views/Admin/Agencies/Detail';
import AdminAgenciesLinkAdd from '../views/Admin/Agencies/LinkAdd';
import AdminAgenciesLinkEdit from '../views/Admin/Agencies/LinkEdit';
import AdminAgenciesProducerLinkAdd from '../views/Admin/Agencies/LinkProducerAdd';

// THIRD PARTIES
import AdminThirdParties from '../views/Admin/ThirdParties/Index';
import AdminThirdPartiesAdd from '../views/Admin/ThirdParties/Add';
import AdminThirdPartiesEdit from '../views/Admin/ThirdParties/Edit';
import AdminThirdPartiesDetail from '../views/Admin/ThirdParties/Detail';

// CHARACTERISTICS
import AdminCharacteristics from '../views/Admin/Characteristics/Index';
import AdminCharacteristicsAdd from '../views/Admin/Characteristics/Add';
import AdminCharacteristicsEdit from '../views/Admin/Characteristics/Edit';
import AdminCharacteristicsDetail from '../views/Admin/Characteristics/Detail';

// SEASONS
import AdminSeasons from '../views/Admin/Seasons/Index';

// PERFORMANCES
import AdminPerformances from '../views/Admin/Performances/Index';

// CREATIVE CAST
import AdminCreativeCast from '../views/Admin/CreativeCast/Index';
import AdminCreativeCastAdd from '../views/Admin/CreativeCast/Add';
import AdminCreativeCastEdit from '../views/Admin/CreativeCast/Edit';
import AdminCreativeCastDetail from '../views/Admin/CreativeCast/Detail';

// NOTIFICATIONS
import AdminNotifications from '../views/Admin/Notifications/Index';
import AdminNotificationsAdd from '../views/Admin/Notifications/Add';
import AdminNotificationsEdit from '../views/Admin/Notifications/Edit';
import AdminNotificationsDetail from '../views/Admin/Notifications/Detail';

// RIGHTS
import AdminRights from '../views/Admin/Rights/Index';
import AdminRightsEdit from '../views/Admin/Rights/Edit';

///////////////////
// MONITOR
///////////////////

// SYNCHRONIZATION
import MonitorSynchronization from '../views/Monitor/Synchronization/Index';

// TICKET SYNCHRONIZATION
import MonitorTicketSynchronization from '../views/Monitor/TicketSynchronization/Index';

// CONTACT PERSONS SUBSIDIZED PRODUCERS
import MonitorContactPersonsSubsidizedProducers from '../views/Monitor/ContactPersonsSubsidizedProducers/Index';

// DUPLICATE PERFORMANCES
import MonitorDuplicatePerformances from '../views/Monitor/DuplicatePerformances/Index';

const views = [
  {
    name: 'PageNotFound',
    component: PageNotFound,
  },
  {
    name: 'AcceptTerms',
    component: AcceptTerms,
  },
  //  LOGIN
  {
    name: 'AdminLogin',
    component: AdminLogin,
  },
  //////////////
  // SALES MONITOR
  //////////////
  {
    name: 'SalesMonitorProductions',
    component: SalesMonitorProductions,
  },
  {
    name: 'SalesMonitorProductionsDetail',
    component: SalesMonitorProductionsDetail,
  },
  {
    name: 'SalesMonitorPerformances',
    component: SalesMonitorPerformances,
  },
  {
    name: 'SalesMonitorPerformancesDetail',
    component: SalesMonitorPerformancesDetail,
  },
  //////////////
  // SALES MUTATIONS
  //////////////
  {
    name: 'SalesMutationsSummary',
    component: SalesMutationsSummary,
  },
  {
    name: 'SalesMutationsProductions',
    component: SalesMutationsProductions,
  },
  {
    name: 'SalesMutationsPerformances',
    component: SalesMutationsPerformances,
  },
  {
    name: 'SalesMutationsProductions10weeks',
    component: SalesMutationsProductions10weeks,
  },
  {
    name: 'SalesMutationsProductions7days',
    component: SalesMutationsProductions7days,
  },
  {
    name: 'SalesMutationsPerformances10weeks',
    component: SalesMutationsPerformances10weeks,
  },
  {
    name: 'SalesMutationsPerformances7days',
    component: SalesMutationsPerformances7days,
  },
  //////////////
  // PUBLIC MONITOR
  //////////////
  {
    name: 'PublicMonitorProductions',
    component: PublicMonitorProductions,
  },
  {
    name: 'PublicMonitorReports',
    component: PublicMonitorReports,
  },
  {
    name: 'PublicMonitorReportsAdd',
    component: PublicMonitorReportsAdd,
  },
  {
    name: 'PublicMonitorReportsEdit',
    component: PublicMonitorReportsEdit,
  },
  {
    name: 'PublicMonitorReportsDetail',
    component: PublicMonitorReportsDetail,
  },
  {
    name: 'PublicMonitorProductionsDetail',
    component: PublicMonitorProductionsDetail,
  },
  {
    name: 'PublicMonitorPerformancesDetail',
    component: PublicMonitorPerformancesDetail,
  },
  {
    name: 'PublicMonitorProductionsOverlapAnalysis',
    component: PublicMonitorProductionsOverlapAnalysis,
  },
  //////////////
  // Benchmark
  //////////////
  {
    name: 'BenchmarkProductions',
    component: BenchmarkProductions,
  },
  {
    name: 'BenchmarkProductionsDetail',
    component: BenchmarkProductionsDetail,
  },
  {
    name: 'BenchmarkProductionsBenchmark',
    component: BenchmarkProductionsBenchmark,
  },
  //////////////
  // CONTRACTS
  //////////////
  {
    name: 'Contracts',
    component: Contracts,
  },
  {
    name: 'ContractsAdd',
    component: ContractsAdd,
  },
  {
    name: 'ContractsEdit',
    component: ContractsEdit,
  },
  {
    name: 'ContractsDetail',
    component: ContractsDetail,
  },
  //////////////
  // LIBRARY
  //////////////

  // DASHBOARD
  {
    name: 'Dashboard',
    component: Dashboard,
  },
  // USERS
  {
    name: 'LibraryUsers',
    component: LibraryUsers,
  },
  {
    name: 'LibraryUsersAdd',
    component: LibraryUsersAdd,
  },
  {
    name: 'LibraryUsersEdit',
    component: LibraryUsersEdit,
  },
  {
    name: 'LibraryUsersDetail',
    component: LibraryUsersDetail,
  },
  // AREAS
  {
    name: 'LibraryAreas',
    component: LibraryAreas,
  },
  {
    name: 'LibraryAreasDetail',
    component: LibraryAreasDetail,
  },
  {
    name: 'LibraryAreasSurchargeAdd',
    component: LibraryAreasSurchargeAdd,
  },
  {
    name: 'LibraryAreasSurchargeEdit',
    component: LibraryAreasSurchargeEdit,
  },
  // PRODUCTIONS
  {
    name: 'LibraryProductions',
    component: LibraryProductions,
  },
  {
    name: 'LibraryProductionsAdd',
    component: LibraryProductionsAdd,
  },
  {
    name: 'LibraryProductionsEdit',
    component: LibraryProductionsEdit,
  },
  {
    name: 'LibraryProductionsDetail',
    component: LibraryProductionsDetail,
  },
  {
    name: 'LibraryProductionsPerformanceAdd',
    component: LibraryProductionsPerformanceAdd,
  },
  {
    name: 'LibraryProductionsPerformanceEdit',
    component: LibraryProductionsPerformanceEdit,
  },
  {
    name: 'LibraryProductionsPerformanceAddBulk',
    component: LibraryProductionsPerformanceAddBulk,
  },
  {
    name: 'LibraryProductionsPerformanceCapacity',
    component: LibraryProductionsPerformanceCapacity,
  },
  // TICKET SYNC
  {
    name: 'LibraryTicketSync',
    component: LibraryTicketSync,
  },
  // NOTIFICATIONS
  {
    name: 'LibraryNotifications',
    component: LibraryNotifications,
  },
  {
    name: 'LibraryNotificationsDetail',
    component: LibraryNotificationsDetail,
  },
  // PRICE TEMPLATES
  {
    name: 'LibraryAreaCapacities',
    component: LibraryAreaCapacities,
  },
  {
    name: 'LibraryAreaCapacitiesAdd',
    component: LibraryAreaCapacitiesAdd,
  },
  {
    name: 'LibraryAreaCapacitiesEdit',
    component: LibraryAreaCapacitiesEdit,
  },
  {
    name: 'LibraryAreaCapacitiesDetail',
    component: LibraryAreaCapacitiesDetail,
  },
  //////////////
  // ADMIN
  //////////////

  // USERS
  {
    name: 'AdminUsers',
    component: AdminUsers,
  },
  {
    name: 'AdminUsersAdd',
    component: AdminUsersAdd,
  },
  {
    name: 'AdminUsersEdit',
    component: AdminUsersEdit,
  },
  {
    name: 'AdminUsersDetail',
    component: AdminUsersDetail,
  },
  {
    name: 'AdminUsersLinkAdd',
    component: AdminUsersLinkAdd,
  },
  {
    name: 'AdminUsersLinkEdit',
    component: AdminUsersLinkEdit,
  },
  // THEATERS
  {
    name: 'AdminTheaters',
    component: AdminTheaters,
  },
  {
    name: 'AdminTheatersAdd',
    component: AdminTheatersAdd,
  },
  {
    name: 'AdminTheatersEdit',
    component: AdminTheatersEdit,
  },
  {
    name: 'AdminTheatersDetail',
    component: AdminTheatersDetail,
  },
  {
    name: 'AdminTheatersAreaAdd',
    component: AdminTheatersAreaAdd,
  },
  {
    name: 'AdminTheatersAreaEdit',
    component: AdminTheatersAreaEdit,
  },
  {
    name: 'AdminTheatersLinkAdd',
    component: AdminTheatersLinkAdd,
  },
  {
    name: 'AdminTheatersLinkEdit',
    component: AdminTheatersLinkEdit,
  },
  // PRODUCERS
  {
    name: 'AdminProducers',
    component: AdminProducers,
  },
  {
    name: 'AdminProducersAdd',
    component: AdminProducersAdd,
  },
  {
    name: 'AdminProducersEdit',
    component: AdminProducersEdit,
  },
  {
    name: 'AdminProducersDetail',
    component: AdminProducersDetail,
  },
  {
    name: 'AdminProducersLinkAdd',
    component: AdminProducersLinkAdd,
  },
  {
    name: 'AdminProducersLinkEdit',
    component: AdminProducersLinkEdit,
  },
  {
    name: 'AdminProducersAgencyLinkAdd',
    component: AdminProducersAgencyLinkAdd,
  },
  {
    name: 'AdminProducersProductions',
    component: AdminProducersProductions,
  },
  {
    name: 'AdminProducersProductionsAgencyLinkEdit',
    component: AdminProducersProductionsAgencyLinkEdit,
  },
  {
    name: 'AdminProducersSubventionAdd',
    component: AdminProducersSubventionAdd,
  },
  {
    name: 'AdminProducersSubventionEdit',
    component: AdminProducersSubventionEdit,
  },
  // AGENCIES
  {
    name: 'AdminAgencies',
    component: AdminAgencies,
  },
  {
    name: 'AdminAgenciesAdd',
    component: AdminAgenciesAdd,
  },
  {
    name: 'AdminAgenciesEdit',
    component: AdminAgenciesEdit,
  },
  {
    name: 'AdminAgenciesDetail',
    component: AdminAgenciesDetail,
  },
  {
    name: 'AdminAgenciesLinkAdd',
    component: AdminAgenciesLinkAdd,
  },
  {
    name: 'AdminAgenciesLinkEdit',
    component: AdminAgenciesLinkEdit,
  },
  {
    name: 'AdminAgenciesProducerLinkAdd',
    component: AdminAgenciesProducerLinkAdd,
  },
  // THIRD PARTIES
  {
    name: 'AdminThirdParties',
    component: AdminThirdParties,
  },
  {
    name: 'AdminThirdPartiesAdd',
    component: AdminThirdPartiesAdd,
  },
  {
    name: 'AdminThirdPartiesEdit',
    component: AdminThirdPartiesEdit,
  },
  {
    name: 'AdminThirdPartiesDetail',
    component: AdminThirdPartiesDetail,
  },
  // GENRES
  {
    name: 'AdminGenres',
    component: AdminGenres,
  },
  // CHARACTERISTICS
  {
    name: 'AdminCharacteristics',
    component: AdminCharacteristics,
  },
  {
    name: 'AdminCharacteristicsAdd',
    component: AdminCharacteristicsAdd,
  },
  {
    name: 'AdminCharacteristicsEdit',
    component: AdminCharacteristicsEdit,
  },
  {
    name: 'AdminCharacteristicsDetail',
    component: AdminCharacteristicsDetail,
  },
  // SEASONS
  {
    name: 'AdminSeasons',
    component: AdminSeasons,
  },
  // PERFORMANCES
  {
    name: 'AdminPerformances',
    component: AdminPerformances,
  },
  // CREATIVECAST
  {
    name: 'AdminCreativeCast',
    component: AdminCreativeCast,
  },
  {
    name: 'AdminCreativeCastAdd',
    component: AdminCreativeCastAdd,
  },
  {
    name: 'AdminCreativeCastEdit',
    component: AdminCreativeCastEdit,
  },
  {
    name: 'AdminCreativeCastDetail',
    component: AdminCreativeCastDetail,
  },
  // NOTIFICATIONS
  {
    name: 'AdminNotifications',
    component: AdminNotifications,
  },
  {
    name: 'AdminNotificationsAdd',
    component: AdminNotificationsAdd,
  },
  {
    name: 'AdminNotificationsEdit',
    component: AdminNotificationsEdit,
  },
  {
    name: 'AdminNotificationsDetail',
    component: AdminNotificationsDetail,
  },
  // RIGHTS
  {
    name: 'AdminRights',
    component: AdminRights,
  },
  {
    name: 'AdminRightsEdit',
    component: AdminRightsEdit,
  },

  //////////////
  // MONITOR
  //////////////

  // Synchronization
  {
    name: 'MonitorSynchronization',
    component: MonitorSynchronization,
  },
  // Ticket synchronization
  {
    name: 'MonitorTicketSynchronization',
    component: MonitorTicketSynchronization,
  },
  // Contact persons subsidized producers
  {
    name: 'MonitorContactPersonsSubsidizedProducers',
    component: MonitorContactPersonsSubsidizedProducers,
  },
  // Duplicate performances
  {
    name: 'MonitorDuplicatePerformances',
    component: MonitorDuplicatePerformances,
  },
];

const viewService = {
  getView(name) {
    var view;
    for (var element of views) {
      if (name == element.name) {
        view = element.component;
        break;
      }
    }
    return view;
  },
};

export { viewService };
