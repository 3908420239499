var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"py-6 px-3 pb-0 mb-4",attrs:{"flat":""}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isCopyrightRejected ? 'error--text ' : 'primary--text '},[_vm._v(" auteursrechten ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.copyrightPercentage != null &&
              _vm.contract.previousApproved.copyrightPercentage != _vm.contract.copyrightPercentage
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.contract.isCopyrightRejected
                  ? 'error--text no-dash'
                  : 'primary--text text-decoration-line-through mb-0 pr-2'},[_vm._v(" "+_vm._s(_vm.getPercentageView(_vm.contract.previousApproved.copyrightPercentage))+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(" "+_vm._s(_vm.getPercentageView(_vm.contract.copyrightPercentage))+" ")])]):_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.contract.isCopyrightRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.getPercentageView(_vm.contract.copyrightPercentage))+" ")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isBuyoutAmountRejected ? 'error--text ' : 'primary--text '},[_vm._v(" uitkoopsom (excl. BTW) ")]),_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.contract.isBuyoutAmountRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.buyoutAmount))+" ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }