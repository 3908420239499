<template>
  <div>
    <!-- Form -->
    <v-form
      @submit.prevent
      v-model="formValid"
      class="d-flex flex-column"
      ref="form"
    >
      <h2 class="primary--text mb-2">wat houdt de financiële afspraak in?</h2>

      <v-card
        class="py-6 pb-0 px-3 mb-4"
        flat
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <p class="primary--text">
                afrekenprijzen <br /><i
                  >afrekenprijzen zijn exclusief theatertoeslag en exclusief toeslagen / alleen bedrag 1 is verplicht</i
                >
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              v-for="i in 10"
              :key="i"
              cols="12"
              md="3"
              class="py-0"
            >
              <v-text-field
                class="primary--text suffix-border-left-number"
                prefix="€"
                :suffix="i.toString()"
                v-model="form[`ticketPrice${i}`]"
                :rules="formValidationRules.valutaRequired"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card
        class="py-6 pb-0 px-3 mb-10"
        flat
      >
        <v-container>
          <v-row v-if="form.type == 'Uitkoop'">
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-text-field
                class="primary--text"
                label="theatertoeslag"
                prefix="€"
                v-model="form.theaterFee"
                :rules="formValidationRules.valutaRequired"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-text-field
                class="primary--text"
                label="consumptietoeslag"
                prefix="€"
                v-model="form.consumptionSurcharge"
                :rules="formValidationRules.valutaRequired"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-text-field
                class="primary--text"
                label="servicetoeslag"
                prefix="€"
                v-model="form.serviceSurcharge"
                :rules="formValidationRules.valutaRequired"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-text-field
                class="primary--text"
                label="Garderobetoeslag"
                prefix="€"
                v-model="form.wardrobeSurcharge"
                :rules="formValidationRules.valutaRequired"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <h2 class="primary--text mb-2">inzet technici</h2>

      <v-card
        class="py-6 pb-0 px-3 mb-10"
        flat
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-text-field
                class="primary--text"
                label="aantal technici theater"
                type="number"
                :value="form.theaterTechnicians"
                @input="form.theaterTechnicians = $event !== '' ? $event : null"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-text-field
                class="primary--text"
                label="aantal technici producent"
                type="number"
                :value="form.producerTechnicians"
                @input="form.producerTechnicians = $event !== '' ? $event : null"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <h2 class="primary--text mb-2">zijn er nog extra afspraken?</h2>

      <v-card
        class="py-6 pb-0 px-3 mb-10"
        flat
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="10"
              class="py-0"
            >
              <v-textarea
                class="primary--text"
                label="extra afspraken"
                placeholder="bijvoorbeeld: toeslag op kaartje, publiciteitsafspraken, start verkoop, dynamic pricing, toegestane kortingen"
                v-model="form.remark"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <h2 class="primary--text mb-2">geldt er een standaardovereenkomst voor deze afspraak?</h2>

      <v-card
        class="py-6 pb-0 px-3 mb-10"
        flat
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="10"
              class="py-0"
            >
              <v-checkbox
                label="door aanbieder en afnemer overeengekomen standaardovereenkomst is van toepassing"
                v-model="form.acceptTerms"
                class="checkbox primary--text ma-0"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import functions from '../../../../services/functions.service';

export default {
  props: ['form'],
  data() {
    return {
      formValid: false,
      formError: '',
      formValidationRules: {},
    };
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    if (!this.form.theaterFee) {
      this.form.theaterFee =
          this.form.performances[0].theaterFee == null
              ? this.form.theaterFee
              : functions.getEuroCurrencyView(this.form.performances[0].theaterFee);
    }
    if (!this.form.consumptionSurcharge) {
      this.form.consumptionSurcharge =
          this.form.performances[0].consumptionFee == null
              ? "0,00"
              : functions.getEuroCurrencyView(this.form.performances[0].consumptionFee);
    }
    if (!this.form.serviceSurcharge) {
      this.form.serviceSurcharge =
          this.form.performances[0].serviceFee == null
              ? "0,00"
              : functions.getEuroCurrencyView(this.form.performances[0].serviceFee);
    }
    if (!this.form.wardrobeSurcharge)
    {
      this.form.wardrobeSurcharge =
          this.form.performances[0].wardrobeFee == null
              ? "0,00"
              : functions.getEuroCurrencyView(this.form.performances[0].wardrobeFee);
    }
  },
  methods: {
    validateForm() {
      this.$refs.form.validate();
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('form', this.form);
      },
    },
    formValid: function () {
      this.$emit('valid', this.formValid);
    },
  },
};
</script>

<style scoped></style>
