<template>
  <div>
    <!-- Agreement Suppletie -->
    <v-card
      class="py-6 px-3 pb-0 mb-4"
      flat
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p
              :class="contract.isTheaterSurchargeRejected ? 'error--text ' : 'primary--text '"
              class="font-weight-medium mb-0"
            >
              theatertoeslag
            </p>
            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.theaterFee &&
                contract.previousApproved.theaterFee != null &&
                contract.previousApproved.theaterFee != contract.theaterFee
              "
            >
              <h3
                :class="
                  contract.isTheaterSurchargeRejected
                    ? 'error--text no-dash'
                    : 'primary--text text-decoration-line-through mb-0 pr-2'
                "
              >
                {{ getEuroSignView(contract.previousApproved.theaterFee) }}
              </h3>
              <h3 class="prev-approved--text pb-4 border-bottom">
                {{ getEuroSignView(contract.theaterFee) }}
              </h3>
            </div>
            <h3
              v-else
              :class="contract.isTheaterSurchargeRejected ? 'error--text ' : 'primary--text '"
              class="border-bottom pb-4 mb-4"
            >
              {{ getEuroSignView(contract.theaterFee) }}
            </h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p
              :class="contract.isCopyrightRejected ? 'error--text ' : 'primary--text '"
              class="font-weight-medium mb-0"
            >
              auteursrechten
            </p>

            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.copyrightPercentage &&
                contract.previousApproved.copyrightPercentage != null &&
                contract.previousApproved.copyrightPercentage != contract.copyrightPercentage
              "
            >
              <h3
                :class="
                  contract.isCopyrightRejected
                    ? 'error--text no-dash'
                    : 'primary--text text-decoration-line-through mb-0 pr-2'
                "
              >
                {{ getEuroSignView(contract.previousApproved.copyrightPercentage) }}
              </h3>
              <h3 class="prev-approved--text pb-4 border-bottom">
                {{ getEuroSignView(contract.copyrightPercentage) }}
              </h3>
            </div>
            <h3
              v-else
              :class="contract.isCopyrightRejected ? 'error--text ' : 'primary--text '"
              class="border-bottom pb-4 mb-4"
            >
              {{ getPercentageView(contract.copyrightPercentage) }}
            </h3>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p
              :class="contract.isCopyrightCalculationRejected ? 'error--text ' : 'primary--text '"
              class="font-weight-medium mb-0"
            >
              AR percentageberekening
            </p>

            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.copyrightPercentage &&
                contract.previousApproved.copyrightPercentage != null &&
                contract.previousApproved.copyrightPercentage != contract.copyrightPercentage
              "
            >
              <h3
                :class="
                  contract.isCopyrightRejected
                    ? 'error--text no-dash'
                    : 'primary--text text-decoration-line-through mb-0 pr-2'
                "
              >
                <span v-if="contract.calculateCopyrightPercentage">{{
                  parseFloat(getDecimalDatabase(contract.previousApproved.copyrightPercentage)) +
                  '/1' +
                  parseFloat(getDecimalDatabase(contract.previousApproved.copyrightPercentage)).toLocaleString(
                    'en-US',
                    { minimumIntegerDigits: 2, useGrouping: false }
                  )
                }}</span>
                <span v-else>{{
                  parseFloat(getDecimalDatabase(contract.previousApproved.copyrightPercentage)) + '/100'
                }}</span>
              </h3>
              <h3 class="prev-approved--text pb-4 border-bottom">
                <span v-if="contract.calculateCopyrightPercentage">{{
                  parseFloat(getDecimalDatabase(contract.copyrightPercentage)) +
                  '/1' +
                  parseFloat(getDecimalDatabase(contract.copyrightPercentage)).toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })
                }}</span>
                <span v-else>{{ parseFloat(getDecimalDatabase(contract.copyrightPercentage)) + '/100' }}</span>
              </h3>
            </div>

            <h3
              v-else
              :class="
                contract.isCopyrightCalculationRejected ? 'error--text ' : 'primary--text text-decoration-line-through'
              "
              class="border-bottom pb-4 mb-4"
            >
              <span v-if="contract.calculateCopyrightPercentage">{{
                parseFloat(getDecimalDatabase(contract.copyrightPercentage)) +
                '/1' +
                parseFloat(getDecimalDatabase(contract.copyrightPercentage)).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })
              }}</span>
              <span v-else>{{ parseFloat(getDecimalDatabase(contract.copyrightPercentage)) + '/100' }}</span>
            </h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p
              :class="contract.isAmountRejected ? 'error--text ' : 'primary--text '"
              class="font-weight-medium mb-0"
            >
              aanvulling tot (excl. BTW)
            </p>
            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.amount &&
                contract.previousApproved.amount != null &&
                contract.previousApproved.amount != contract.amount
              "
            >
              <h3
                :class="
                  contract.isAmountRejected
                    ? 'error--text no-dash'
                    : 'primary--text text-decoration-line-through mb-0 pr-2'
                "
              >
                {{ getEuroSignView(contract.previousApproved.amount) }}
              </h3>
              <h3 class="prev-approved--text pb-4 border-bottom">
                {{ getEuroSignView(contract.amount) }}
              </h3>
            </div>
            <h3
              v-else
              :class="contract.isAmountRejected ? 'error--text ' : 'primary--text '"
              class="border-bottom pb-4 mb-4"
            >
              {{ getEuroSignView(contract.amount) }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p
              :class="contract.isMaximumAmountRejected ? 'error--text ' : 'primary--text '"
              class="font-weight-medium mb-0"
            >
              maximale aanvulling (excl. BTW)
            </p>

            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.maximumAmount &&
                contract.previousApproved.maximumAmount != null &&
                contract.previousApproved.maximumAmount != contract.maximumAmount
              "
            >
              <h3
                :class="
                  contract.isMaximumAmountRejected
                    ? 'error--text no-dash'
                    : 'primary--text text-decoration-line-through mb-0 pr-2'
                "
              >
                {{ getEuroSignView(contract.previousApproved.maximumAmount) }}
              </h3>
              <h3 class="prev-approved--text pb-4 border-bottom">
                {{ getEuroSignView(contract.maximumAmount) }}
              </h3>
            </div>
            <h3
              v-else
              :class="contract.isMaximumAmountRejected ? 'error--text ' : 'primary--text '"
              class="border-bottom pb-4 mb-4"
            >
              {{ getEuroSignView(contract.maximumAmount) }}
            </h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p
              :class="contract.isProviderPartageRejected ? 'error--text ' : 'primary--text '"
              class="font-weight-medium mb-0"
            >
              partage aanbieder
            </p>

            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.partageProviderPercentage &&
                contract.previousApproved.partageProviderPercentage != null &&
                contract.previousApproved.partageProviderPercentage != contract.partageProviderPercentage
              "
            >
              <h3
                :class="
                  contract.isProviderPartageRejected
                    ? 'error--text no-dash'
                    : 'primary--text text-decoration-line-through mb-0 pr-2'
                "
              >
                {{ getPercentageView(contract.previousApproved.partageProviderPercentage) }}
              </h3>
              <h3 class="prev-approved--text pb-4 border-bottom">
                {{ getPercentageView(contract.partageProviderPercentage) }}
              </h3>
            </div>
            <h3
              v-else
              :class="contract.isProviderPartageRejected ? 'error--text ' : 'primary--text '"
              class="border-bottom pb-4 mb-4"
            >
              {{ getPercentageView(contract.partageProviderPercentage) }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0 mb-8"
          >
            <p
              :class="contract.isGuaranteePerPerformanceRejected ? 'error--text ' : 'primary--text '"
              class="font-weight-medium mb-0"
            >
              aanvullingsberekening
            </p>

            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.guaranteePerPerformance &&
                contract.previousApproved.guaranteePerPerformance != null &&
                contract.previousApproved.guaranteePerPerformance != contract.guaranteePerPerformance
              "
            >
              <h3
                :class="contract.isGuaranteePerPerformanceRejected ? 'error--text no-dash' : 'primary--text mb-0 pr-2'"
              >
                {{
                  contract.previousApproved.guaranteePerPerformance
                    ? 'afspraak per voorstelling'
                    : 'afspraak over het totaal'
                }}
              </h3>
              <h3 class="error--text pb-4 border-bottom">
                {{ contract.guaranteePerPerformance }}
              </h3>
            </div>
            <h3
              v-else
              :class="contract.isGuaranteePerPerformanceRejected ? 'error--text ' : 'primary--text '"
              class="border-bottom pb-4 mb-0"
            >
              {{ contract.guaranteePerPerformance ? 'afspraak per voorstelling' : 'afspraak over het totaal' }}
            </h3>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import functions from '../../../../services/functions.service';

export default {
  props: ['contract'],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    getEuroSignView(value) {
      if (value != '') {
        return functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(value)));
      } else {
        return '-';
      }
    },
    getDecimalDatabase(value) {
      if (value != '') {
        return functions.getDecimalDatabase(value);
      } else {
        return '-';
      }
    },
    getPercentageView(value) {
      if (value != '') {
        return functions.getPercentageView(this.getDecimalDatabase(value));
      } else {
        return '-';
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {},
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #e1e7f2;
}
</style>
