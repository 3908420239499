<template>
  <div>
    <v-expansion-panels
      inset
      flat
      multiple
      :value="[0]"
      v-if="contract.logs != null && contract.logs.length > 0"
    >
      <v-expansion-panel class="mb-3">
        <v-expansion-panel-header><h3 class="primary--text py-2">log</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list-item-group
            :value="1"
            no-action
          >
            <v-list nav>
              <template v-for="log in contract.logs">
                <v-list-item
                  color="primary"
                  link
                  :to="{ name: 'ContractsDetail', params: { type: log.type, contractId: log.id } }"
                  :key="log.id"
                  v-model="logActive"
                >
                  <v-list-item-title>
                    <div class="d-flex align-center">
                      <h6 class="ma-0 mr-4 primary--text font-weight-regular">
                        {{ getDateTimeView(log.modifiedDate) }}
                      </h6>
                      <h5
                        v-if="log.state == 'proposed'"
                        class="ma-0 primary--text"
                      >
                        voorgesteld <i class="font-weight-light">door {{ log.userFullName }}</i>
                      </h5>
                      <h5
                        v-else-if="log.state == 'rejected'"
                        class="ma-0 primary--text"
                      >
                        afgekeurd <i class="font-weight-light">door {{ log.userFullName }}</i>
                      </h5>
                      <h5
                        v-else-if="log.state == 'approved'"
                        class="ma-0 primary--text"
                      >
                        goedgekeurd <i class="font-weight-light">door {{ log.userFullName }}</i>
                      </h5>
                      <h5
                        v-else-if="log.state == 'revised'"
                        class="ma-0 primary--text"
                      >
                        aangepast voorstel <i class="font-weight-light">door {{ log.userFullName }}</i>
                      </h5>
                      <h5
                        v-else-if="log.state == 'concept'"
                        class="ma-0 primary--text"
                      >
                        concept <i class="font-weight-light">door {{ log.userFullName }}</i>
                      </h5>
                    </div>
                  </v-list-item-title>
                  <v-list-item-icon><v-icon small>mdi-chevron-right-circle</v-icon></v-list-item-icon>
                </v-list-item>
              </template>
            </v-list>
          </v-list-item-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import ApiService from '../../../../services/api.service';
// import { TokenService } from "../../../../services/storage.service";
import functions from '../../../../services/functions.service';

export default {
  props: ['contract'],
  data() {
    return {
      logActive: null,
    };
  },
  mounted() {
    this.checkActiveLog();
  },
  methods: {
    checkActiveLog() {
      if (this.contract.logs != null && this.contract.logs.length > 0) {
        this.logActive = this.contract.logs.map((log) => log.id).indexOf(this.contract.id);
      }
    },
    getDateTimeView(value) {
      return functions.getDateTimeView(value);
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {},
};
</script>

<style scoped></style>
