<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="1123"
            ></help>
          </v-col>
        </v-row>

        <v-row flex>
          <v-col
            cols="12"
            sm="12"
            md="4"
            class="pa-0 ma-0 d-block d-sm-flex d-md-block"
          >
            <monitor-synchronization-widget
              :cols-desktop="12"
              :cols-tablet="6"
              :cols-mobile="12"
              :help-id="1139"
            ></monitor-synchronization-widget>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import { mapGetters, mapState } from "vuex";
// import { TokenService } from "@/services/storage.service";
//  import ApiService from "@/services/api.service";
// import functions from '@/services/functions.service';

// import { mapState } from "vuex";

import Help from '@/components/Help';

// widgets
import MonitorSynchronizationWidget from '@/components/widgets/MonitorSynchronization.vue';

export default {
  name: 'MonitorSynchronization',
  components: {
    Help,
    MonitorSynchronizationWidget,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'monitor',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'synchronisatie',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>
