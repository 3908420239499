<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
            <help
              bg-color="#fff"
              help-id="1"
            ></help>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableDuplicatePerformancesHeaders"
          :items="tableDuplicatePerformancesFiltered"
          :mobile-breakpoint="0"
          :loading="tableDuplicatePerformancesLoading"
          :page.sync="tableDuplicatePerformancesPaging.page"
          :items-per-page="tableDuplicatePerformancesPaging.itemsPerPage"
          :expanded="clearExpand"
          hide-default-footer
          @page-count="tableDuplicatePerformancesPaging.pageCount = $event"
          class="table-action table-expand mt-2"
          item-key="key"
          single-expand
          show-expand
          fixed-header
          :height="tableDuplicatePerformancesFiltered.length > 10 ? '60vh' : ''"
        >
          <template v-slot:[`item.startDate`]="{ item }">
            <span>{{ item.startDate_formatted }}</span>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0"
            >
              <v-data-table
                :headers="tableProductionsDetailsHeaders"
                :items="item.performances"
                :mobile-breakpoint="0"
                hide-default-footer
                :items-per-page="-1"
              >
                <template v-slot:[`item.type`]="{ item }">
                  <span v-if="item.type == 'normal'">normaal</span>
                  <span v-if="item.type == 'tryout'">tryout</span>
                  <span v-if="item.type == 'premiere'">premiere</span>
                  <span v-if="item.type == 'montage'">montage</span>
                  <span v-if="item.type == 'derniere'">derniere</span>
                </template>

                <template v-slot:[`item.state`]="{ item }">
                  <span v-if="item.state == 'agreed'">geaccepteerd</span>
                  <span v-if="item.state == 'option'">optie</span>
                  <span v-if="item.state == 'success'">succesoptie</span>
                  <span v-if="item.state == 'cancellation'">annulering</span>
                  <span v-if="item.state == 'interest'">interesse</span>
                </template>

                <template v-slot:[`item.startDate`]="{ item }">
                  <span>{{ item.startDate_formatted }}</span>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    fab
                    x-small
                    depressed
                    color="warning"
                    class="ml-1"
                    @click="(deleteItem = item), (deleteItemDialog = true);"
                  >
                    <v-icon color="primary">mdi-check</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>

        <!-- Table settings -->
        <v-row
          class="text-center pt-2"
          align="center"
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption primary--text ma-0 pa-0">
              totaal: {{ this.tableDuplicatePerformancesFiltered.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableDuplicatePerformancesFiltered.length > 25"
              @click="tableDuplicatePerformancesPaging.itemsPerPage = 25"
              :color="tableDuplicatePerformancesPaging.itemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tableDuplicatePerformancesFiltered.length > 25"
              @click="tableDuplicatePerformancesPaging.itemsPerPage = 50"
              :color="tableDuplicatePerformancesPaging.itemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tableDuplicatePerformancesFiltered.length > 50"
              @click="tableDuplicatePerformancesPaging.itemsPerPage = 100"
              :color="tableDuplicatePerformancesPaging.itemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tableDuplicatePerformancesPaging.pageCount > 1"
              v-model="tableDuplicatePerformancesPaging.page"
              :length="tableDuplicatePerformancesPaging.pageCount"
              :total-visible="7"
              :elevation="0"
            ></v-pagination>
          </v-col>

          <!-- Table download -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex justify-center justify-sm-end"
          >
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
            >
              <vue-excel-xlsx
                class="v-btn font-weight-bold text-lowercase"
                :data="tableDuplicatePerformancesFiltered"
                :columns="downloadExportColumns"
                :filename="'dip-monitor-ticket-monitor'"
                :sheetname="'kaartstanden synchronisatie'"
                >exporteer selectie <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">Voorstelling met ID:{{ this.deleteItem.id }} fiatteren?</v-card-title>
        <v-card-text
          >Weet je zeker dat je deze voorstelling met ID{{ this.deleteItem.id }} en PKI-nummer
          {{ this.deleteItem.pkiNumber }} wilt fiatteren? Deze is daarna niet meer terug te vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(deleteItemDialog = false), (deleteItem = '');"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="approvePerformance()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
      elevation="0"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import ApiService from '@/services/api.service';
import { TokenService } from '@/services/storage.service';
import Help from '@/components/Help.vue';
import functions from '@/services/functions.service';

export default {
  name: 'MonitorDuplicatePerformances',
  components: {
    Help,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'monitor',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'dubbele voorstellingen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      duplicatePerformances: [],
      types: [],
      tableDuplicatePerformancesFiltered: [],
      clearExpand: [],
      tableDuplicatePerformancesLoading: true,
      tableDuplicatePerformancesHeaders: [
        { text: 'datum', value: 'startDate' },
        { text: 'theater', value: 'theaterName' },
        { text: 'zaal', value: 'areaName' },
        { text: '', value: 'data-table-expand', align: 'end' },
      ],
      tableDuplicatePerformancesPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableDuplicatePerformancesFilterDialog: false,
      tableProductionsDetailsHeaders: [
        { text: 'productie', value: 'productionTitle' },
        { text: 'productie nummer', value: 'productionNumber' },
        { text: 'id', value: 'id' },
        { text: 'nummer', value: 'number' },
        { text: 'type', value: 'type' },
        { text: 'status', value: 'state' },
        { text: 'startdatum', value: 'startDate' },
        { text: 'impresariaat', value: 'agencyName' },
        { text: 'producent', value: 'producerName' },
        {
          text: '',
          value: 'action',
          sortable: false,
          align: 'end',
        },
      ],
      downloadExportColumns: [
        { label: 'productie', field: 'productionTitle' },
        { label: 'productie nummer', field: 'productionNumber' },
        { label: 'theater', field: 'theaterName' },
      ],
      deleteItemDialog: false,
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
    };
  },
  mounted() {
    this.getDuplicatePerformances();
  },
  methods: {
    getDateTimeView: function (date) {
      return functions.getDateTimeView(date);
    },
    getDuplicatePerformances: function () {
      const vm = this;
      this.duplicatePerformances = [];
      this.tableDuplicatePerformancesFiltered = [];
      this.clearExpand = [];
      this.tableDuplicatePerformancesLoading = true;

      ApiService.get('/Performances/GetDuplicates')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.duplicatePerformances = response.data.result;

          for (var i = 0; i < this.duplicatePerformances.length; i++) {
            vm.$set(this.duplicatePerformances[i], 'key', i);
            vm.$set(
              this.duplicatePerformances[i],
              'startDate_formatted',
              functions.getDateTimeView(this.duplicatePerformances[i].startDate)
            );

            for (var j = 0; j < this.duplicatePerformances[i].performances.length; j++) {
              vm.$set(
                this.duplicatePerformances[i].performances[j],
                'startDate_formatted',
                functions.getDateTimeView(this.duplicatePerformances[i].performances[j].startDate)
              );
            }
          }

          this.tableDuplicatePerformancesFiltered = this.duplicatePerformances;
          this.tableDuplicatePerformancesLoading = false;
        })
        .catch((error) => {
          //eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    approvePerformance: function () {
      ApiService.put('/Performances/UpdateChecked', {
        performanceId: this.deleteItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableDuplicatePerformancesLoading = true;

            this.getDuplicatePerformances();

            this.snackbarText = this.deleteItem.id + ' is verwijderd';
            this.deleteItemDialog = false;
            this.deleteItem = '';
            this.snackbarColor = 'success';
            this.snackbar = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
};
</script>

<style lang="scss">
.table-expand .v-data-table__expanded.v-data-table__expanded__content > td {
  position: static;
  top: unset;
  left: unset;
  height: auto;
}
</style>
