import { Bar } from 'vue-chartjs/legacy';
//import ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
  extends: Bar,
  props: ['options'],
  mounted() {
    //this.addPlugin(ChartDataLabels)
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
  },
};
