<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="44"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <!-- User details -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <h1 class="primary--text mb-5">{{ this.userInfo.firstName }} {{ this.userInfo.lastName }}</h1>
                  <h4 class="primary--text mb-2 font-weight-regular">gegevens gebruiker</h4>

                  <v-card
                    class="pa-6 mb-1"
                    flat
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>naam:</small> <br />
                          <strong>{{ userInfo.firstName }} {{ userInfo.lastName }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>e-mailadres:</small> <br />
                          <strong>{{ userInfo.email }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>gebruikersrol:</small> <br />
                          <strong>{{ userInfo.roleName }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>

                  <h4 class="primary--text mt-8 mb-2 font-weight-regular">rechten gebruiker</h4>
                  <!-- Table -->
                  <v-data-table
                    :headers="tableRightsHeaders"
                    :items="userInfo.sitemap"
                    :mobile-breakpoint="0"
                    :loading="tableRightsLoading"
                    :items-per-page="-1"
                    hide-default-footer
                  >
                    <template v-slot:[`item.rights.view`]="{ item }">
                      <v-checkbox
                        v-model="item.rights.view"
                        v-if="item.rights.view != null"
                        :input-value="item.rights.view"
                        value
                        disabled
                      ></v-checkbox>
                      <div v-else>-</div>
                    </template>
                    <template v-slot:[`item.rights.edit`]="{ item }">
                      <v-checkbox
                        v-model="item.rights.edit"
                        v-if="item.rights.edit != null"
                        :input-value="item.rights.edit"
                        value
                        disabled
                      ></v-checkbox>
                      <div v-else>-</div>
                    </template>
                    <template v-slot:[`item.rights.approve`]="{ item }">
                      <v-checkbox
                        v-model="item.rights.approve"
                        v-if="item.rights.approve != null"
                        :input-value="item.rights.approve"
                        value
                        disabled
                      ></v-checkbox>
                      <div v-else>-</div>
                    </template>

                    <v-data-footer></v-data-footer>
                  </v-data-table>

                  <div>
                    <p class="grey--text text--lighten-1 mt-0">
                      <small
                        ><i>{{ this.userInfo.lastEditedBy }}</i></small
                      >
                    </p>

                    <!-- Bewerken -->
                    <v-btn
                      v-if="rights.edit"
                      rounded
                      large
                      elevation="0"
                      outlined
                      color="primary"
                      class="text-lowercase mb-5 mr-5 align-self-start"
                      :to="{ name: 'LibraryUsersEdit' }"
                      >gegevens Bewerken
                      <v-icon
                        small
                        right
                        >mdi-square-edit-outline</v-icon
                      ></v-btn
                    >

                    <!-- Verwijderen -->
                    <v-btn
                      v-if="rights.edit && user.userId != this.userId"
                      rounded
                      large
                      elevation="0"
                      @click="deleteItemDialog = true"
                      color="error"
                      class="text-lowercase mb-5 align-self-start"
                    >
                      verwijderen
                      <v-icon
                        small
                        right
                        >mdi-delete</v-icon
                      ></v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog user starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.user.firstName }} {{ this.user.lastName }} verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je het account voor {{ this.user.firstName }} {{ this.user.lastName }} wil verwijderen? Het
          is daarna niet meer terug te vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemUser()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog link starts here -->
    <v-dialog
      v-model="deleteItemLinkDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">Gebruikersrol verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je deze gebruikersrol wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemLinkDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemLinkUser()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';

import Help from '../../../components/Help.vue';

export default {
  name: 'LibraryUsersDetail',
  props: ['userId'],
  components: {
    Help,
  },
  data() {
    return {
      userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        roleName: '',
        linkId: '',
        lastEditedBy: '',
        sitemap: [],
      },
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruikers',
          disabled: false,
          href: '/beheer/gebruikers',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      deleteItemDialog: false,
      deleteItemLinkDialog: false,
      deleteItemLink: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      editUserDialog: false,

      tableRightsHeaders: [
        { text: 'module', value: 'name', sortable: false },
        { text: 'inzage', value: 'rights.view' },
        { text: 'wijzigen', value: 'rights.edit' },
        { text: 'accorderen', value: 'rights.approve' },
      ],
      tableRightsLoading: true,
    };
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryUsersDetail');
    this.getUser();
  },
  methods: {
    getUser: function () {
      ApiService.get('/Users/GetPerOrganisation', {
        userId: this.userId,
      })
        .then((response) => {
          if (response.data.success) {
            const userInfo = response.data.result;
            this.breadcrumbs[2].text = userInfo.firstName + ' ' + userInfo.lastName;
            this.userInfo.firstName = userInfo.firstName;
            this.userInfo.lastName = userInfo.lastName;
            this.userInfo.email = userInfo.email;
            this.userInfo.roleName = userInfo.roleName;
            this.userInfo.linkId = userInfo.linkId;
            this.userInfo.lastEditedBy = userInfo.lastEditedBy;
            this.userInfo.sitemap = userInfo.sitemap;

            this.tableRightsLoading = false;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    deleteItemUser: function () {
      ApiService.delete('/Users/DeletePerOrganisation', {
        linkId: this.userInfo.linkId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.userInfo.firstName + ' is verwijderd';
            this.deleteItemDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/beheer/gebruikers' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
