<template>
  <div>
    <v-card
      class="pa-3 mb-4"
      flat
      color="accent"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between border-bottom"
          >
            <p class="primary--text font-weight-bold mb-0">resultaten (indicatie)</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between border-bottom"
          >
            <p class="primary--text font-weight-medium mb-0">vrijkaarten</p>
            <p class="primary--text font-weight-medium mb-0">
              {{ contract.tickets.free }}
            </p>
          </v-col>
        </v-row>

        <v-row
          v-for="other in contract.tickets.other"
          :key="'ticketsOther' + other.toString()"
        >
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between border-bottom-dark"
          >
            <p class="primary--text font-weight-medium mb-0">kaarten verkocht</p>
            <p class="primary--text font-weight-medium mb-0">
              {{ formatThousandsWithDot(other.value) }}
            </p>
          </v-col>
        </v-row>

        <v-row
          v-for="vat in contract.tickets.revenueInVat"
          :key="'ticketsRevenueInVat' + vat.toString()"
        >
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between border-bottom"
          >
            <p class="primary--text font-weight-bold mb-0">
              bruto recette
              <help
                bg-color="#fff"
                help-id="1150"
              ></help>
            </p>
            <p class="primary--text font-weight-bold mb-0">
              {{ getEuroSignView(vat.value) }}
            </p>
          </v-col>
        </v-row>

        <v-row v-if="contract.calculation">
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between border-bottom"
          >
            <p class="primary--text font-weight-medium mb-0">af: theatertoeslagen</p>
            <p class="primary--text font-weight-medium mb-0">
              {{ getEuroSignView(contract.calculation.theaterFeeInVat) }}
            </p>
          </v-col>
        </v-row>

        <v-row v-if="contract.calculation && contract.calculation.consumptionFeeInVat">
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between border-bottom-dark"
          >
            <p class="primary--text font-weight-medium mb-0">af: consumptietoeslag</p>
            <p class="primary--text font-weight-medium mb-0">
              {{ getEuroSignView(contract.calculation.consumptionFeeInVat) }}
            </p>
          </v-col>
        </v-row>

        <v-row v-if="contract.calculation">
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between border-bottom"
          >
            <p class="primary--text font-weight-bold mb-0">
              recette
              <help
                bg-color="#fff"
                help-id="1151"
              ></help>
            </p>
            <p class="primary--text font-weight-bold mb-0">
              {{ getEuroSignView(contract.calculation.grossRevenueInVat) }}
            </p>
          </v-col>
        </v-row>

        <v-row v-if="contract.calculation">
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between border-bottom"
          >
            <p class="primary--text font-weight-medium mb-0">af: btw (9%)</p>
            <p class="primary--text font-weight-medium mb-0">
              {{ getEuroSignView(contract.calculation.grossRevenueVat) }}
            </p>
          </v-col>
        </v-row>

        <v-row v-if="contract.calculation">
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between border-bottom-dark"
          >
            <p class="primary--text font-weight-medium mb-0">
              af: auteursrechten ({{ contract.copyrightPercentage }}%)
            </p>
            <p class="primary--text font-weight-medium mb-0">
              {{ getEuroSignView(contract.calculation.copyrightExVat) }}
            </p>
          </v-col>
        </v-row>

        <v-row v-if="contract.calculation">
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between border-bottom"
          >
            <p class="primary--text font-weight-bold mb-0">
              netto recette (grondslag berekening)
              <help
                bg-color="#fff"
                help-id="1152"
              ></help>
            </p>
            <p class="primary--text font-weight-bold mb-0">
              {{ getEuroSignView(contract.calculation.netRevenueExVat) }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      class="pa-3 mb-4"
      flat
      color="accent"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between border-bottom"
          >
            <p class="primary--text font-weight-bold mb-0">verdeling netto recette</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between border-bottom"
          >
            <p class="primary--text font-weight-medium mb-0">aandeel aanbieder</p>
            <p class="primary--text font-weight-medium mb-0">
              {{ getEuroSignView(contract.calculation.providerRevenueExVat) }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between border-bottom"
          >
            <p class="primary--text font-weight-medium mb-0">btw (9%)</p>
            <p class="primary--text font-weight-medium mb-0">
              {{ getEuroSignView(contract.calculation.providerRevenueVat) }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between border-bottom"
          >
            <p class="primary--text font-weight-medium mb-0">auteursrechten</p>
            <p class="primary--text font-weight-medium mb-0">
              {{ getEuroSignView(contract.calculation.copyrightTotalExVat) }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between border-bottom"
          >
            <p class="primary--text font-weight-medium mb-0">btw over auteursrechten (21%)</p>
            <p class="primary--text font-weight-medium mb-0">
              {{ getEuroSignView(contract.calculation.copyrightTotalVat) }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="py-4 d-flex align-center justify-space-between"
          >
            <p class="primary--text font-weight-bold mb-0">Totaal</p>
            <p class="primary--text font-weight-bold mb-0">
              {{ getEuroSignView(contract.calculation.providerTotalInVat) }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <p class="primary--text"><i>berekening conform BUMA</i></p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import ApiService from '../../../../services/api.service';
// import { TokenService } from "../../../../services/storage.service";
import functions from '../../../../services/functions.service';
import Help from '@/components/Help.vue';

export default {
  components: { Help },
  props: ['contract'],
  data() {
    return {
      result: null,
    };
  },
  mounted() {},
  methods: {
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getEuroSignView(value) {
      return functions.getEuroSignView(value);
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {},
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid rgb(2 30 114 / 0.1);
}

.border-bottom-dark {
  border-bottom: 1px solid rgb(2 30 114 / 0.5);
}
</style>
