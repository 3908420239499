var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"table-action row-is-clickable mt-5",attrs:{"headers":_vm.tablePublicMonitorHeadersFiltered,"items":_vm.tableProductionPerformancesFiltered,"mobile-breakpoint":0,"loading":_vm.loading,"items-per-page":-1,"hide-default-footer":"","fixed-header":"","height":_vm.tableProductionPerformancesFiltered.length > 10 ? '55vh' : ''},on:{"click:row":_vm.goToDetail},scopedSlots:_vm._u([{key:`item.startDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.startDateView)+" ")]}},{key:`item.action`,fn:function({ item }){return [(
        item.number != null && item.number != 'no_export' && item.dipId != null && item.hasTooFewPurchases == false
      )?_c('v-btn',{staticClass:"ml-1",attrs:{"fab":"","x-small":"","depressed":"","color":"info","to":{
        name: 'SalesMonitorPerformancesDetail',
        params: {
          productionNumber: item.production.number,
          performanceId: item.dipId,
        },
      }}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-link-variant")])],1):_vm._e(),(item.id != null)?_c('v-btn',{staticClass:"ml-1",attrs:{"fab":"","x-small":"","depressed":"","color":"warning","to":{
        name: 'PublicMonitorPerformancesDetail',
        params: {
          productionNumber: _vm.productionNumber,
          performanceId: item.id,
        },
      }}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye")])],1):_vm._e()]}}],null,true)},[_c('v-data-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }