<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="1148"
            ></help>
          </v-col>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <filter-period
                :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                selectedDefault="thisSeason"
                @changePeriod="filterPeriod = $event"
              ></filter-period>
              <v-text-field
                v-model="tableBenchmarkFilter.productionName"
                label="productie zoeken"
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-autocomplete
                v-model="tableBenchmarkFilter.genre"
                :items="genres"
                label="genre selecteren"
                clearable
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tableBenchmarkFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-on="on"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="primary--text">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <filter-period
                      :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                      selectedDefault="thisSeason"
                      @changePeriod="filterPeriod = $event"
                    ></filter-period>
                    <v-text-field
                      v-model="tableBenchmarkFilter.productionName"
                      label="productie zoeken"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-text-field>
                    <v-autocomplete
                      v-model="tableBenchmarkFilter.genre"
                      :items="genres"
                      label="genre selecteren"
                      clearable
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tableBenchmarkFilterDialog = false"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      outlined
                      dark
                      @click="clearFilterProductions()"
                      >reset filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <benchmark-productions-table
          :headers="tableBenchmarkHeadersFiltered"
          :items="tableBenchmarkFiltered"
          :loading="tableBenchmarkLoading"
          :pageSync="tableBenchmarkPage"
          :filter-active="tableBenchmarkFilterActive"
          :selected="tableBenchmarkSelected"
          :total="tableBenchmarkTotal"
          :downloads="downloadExportColumns"
          :detailPage="'BenchmarkProductionsDetail'"
        >
        </benchmark-productions-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import Help from '../../components/Help.vue';
import functions from '../../services/functions.service';
import FilterPeriod from '../../components/FilterPeriod';
import BenchmarkProductionsTable from './components/ProductionsTable';

export default {
  name: 'BenchmarkProductions',
  components: {
    Help,
    FilterPeriod,
    BenchmarkProductionsTable,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'benchmark producties',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      filterPeriod: {
        from: '',
        to: '',
      },
      productions: [],
      tableBenchmarkSelected: {
        performancesCount: 0,
        sold: 0,
        capacity: 0,
        purchases: 0,
      },
      tableBenchmarkTotal: {
        performancesCount: 0,
        sold: 0,
        capacity: 0,
        purchases: 0,
      },
      tableBenchmarkFiltered: [],
      selectAgencyId: '',
      genres: [],
      tableBenchmarkLoading: true,
      tableBenchmarkHeaders: [
        { text: 'naam', value: 'title' },
        { text: 'genre', value: 'genre' },
        { text: 'datum en tijd', value: 'startDate' },
        { text: 'aantal vrst.', value: 'performances' },
        { text: 'capaciteit', value: 'capacity' },
        { text: 'aantal kaartkopers', value: 'purchases' },
        { text: 'verkocht', value: 'tickets' },
        { text: '', value: 'action', sortable: false, align: 'end' },
      ],
      tableBenchmarkFilterActive: false,
      tableBenchmarkFilter: {
        productionName: '',
        genre: '',
      },
      tableBenchmarkPage: 1,
      tableBenchmarkFilterDialog: false,
      downloadExportColumns: [
        { label: 'DIP-ID', field: 'number' },
        { label: 'naam', field: 'title' },
        { label: 'genre', field: 'genre' },
        { label: 'datum en tijd', field: 'startDate' },
        { label: 'aantal vrst.', field: 'performances' },
        { label: 'capaciteit', field: 'capacity' },
        { label: 'aantal kaartkopers', field: 'purchases' },
        { label: 'verkocht', field: 'tickets' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('Benchmark');
    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tableBenchmarkFilter,
    });
    this.tableBenchmarkFilter = this.getFilter(this.$options.name);
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),
    goToDetail(row) {
      this.$router.push({
        name: 'BenchmarkProductionsDetail',
        params: { productionNumber: row.id },
      });
    },
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageViewWithoutDecimals(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    getProductions: function () {
      var vm = this;
      this.productions = [];
      this.tableBenchmarkFiltered = [];
      this.tableBenchmarkLoading = true;

      ApiService.get('Benchmark/GetProductions', {
        startDate: functions.jsDateToStringDate(this.filterPeriod.from),
        endDate: functions.jsDateToStringDate(this.filterPeriod.to),
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.productions = response.data.result;

          for (var i = 0; i < this.productions.length; i++) {
            vm.$set(this.productions[i], 'startDateView', functions.getDateTimeView(this.productions[i].startDate));

            vm.genres.push({
              value: this.productions[i].genre,
              text: this.productions[i].genre,
            });
          }

          this.tableBenchmarkTotal.performancesCount = this.calculateArraySum(this.productions, 'performances');
          this.tableBenchmarkTotal.sold = this.calculateArraySum(this.productions, 'tickets');
          this.tableBenchmarkTotal.capacity = this.calculateArraySum(this.productions, 'capacity');

          this.tableBenchmarkTotal.purchases = this.calculateArraySum(this.productions, 'purchases');

          this.tableBenchmarkFiltered = this.productions;

          // Paging
          this.checkFilter({
            viewName: this.$options.name + '-paging',
            filterObject: this.tableBenchmarkPage,
          });
          this.tableBenchmarkPage = this.getFilter(this.$options.name + '-paging');

          this.filterProductions();
          this.tableBenchmarkLoading = false;
        })
        .catch((error) => {
          //eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterProductions: function () {
      const vm = this;

      if (this.tableBenchmarkFilter.genre == '' || this.tableBenchmarkFilter.genre == undefined) {
        this.tableBenchmarkFilterActive = false;
      } else {
        this.tableBenchmarkFilterActive = true;
      }

      this.tableBenchmarkFiltered = this.productions.filter(function (production) {
        return (
          cleanWords(production.title).indexOf(cleanWords(vm.tableBenchmarkFilter.productionName)) !== -1 &&
          (production.genre == vm.tableBenchmarkFilter.genre ||
            vm.tableBenchmarkFilter.genre == '' ||
            vm.tableBenchmarkFilter.genre == undefined)
        );
      });

      this.tableBenchmarkSelected.performancesCount = this.tableBenchmarkSelected.performancesCount =
        this.calculateArraySum(this.tableBenchmarkFiltered, 'performances');
      this.tableBenchmarkSelected.sold = this.calculateArraySum(this.tableBenchmarkFiltered, 'tickets');
      this.tableBenchmarkSelected.capacity = this.calculateArraySum(this.tableBenchmarkFiltered, 'capacity');
      this.tableBenchmarkSelected.purchases = this.calculateArraySum(this.tableBenchmarkFiltered, 'purchases');
      this.calculateArraySum(this.tableBenchmarkFiltered, 'performances');

      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterProductions: function () {
      this.tableBenchmarkFilter.genre = '';
      this.tableBenchmarkFilterActive = false;
      this.tableBenchmarkFilterDialog = false;
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
    ...mapGetters('storage', ['getFilter']),

    tableBenchmarkHeadersFiltered() {
      return this.tableBenchmarkHeaders.filter((h) => !h.showFor || h.showFor.includes(this.user.organisationTypeId));
    },
  },
  beforeDestroy() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tableBenchmarkFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tableBenchmarkPage,
    });
  },
  watch: {
    tableBenchmarkFilter: {
      handler: 'filterProductions',
      deep: true,
    },
    filterPeriod: function () {
      this.getProductions();
    },
  },
};
</script>
