import Vue from 'vue';
import router from './router/router.js';
import store from './store/index';
import ApiService from './services/api.service';
import { TokenService } from './services/storage.service';
import App from './App.vue';
import { UserService } from './services/user.service';
import vuetify from './plugins/vuetify';
import JsonExcel from 'vue-json-excel';
import VueExcelXlsx from 'vue-excel-xlsx';
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  ArcElement,
  CategoryScale,
  BarElement,
  Legend,
  Tooltip,
  TimeScale,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-luxon';
import * as Sentry from '@sentry/vue';
import { endpoint } from '@/env';

Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  ArcElement,
  CategoryScale,
  BarElement,
  Title,
  Legend,
  ChartDataLabels,
  Tooltip,
  TimeScale
);

Vue.config.productionTip = false;
Vue.component('downloadExcel', JsonExcel);
Vue.use(VueExcelXlsx);

// Set the base URL of the API
var currentLocation = window.location.host;

if (currentLocation.includes('localhost') || currentLocation.includes('dip-dashboard-tst.tc8l.dev')) {
  ApiService.init('https://api-mr-8.dip.nl/api');
} else if (currentLocation.includes('dip-test.innovatievooronderwijs.nl')) {
  ApiService.init('https://dip-internal-api-test.innovatievooronderwijs.nl/');
} else if (endpoint) {
  ApiService.init(endpoint);
} else if (currentLocation.includes('acc-dashboard.dip.nl')) {
  ApiService.init('https://acc-internal-api.dip.nl/');
} else if (currentLocation.includes('snd-dashboard.dip.nl')) {
  ApiService.init('https://snd-internal-api.dip.nl/');
} else if (currentLocation.includes('dashboard.dip.nl')) {
  ApiService.init('https://internal-api.dip.nl/');
}

// If token exists set header
if (TokenService.getToken()) {
  ApiService.setHeader();
  UserService.setNavigation();
} else {
  // hotjar
  // window.hj('identify', null, {});
}

Sentry.init({
  Vue,
  dsn: 'https://066baba5ded51dc59bb26c272202eccc@o4506348697812992.ingest.sentry.io/4506576158523392',
  integrations: [
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  render: (h) => h(App),
});
