<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex fill-height"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="primary--text font-weight-medium mb-0">leeftijdsopbouw</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>

        <v-row
          flex
          align="center"
          justify="center"
          class="py-2 flex-column flex-grow-1"
        >
          <template v-if="chart.loaded">
            <v-col
              cols="12"
              class="d-flex flex-column"
            >
              <doughnut-chart
                v-if="chart.loaded"
                :chart-data="chart.data"
                :chart-options="chart.options"
                :plugins="chart.plugins"
              ></doughnut-chart>

              <div class="widget-average d-flex flex-column align-center justify-center my-4">
                <span>gemiddelde leeftijd: {{ `${Math.round(widgetData.average)} jaar` }}</span>
                <span v-if="widgetData.averageComparison != null">
                  {{ `vergelijking: ${Math.round(widgetData.averageComparison)} jaar` }}
                </span>
              </div>
            </v-col>
          </template>

          <template v-else>
            <p class="primary--text font-weight-medium mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import Help from '../../Help.vue';
import DoughnutChart from '../../../plugins/chartjs/DoughnutChart.js';

export default {
  props: ['helpId', 'colsMobile', 'cols-Tablet', 'colsDesktop', 'widgetData', 'fillHeight'],
  components: {
    Help,
    DoughnutChart,
  },
  data() {
    return {
      chart: {
        loaded: false,
        data: {},
        options: {},
      },
    };
  },
  methods: {
    getData() {
      this.chart.loaded = false;

      const data = {
        ages: this.widgetData,
      };

      if (!data.ages.values.length > 0) {
        return;
      }

      var labels = data.ages.values.map(function (e) {
        return e.age;
      });

      var dataTickets = data.ages.values.map(function (e) {
        return parseFloat(e.rate).toFixed(2);
      });

      var dataPurchases = data.ages.values.map(function (e) {
        return e.purchases;
      });

      if (data.ages.comparison != null) {
        var dataTicketsCompared = data.ages.comparison.map(function (e) {
          return e.rate;
        });

        var dataPurchasesCompared = data.ages.comparison.map(function (e) {
          return e.purchases;
        });
      }

      var backgroundColorBundle = ['#fff453', '#0abfb8', '#021e73', '#D92853', '#782562', '#FF8F2E', '#EAEAEA'];

      var colorBundle = ['#021e73', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'];

      labels.forEach(function (element, index) {
        if (element == 'Onbekend') {
          backgroundColorBundle[index] = '#EAEAEA';
          colorBundle[index] = '#000';
        }
      });

      // Set chart options
      this.chart.options = {
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 10,
          },
        },
        plugins: {
          datalabels: {
            formatter: function (value) {
              if (value > 5) {
                return Math.round(value);
              } else {
                return '';
              }
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            textAlign: 'left',
            title: {
              display: true,
              color: '#021e72',
              font: {
                size: 14,
              },
              padding: 20,
            },
          },
        },
      };

      // Define datasets
      let datasets = [
        {
          data: dataTickets,
          dataPurchases: dataPurchases,
          backgroundColor: backgroundColorBundle,
          borderWidth: 3,
          weight: 3,
          datalabels: {
            color: colorBundle,
          },
          tooltip: {
            enabled: true,
            intersect: true,
            mode: 'index',
            position: 'nearest',
            callbacks: {
              label: function (tooltipItem) {
                var dataPercentage = tooltipItem.dataset.data[tooltipItem.dataIndex];
                var data = tooltipItem.dataset.dataPurchases[tooltipItem.dataIndex];

                var label = `${tooltipItem.label}: ${dataPercentage}% / ${data}`;

                return label;
              },
            },
          },
        },
      ];

      if (dataTicketsCompared) {
        const filteredDataTicketsCompared = dataTicketsCompared.filter((d) => d != null);
        if (filteredDataTicketsCompared.length > 0) {
          datasets.push({
            data: dataTicketsCompared,
            dataPurchasesCompared: dataPurchasesCompared,
            backgroundColor: backgroundColorBundle,
            borderWidth: 3,
            weight: 3,
            datalabels: {
              color: colorBundle,
            },
            tooltip: {
              enabled: true,
              intersect: true,
              mode: 'index',
              position: 'nearest',
              callbacks: {
                label: function (tooltipItem) {
                  var dataPercentage = tooltipItem.dataset.data[tooltipItem.dataIndex];
                  var data = tooltipItem.dataset.dataPurchasesCompared[tooltipItem.dataIndex];

                  var label = `${tooltipItem.label}: ${dataPercentage}% / ${data}`;

                  return label;
                },
              },
            },
          });
        }
      }

      // Set chart data
      this.chart.data = {
        labels: labels,
        datasets: datasets,
      };

      // Load chart
      this.chart.loaded = true;
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    widgetData() {
      this.getData();
    },
  },
};
</script>

<style scoped>
.fill-height {
  height: 100%;
}
</style>
