import { render, staticRenderFns } from "./Step5-Agreement.vue?vue&type=template&id=0170af64&scoped=true"
import script from "./Step5-Agreement.vue?vue&type=script&lang=js"
export * from "./Step5-Agreement.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0170af64",
  null
  
)

export default component.exports