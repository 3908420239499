var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"py-6 px-3 pb-0 mb-4",attrs:{"flat":""}},[_c('v-container',[_c('v-row',[(_vm.contract.providerContact && _vm.contract.providerContact.fullName != '')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"primary--text font-weight-medium mb-0"},[_vm._v("contactpersoon aanbieder")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.providerContact &&
              _vm.contract.previousApproved.providerContact.fullName != '' &&
              _vm.contract.previousApproved.providerContact.fullName != _vm.contract.providerContact.fullName
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{staticClass:"primary--text mb-0 pr-2"},[_vm._v(_vm._s(_vm.contract.previousApproved.providerContact.fullName))]),_c('h3',{staticClass:"error--text pb-4 border-bottom"},[_vm._v(_vm._s(_vm.contract.providerContact.fullName))])]):_c('h3',{staticClass:"primary--text border-bottom pb-4 mb-4"},[_vm._v(" "+_vm._s(_vm.contract.providerContact.fullName)+" ")])]):_vm._e(),(_vm.contract.providerContact && _vm.contract.providerContact.email != '')?_c('v-col',{staticClass:"py-0 mb-8",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"primary--text font-weight-medium mb-0"},[_vm._v("e-mailadres aanbieder")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.providerContact &&
              _vm.contract.previousApproved.providerContact.email != '' &&
              _vm.contract.previousApproved.providerContact.email != _vm.contract.providerContact.email
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{staticClass:"primary--text mb-0 pr-2"},[_vm._v(_vm._s(_vm.contract.previousApproved.providerContact.email))]),_c('h3',{staticClass:"error--text pb-4 border-bottom"},[_vm._v(_vm._s(_vm.contract.providerContact.email))])]):_c('h3',{staticClass:"primary--text border-bottom pb-4 mb-0"},[_vm._v(" "+_vm._s(_vm.contract.providerContact.email)+" ")])]):(_vm.contract.providerContactEmail != '')?_c('v-col',{staticClass:"py-0 mb-8",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"primary--text font-weight-medium mb-0"},[_vm._v("e-mailadres aanbieder")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.providerContactEmail &&
              _vm.contract.previousApproved.providerContactEmail != '' &&
              _vm.contract.previousApproved.providerContactEmail != _vm.contract.providerContactEmail
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{staticClass:"primary--text mb-0 pr-2"},[_vm._v(_vm._s(_vm.contract.previousApproved.providerContactEmail))]),_c('h3',{staticClass:"error--text pb-4 border-bottom"},[_vm._v(_vm._s(_vm.contract.providerContactEmail))])]):_c('h3',{staticClass:"primary--text border-bottom pb-4 mb-0"},[_vm._v(" "+_vm._s(_vm.contract.providerContactEmail)+" ")])]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }