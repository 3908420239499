<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="48"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <!-- Production details -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <h1 class="primary--text mb-5">
                    {{ this.production.title }}
                  </h1>
                  <h4 class="primary--text mb-2 font-weight-regular">productiegegevens</h4>

                  <v-card
                    class="pa-6 mb-1"
                    flat
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>titel: </small><br /><strong> {{ production.title }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <div>
                      <hr class="accent-hr my-2" />
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <p class="primary--text mb-0">
                            <small>producent: </small><br /><strong> {{ production.producerName }}</strong>
                          </p>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="this.user.organisationTypeId !== 1">
                      <hr class="accent-hr my-2" />
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <p class="primary--text mb-0">
                            <small>impresariaat: </small><br /><strong> {{ production.agency }}</strong>
                          </p>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="production.coProducers">
                      <hr class="accent-hr my-2" />
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <p class="primary--text mb-0">
                            <small>co-producent(en): </small><br /><strong> {{ production.coProducers }}</strong>
                          </p>
                        </v-col>
                      </v-row>
                    </div>
                    <hr class="accent-hr my-2" />
                    <v-row
                      no-gutters
                      v-if="production.isReprise"
                    >
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p
                          v-if="production.isReprise == 'normal'"
                          class="primary--text mb-0"
                        >
                          <small>nieuw / reprise: </small><br /><strong> nieuwe productie</strong>
                        </p>
                        <p
                          v-else
                          class="primary--text mb-0"
                        >
                          <small>nieuw / reprise: </small><br /><strong>reprise / herneming </strong>
                        </p>
                      </v-col>
                    </v-row>
                    <div v-if="production.isReprise == 'reprise' && production.repriseTitle">
                      <hr class="accent-hr my-2" />
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <p class="primary--text mb-0">
                            <small>oorspronkelijke productie: </small><br />
                            <router-link
                              :to="{
                                name: 'LibraryProductionsDetail',
                                params: {
                                  productionNumber: production.repriseNumber,
                                },
                              }"
                            >
                              <strong>
                                {{ `${production.repriseTitle} (${production.repriseNumber})` }}
                              </strong>
                            </router-link>
                          </p>
                        </v-col>
                      </v-row>
                    </div>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p
                          v-if="production.productionType == 'co_production'"
                          class="primary--text mb-0"
                        >
                          <small>eigenaar: </small><br /><strong>coproductie</strong>
                        </p>
                        <p
                          v-if="production.productionType == 'own_production'"
                          class="primary--text mb-0"
                        >
                          <small>eigenaar: </small><br /><strong>eigen productie</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>synopsis: </small><br /><strong
                            ><span v-if="production.synopsis === ''">-</span
                            ><span v-else>{{ production.synopsis }}</span></strong
                          >
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>aantal muzikanten: </small><br /><strong> {{ production.musicianCount }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>begeleidende producent: </small><br /><strong>
                            {{ production.accompanyingProducers }}</strong
                          >
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>genre: </small><br /><strong>
                            {{ production.genre }}
                          </strong>
                        </p>
                      </v-col>
                    </v-row>
                    <template v-if="subventionRights.view && production.subventions.length > 0">
                      <hr class="accent-hr my-2" />
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <p class="primary--text mb-0">
                            <small>subsidies: </small>
                            <strong
                              v-for="subvention in production.subventions"
                              :key="subvention.id"
                            >
                              <br />{{ subvention.text }}
                            </strong>
                          </p>
                        </v-col>
                      </v-row>
                      <hr class="accent-hr my-2" />
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <p class="primary--text mb-0">
                            <small>toestemming date gebruiken voor subsidieverantwoording: </small>
                            <strong><br />{{ production.hasTransferPermission }}</strong>
                          </p>
                        </v-col>
                      </v-row>
                    </template>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>start datum: </small><br /><strong>
                            {{ production.startDate }}
                          </strong>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                  <div>
                    <p class="grey--text text--lighten-1 mt-0">
                      <small
                        ><i>{{ this.production.lastEditedBy }}</i></small
                      >
                    </p>
                    <!-- Bewerken -->
                    <v-btn
                      v-if="
                        rights.edit &&
                        (user.organisationTypeId == 1 || user.organisationTypeId == 3) &&
                        production.isOwn
                      "
                      rounded
                      large
                      outlined
                      elevation="0"
                      color="primary"
                      class="text-lowercase mb-5 mr-5 align-self-start"
                      :to="{ name: 'LibraryProductionsEdit' }"
                      >Gegevens Bewerken
                      <v-icon
                        small
                        right
                        >mdi-square-edit-outline</v-icon
                      ></v-btn
                    >
                  </div>
                </v-col>
              </v-row>

              <!-- Production downloads overview -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="10"
                  sm="10"
                >
                  <v-row class="pa-0 ma-0 d-flex flex-row align-center justify-space-between">
                    <div>
                      <h4 class="primary--text font-weight-regular">documenten</h4>
                    </div>

                    <v-btn
                      v-if="rights.edit && (user.organisationTypeId == 1 || user.organisationTypeId == 3)"
                      rounded
                      small
                      elevation="0"
                      color="primary"
                      class="text-lowercase"
                      @click="uploadFileDialog = true"
                      >documenten toevoegen</v-btn
                    >
                  </v-row>
                </v-col>
              </v-row>

              <v-form
                @submit.prevent
                v-model="productionFileFormDownloadValid"
                class="d-flex flex-column col col-12"
                ref="formDownload"
              >
                <v-row class="d-flex justify-center align-center col col-12">
                  <v-col
                    cols="10"
                    class="d-flex flex-wrap py-0 px-0"
                  >
                    <v-col
                      cols="12"
                      md="6"
                      sm="6"
                      v-for="file in production.files"
                      :key="file.id"
                    >
                      <v-card
                        class="px-4 py-0 mb-1"
                        flat
                      >
                        <v-container class="d-flex flex-column">
                          <v-card-title> {{ file.type }}</v-card-title>
                          <v-card-subtitle>
                            {{ file.name }}
                          </v-card-subtitle>

                          <v-card-actions class="d-flex align-center justify-end">
                            <v-btn
                              rounded
                              large
                              elevation="0"
                              @click="downloadFile(file)"
                              color="secondary"
                              class="text-lowercase align-self-start"
                              >Download
                              <v-icon
                                small
                                right
                                >mdi-download</v-icon
                              ></v-btn
                            >

                            <v-btn
                              v-if="rights.edit && (user.organisationTypeId == 1 || user.organisationTypeId == 3)"
                              rounded
                              large
                              elevation="0"
                              @click="(deleteItem = file), (deleteFileDialog = true);"
                              color="error"
                              class="text-lowercase align-self-start"
                              >verwijderen
                              <v-icon
                                small
                                right
                                >mdi-delete</v-icon
                              ></v-btn
                            >
                          </v-card-actions>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-col>
                </v-row>
              </v-form>

              <!-- Production Performances overview -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="10"
                  sm="10"
                >
                  <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
                    <div>
                      <h4 class="primary--text font-weight-regular">voorstellingen</h4>
                    </div>
                    <div>
                      <v-btn
                        v-if="
                          rights.edit &&
                          (user.organisationTypeId == 1 || user.organisationTypeId == 3) &&
                          production.isOwn
                        "
                        rounded
                        small
                        elevation="0"
                        color="primary"
                        class="text-lowercase mr-2"
                        :to="{ name: 'LibraryProductionsPerformanceAdd' }"
                        >voorstelling toevoegen</v-btn
                      >
                      <v-btn
                        v-if="
                          rights.edit &&
                          (user.organisationTypeId == 1 || user.organisationTypeId == 3) &&
                          production.isOwn
                        "
                        rounded
                        small
                        elevation="0"
                        color="primary"
                        class="text-lowercase"
                        :to="{ name: 'LibraryProductionsPerformanceAddBulk' }"
                        >Bulk voorstellingen toevoegen</v-btn
                      >
                    </div>
                  </v-row>

                  <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center">
                    <v-switch
                      class="my-0 no-messages"
                      v-model="performancesOnlyWhitoutDate"
                      @change="filterPerformances()"
                      inset
                      label="Toon alleen voorstellingen zonder datum"
                    ></v-switch>
                  </v-row>

                  <!-- Table -->
                  <v-data-table
                    :headers="tableProductionPerformancesHeaders"
                    :items="tableProductionPerformancesFiltered"
                    :mobile-breakpoint="0"
                    :loading="tableProductionPerformancesLoading"
                    :items-per-page="-1"
                    hide-default-footer
                    class="table-action"
                  >
                    <template v-slot:[`item.startDate`]="{ item }">
                      <span>{{ item.startDateDate_formatted }}</span>
                    </template>

                    <template v-slot:[`item.capacity`]="{ item }">
                      <span>{{ formatThousandsWithDot(item.capacity) }}</span>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                      <v-btn
                        v-if="
                          rights.edit &&
                          (user.organisationTypeId == 1 || user.organisationTypeId == 3) &&
                          production.isOwn
                        "
                        fab
                        x-small
                        depressed
                        color="info"
                        class="ml-1"
                        :to="{
                          name: 'LibraryProductionsPerformanceEdit',
                          params: { performanceId: item.id },
                        }"
                      >
                        <v-icon color="primary">mdi-square-edit-outline</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="performanceCapacityRights.edit"
                        fab
                        x-small
                        depressed
                        color="info"
                        class="ml-1"
                        :to="{
                          name: 'LibraryProductionsPerformanceCapacity',
                          params: { performanceId: item.id },
                        }"
                      >
                        <v-icon color="primary">mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>

                    <v-data-footer></v-data-footer>
                  </v-data-table>
                </v-col>

                <!-- Table downlaod -->
                <v-col
                  cols="12"
                  md="10"
                  sm="10"
                  class="d-flex justify-end"
                >
                  <v-btn
                    color="primary"
                    rounded
                    depressed
                    class="font-weight-bold text-lowercase"
                  >
                    <vue-excel-xlsx
                      class="v-btn font-weight-bold text-lowercase"
                      :data="tableProductionPerformancesFiltered"
                      :columns="downloadExportColumns"
                      :filename="'dip-vrst-export'"
                      :sheetname="'Voorstellingen'"
                      >exporteer <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Upload dialog starts here -->
    <v-dialog
      v-model="uploadFileDialog"
      width="500"
    >
      <v-card
        class="pa-5"
        flat
      >
        <v-form
          @submit.prevent
          v-model="productionFileFormUploadValid"
          class="d-flex flex-column col col-12"
          ref="formUpload"
        >
          <v-card-title class="px-0 mb-2">Upload hier je documenten</v-card-title>
          <div class="d-flex align-center">
            <v-col
              cols="12"
              sm="6"
              md="8"
              class="py-0 pr-2 pl-0"
            >
              <v-file-input
                label="bestand"
                truncate-length="15"
                accept="application/pdf"
                outlined
                dense
                v-model="productionFileFormUpload.file"
                prepend-icon=""
                :rules="formValidationRules.required"
              ></v-file-input>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="pa-0"
            >
              <v-autocomplete
                label="type document"
                v-model="productionFileFormUpload.type"
                :items="documentUploadOptions"
                :rules="formValidationRules.required"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </div>
          <v-btn
            rounded
            large
            elevation="0"
            @click="uploadFile"
            :loading="productionFileFormUploadLoading"
            color="secondary"
            class="text-lowercase mt-2 mb-2 align-self-start"
            >document toevoegen</v-btn
          >
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteFileDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline"
          >Weet je zeker dat je het bestand {{ this.deleteItem.name }} wilt verwijderen?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(deleteFileDialog = false), (deleteItem = '');"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteFile(deleteItem)"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';

export default {
  name: 'LibraryProductionsDetail',
  props: ['productionNumber'],
  components: {
    Help,
  },
  data() {
    return {
      production: {
        name: '',
        title: '',
        synopsis: '',
        genre: '',
        type: '',
        startDate: '',
        lastEditedBy: '',
        agency: '',
        subventions: [],
        hasTransferPermission: 'nee',
        files: [],
      },
      rights: {
        edit: false,
        approve: false,
      },
      subventionRights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '/beheer/producties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      productionFileFormUploadValid: false,
      productionFileFormUploadLoading: false,
      productionFileFormDownloadValid: false,
      productionFileFormDownloadLoading: false,
      productionFileFormDownload: {
        ProductionNumber: '',
        FileId: 0,
      },
      productionFileFormUpload: {
        file: null,
        type: '',
      },
      documentUploadOptions: [
        { text: 'technische lijst', value: 'technische lijst' },
        { text: 'risicoinventarisatie', value: 'risicoinventarisatie' },
        { text: 'anders', value: 'anders' },
      ],
      formValidationRules: {},
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      performancesOnlyWhitoutDate: false,
      tableProductionPerformancesHeaders: [
        { text: 'nummer', value: 'number' },
        { text: 'dag', value: 'startDate' },
        { text: 'tijd', value: 'startDateTime' },
        { text: 'status', value: 'state' },
        { text: 'type', value: 'type' },
        { text: 'theater', value: 'theater' },
        { text: 'zaal', value: 'area' },
        { text: 'capaciteit', value: 'capacity' },
        { text: '', value: 'action', sortable: false, align: 'end' },
      ],
      tableProductionPerformances: [],
      tableProductionPerformancesFiltered: [],
      tableProductionPerformancesLoading: true,
      downloadExportColumns: [
        { label: 'nummer', field: 'number' },
        { label: 'datum', field: 'startDateDate' },
        { label: 'tijd', field: 'startDateTime' },
        { label: 'status', field: 'state' },
        { label: 'type', field: 'type' },
        { label: 'theater', field: 'theater' },
        { label: 'zaal', field: 'area' },
        { label: 'capaciteit', field: 'capacity' },
      ],
      uploadFileDialog: false,
      deleteFileDialog: false,
      deleteItem: '',
    };
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryProductionsDetail');
    this.performanceCapacityRights = this.getComponentRights('LibraryProductionsPerformanceCapacity');
    this.subventionRights = this.getComponentRights('Subventions');

    this.formValidationRules = functions.getFormValidationRules();
    this.getProduction();
    this.getProductionPerformances();
  },
  beforeRouteUpdate: function (to, _, next) {
    // get productionNumber from To router object, because of re-render trigger
    this.getProduction(to.params.productionNumber);
    next();
  },
  methods: {
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getProduction: function (productionNumber) {
      ApiService.get('/Productions/Get', {
        productionNumber: productionNumber ? productionNumber : this.productionNumber,
      })
        .then((response) => {
          if (response.data.success) {
            const production = response.data.result;

            this.breadcrumbs[2].text = production.title;
            this.production.name = production.name;
            this.production.title = production.title;
            this.production.producerName = production.producer.name;
            this.production.isReprise = production.isReprise;
            this.production.productionType = production.productionType;
            this.production.pnlId = production.producer.pnlId;
            this.production.synopsis = production.synopsis;
            this.production.musicianCount = production.musicianCount;
            this.production.genre = production.genre.name;
            this.production.startDate = functions.getDateView(production.startDateTime);
            this.production.lastEditedBy = production.lastEditedBy;
            this.production.agency = production.agency ? production.agency.name : '-';
            this.production.hasTransferPermission = production.hasTransferPermission ? 'ja' : 'nee';
            this.production.coProducers = production.coProducers.map((p) => p.name).join(', ');
            this.production.accompanyingProducers = production.accompanyingProducers.map((p) => p.name).join(', ');
            this.production.isOwn = production.isOwn;

            if (production.reprise != null) {
              this.production.repriseTitle = production.reprise.title;
              this.production.repriseNumber = production.reprise.number;
            }

            for (let i = 0; i < production.subventions.length; i++) {
              this.production.subventions.push({
                id: production.subventions[i].id,
                text:
                  functions.getSubventionTypeView(production.subventions[i].type) +
                  ' ' +
                  production.subventions[i].organisation +
                  ' (' +
                  functions.getDateView(production.subventions[i].startDate) +
                  ' t/m ' +
                  functions.getDateView(production.subventions[i].endDate) +
                  ')',
              });
            }

            for (let j = 0; j < production.files.length; j++) {
              this.production.files.push({
                id: production.files[j].id,
                name: production.files[j].name,
                type: production.files[j].type,
              });
            }
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getProductionPerformances: function () {
      const vm = this;

      ApiService.get('/Productions/GetPerformances', {
        productionNumber: this.productionNumber,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableProductionPerformances = response.data.result;

            for (var j = 0; j < this.tableProductionPerformances.length; j++) {
              vm.$set(
                this.tableProductionPerformances[j],
                'startDateDate_formatted',
                functions.getDateView(this.tableProductionPerformances[j].startDate)
              );
              vm.$set(
                this.tableProductionPerformances[j],
                'startDateTime',
                functions.getTimeView(this.tableProductionPerformances[j].startDate)
              );
              vm.$set(
                this.tableProductionPerformances[j],
                'startDateDate',
                functions.getDateView(this.tableProductionPerformances[j].startDate)
              );
              vm.$set(
                this.tableProductionPerformances[j],
                'state',
                functions.changeState(this.tableProductionPerformances[j].state)
              );
              vm.$set(
                this.tableProductionPerformances[j],
                'type',
                functions.changeType(this.tableProductionPerformances[j].type)
              );
            }

            this.tableProductionPerformancesFiltered = this.tableProductionPerformances;
            this.tableProductionPerformancesLoading = false;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    filterPerformances: function () {
      if (this.performancesOnlyWhitoutDate) {
        this.tableProductionPerformancesFiltered = this.tableProductionPerformances.filter(function (production) {
          return production.startDateDate_formatted == '';
        });
      } else {
        this.tableProductionPerformancesFiltered = this.tableProductionPerformances;
      }
    },
    downloadFile(file) {
      if (this.productionFileFormDownloadValid) {
        ApiService.getFile('/Productions/GetFile', {
          ProductionNumber: this.productionNumber,
          FileId: file.id,
        })
          .then((response) => {
            var contentType = response.headers['content-type'];
            var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.download = file.name;
            fileLink.click();
            URL.revokeObjectURL(fileLink.href);
          })
          // eslint-disable-next-line
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.ticketSyncFormDownloadLoading = false;
        this.$refs.formDownload.validate();
      }
    },
    uploadFile() {
      this.productionFileFormUploadLoading = true;
      if (this.productionFileFormUploadValid) {
        const form = this.productionFileFormUpload;
        const formData = new FormData();
        formData.append('file', form.file);

        ApiService.postFile('/Productions/AddFile', formData, {
          params: {
            ProductionNumber: this.productionNumber,
            Type: form.type,
          },
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.productionFileFormUploadLoading = false;
              this.uploadFileDialog = false;
              this.production.files = [];
              this.getProduction();
              this.productionFileFormUpload.file = null;
              this.$refs.formUpload.reset();
            } else {
              this.productionFileFormUploadLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch((error) => {
            this.productionFileFormUploadLoading = false;
          });
      } else {
        this.productionFileFormUploadLoading = false;
        this.$refs.formUpload.validate();
      }
    },
    deleteFile(file) {
      if (this.productionFileFormDownloadValid) {
        ApiService.delete('/Productions/DeleteFile', {
          ProductionNumber: this.productionNumber,
          FileId: file.id,
        })
          .then((response) => {
            if (response.data.success) {
              this.production.files = [];
              this.getProduction();
              this.deleteFileDialog = false;
            }
          })
          // eslint-disable-next-line
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$refs.formDownload.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}

.no-messages .v-messages {
  display: none;
}
</style>
