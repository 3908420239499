import ApiService from './api.service';
import { TokenService } from './storage.service';
import router, { resetRouter } from '../router/router.js';
import { viewService } from './view.service';

import store from '../store';

const UserService = {
  login: async function (email, password) {
    const requestData = {
      method: 'post',
      url: '/Account/Login',
      data: {
        email: email,
        password: password,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);

      if (response.data.success != true) {
        const error = { errorCode: response.data.statusCode, errorMessage: response.data.result.message };
        return error;
      } else {
        TokenService.saveToken(response.data.result.token);
        ApiService.setHeader();
        
        ApiService.mount401Interceptor();
        
        if (!response.data.result.twoFactorEnabled) {
          await UserService.setNavigation();
        } else {
          store.dispatch('auth/fetchUser', { twoFactorEnabled: true });
        }
      }

      return response.data.result.token;
    } catch (error) {
      return {
        errorCode: '500',
        errorMessage:
          'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.',
      };
    }
  },
  logout() {
    ApiService.removeHeader();
    ApiService.unmount401Interceptor();
    UserService.clearNavigation();

    return TokenService.removeToken();
  },
  twoFactorAuth: async function (authCode) {
    const requestData = {
      method: 'post',
      url: '/Account/ValidateTwoFactorAuthentication',
      data: {
        CustomerKey: 'two-factor',
        ValidationKey: authCode,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);

      if (response.data.success != true) {
        const error = { errorCode: response.data.statusCode, errorMessage: response.data.result.message };
        return error;
      } else {
        TokenService.saveToken(response.data.result.token);
        ApiService.setHeader();

        ApiService.mount401Interceptor();
        await UserService.setNavigation();
      }

      return response.data.result.token;
    } catch (error) {
      return {
        errorCode: '500',
        errorMessage:
          'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.',
      };
    }
  },
  switchRole: async function (userId, userRoleId, organisationId, organisationTypeId) {
    ApiService.post('/Account/SetRole', {
      userId: userId,
      userRoleId: userRoleId,
      organisationId: organisationId,
      organisationTypeId: organisationTypeId,
    })
      .then((response) => {
        TokenService.saveToken(response.data.token);
        ApiService.setHeader();

        ApiService.mount401Interceptor();

        UserService.clearNavigation();
        UserService.setNavigation();

        var redirect = setInterval(function () {
          if (store.state.auth.user.agreedToTerms != undefined) {
            clearInterval(redirect);

            if (!store.state.auth.user.agreedToTerms) {
              router.push('/accepteer-voorwaarden');
            } else if (userRoleId == 0 && organisationId == 0 && organisationTypeId == 0) {
              router.push('/');
            } else {
              router.push('/dashboard/');
            }

            store.dispatch('auth/fetchAuthenticating');
          }
        }, 100);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        router.back();
      });
  },
  async setNavigation() {
    store.dispatch('auth/fetchNavigationStatus');

    ApiService.get('/Account/GetNavigation', {
      status: store.getters['auth/getNavigationStatus'],
    })
      .then((response) => {
        return response.data.result;
      })
      .then((json) => {
        addRoutes(json.navigation);
        store.dispatch('auth/fetchNavigation', json.navigation);
        store.dispatch('auth/fetchUser', json.user);

        if (!json.user.agreedToTerms) {
          router.addRoute({
            path: '/accepteer-voorwaarden',
            component: viewService.getView('AcceptTerms'),
            name: 'AcceptTerms',
            props: false,
          });
          router.push('/accepteer-voorwaarden');
        }

        router.addRoute({
          path: '*',
          component: viewService.getView('PageNotFound'),
          name: 'PageNotFound',
          props: false,
        });
      })
      .catch((error) => {
        store.dispatch('auth/logout');
        // eslint-disable-next-line
        console.log(error);
        this.errored = true;
      });
  },
  clearNavigation() {
    resetRouter();
  },
};

function addRoutes(json) {
  for (var i = 0; i < json.length; i++) {
    if (json[i].router.component != '') {
      var newRoute = {
        path: json[i].router.path,
        component: viewService.getView(json[i].router.component),
        name: json[i].router.name,
        props: json[i].router.props,
      };
      router.addRoute(newRoute);
    }
    if (json[i].children) {
      addRoutes(json[i].children);
    }
  }
}

export default UserService;

export { UserService };
