<template>
  <div>
    <h2 class="primary--text mb-2">welk type afspraak wil je maken?</h2>

    <v-card
      flat
      @click="changeType('Huur')"
      class="mb-3"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="d-flex pa-0 align-center justify-space-between">
              <h3 class="primary--text">huur</h3>
              <v-icon
                large
                color="primary"
                >mdi-chevron-right</v-icon
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card
      flat
      @click="changeType('Partage')"
      class="mb-3"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="d-flex pa-0 align-center justify-space-between">
              <h3 class="primary--text">partage</h3>
              <v-icon
                large
                color="primary"
                >mdi-chevron-right</v-icon
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card
      flat
      @click="changeType('Suppletie')"
      class="mb-3"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="d-flex pa-0 align-center justify-space-between">
              <h3 class="primary--text">suppletie</h3>
              <v-icon
                large
                color="primary"
                >mdi-chevron-right</v-icon
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card
      flat
      @click="changeType('Staffel')"
      class="mb-3"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="d-flex pa-0 align-center justify-space-between">
              <h3 class="primary--text">staffel</h3>
              <v-icon
                large
                color="primary"
                >mdi-chevron-right</v-icon
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card
      flat
      @click="changeType('Uitkoop')"
      class="mb-3"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="d-flex pa-0 align-center justify-space-between">
              <h3 class="primary--text">uitkoop</h3>
              <v-icon
                large
                color="primary"
                >mdi-chevron-right</v-icon
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
//import ApiService from "../../../services/api.service";
//import { TokenService } from "../../../services/storage.service";
//import functions from '../../../services/functions.service';

export default {
  props: ['form', 'steps', 'step'],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    changeType(type) {
      this.form.type = type;
      this.steps[this.step].active = false;
      this.steps[this.step].done = true;
      this.steps[this.step].valid = true;

      this.steps[this.step + 1].active = true;
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('form', this.form);
      },
    },
    steps: {
      deep: true,
      handler() {
        this.$emit('steps', this.steps);
      },
    },
  },
};
</script>

<style scoped></style>
