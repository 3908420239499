<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="flex align-center pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="43"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-col
                cols="12"
                md="6"
                sm="10"
              >
                <!-- Form -->
                <v-form
                  @submit.prevent
                  v-model="formValid"
                  class="d-flex flex-column"
                  ref="form"
                >
                  <h4 class="primary--text mb-2 font-weight-regular">gegevens gebruiker</h4>

                  <v-card
                    class="pa-6 mb-1"
                    flat
                  >
                    <v-row>
                      <!-- Voornaam -->
                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-text-field
                          class="primary--text"
                          label="voornaam"
                          v-model="addUserForm.firstName"
                          :rules="formValidationRules.required"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <!-- Achternaam -->
                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-text-field
                          class="primary--text"
                          label="achternaam"
                          v-model="addUserForm.lastName"
                          :rules="formValidationRules.required"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Email -->
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-text-field
                          class="primary--text"
                          label="e-mailadres"
                          v-model="addUserForm.email"
                          :rules="formValidationRules.email"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Userrol -->
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-autocomplete
                          label="gebruikersrol"
                          v-model="addUserForm.roleId"
                          :items="userRoleOptions"
                          :rules="formValidationRules.required"
                          v-on:change="getRights()"
                          required
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-alert
                      text
                      type="error"
                      v-if="formError != ''"
                      >{{ formError }}</v-alert
                    >
                  </v-card>

                  <div v-if="addUserForm.roleId != ''">
                    <h4 class="primary--text mt-8 mb-5">rechten gebruiker</h4>
                    <!-- Table -->
                    <v-data-table
                      :headers="tableRightsHeaders"
                      :items="addUserForm.sitemap"
                      :mobile-breakpoint="0"
                      :items-per-page="-1"
                      hide-default-footer
                    >
                      <template v-slot:[`item.rights.view`]="{ item }">
                        <v-checkbox
                          v-model="item.rights.view"
                          v-if="item.rights.view != null"
                          :input-value="item.rights.view"
                          value
                          v-on:change="changeRight(item, item.rights.view, 'view')"
                          :disabled="addUserForm.roleIsAdmin"
                        ></v-checkbox>
                        <div v-else>-</div>
                      </template>
                      <template v-slot:[`item.rights.edit`]="{ item }">
                        <v-checkbox
                          v-model="item.rights.edit"
                          v-if="item.rights.edit != null"
                          :input-value="item.rights.edit"
                          v-on:change="changeRight(item, item.rights.edit, 'edit')"
                          value
                          :disabled="addUserForm.roleIsAdmin"
                        ></v-checkbox>
                        <div v-else>-</div>
                      </template>
                      <template v-slot:[`item.rights.approve`]="{ item }">
                        <v-checkbox
                          v-model="item.rights.approve"
                          v-if="item.rights.approve != null"
                          :input-value="item.rights.approve"
                          v-on:change="changeRight(item, item.rights.approve, 'approve')"
                          value
                          :disabled="addUserForm.roleIsAdmin"
                        ></v-checkbox>
                        <div v-else>-</div>
                      </template>

                      <v-data-footer></v-data-footer>
                    </v-data-table>
                  </div>
                  <v-btn
                    rounded
                    large
                    elevation="0"
                    @click="submit"
                    :loading="formLoading"
                    color="secondary"
                    class="text-lowercase mb-5 mt-3 align-self-start"
                    >Opslaan
                    <v-icon
                      small
                      right
                      >mdi-content-save</v-icon
                    ></v-btn
                  >
                </v-form>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogMessage"
      max-width="700px"
    >
      <v-card>
        <v-btn
          color="#fff"
          class="mt-8"
          top
          right
          absolute
          fab
          x-small
          depressed
          light
          @click="closeDialogMessage()"
        >
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="primary--text">{{ dialogMessageTitle }}</v-card-title>
        <v-card-text class="primary--text caption">{{ dialogMessageText }}</v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';

export default {
  name: 'LibraryUsersAdd',
  components: {
    Help,
  },
  data() {
    return {
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      addUserForm: {
        firstName: '',
        lastName: '',
        email: '',
        roleId: '',
        sitemap: [],
        roleIsAdmin: false,
      },
      tableRightsHeaders: [
        { text: 'module', value: 'name', sortable: false },
        { text: 'inzage', value: 'rights.view' },
        { text: 'beheer', value: 'rights.edit' },
        { text: 'accorderen', value: 'rights.approve' },
      ],
      userRoleOptions: [],
      formValidationRules: {},
      rights: {
        edit: false,
        approve: false,
      },
      dialogMessage: false,
      dialogMessageTitle: '',
      dialogMessageText: '',
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruikers',
          disabled: false,
          href: '/beheer/gebruikers',
          bold: false,
        },
        {
          text: 'toevoegen',
          disabled: false,
          href: '/beheer/gebruikers/add',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryUsersAdd');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.getUserRoles();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    getUserRoles: function () {
      ApiService.get('/UserRoles/GetAll', {
        organisationTypeId: this.user.organisationTypeId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.userRoleOptions.push({ value: element.id, text: element.name, isAdmin: element.isAdmin });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getRights: function () {
      ApiService.get('/Rights/GetPerOrganisation', {
        userRoleId: this.addUserForm.roleId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          var roleIsAdmin = this.userRoleOptions.filter((role) => {
            if (role.value == this.addUserForm.roleId) {
              return role;
            }
          });
          this.addUserForm.roleIsAdmin = roleIsAdmin[0].isAdmin;

          this.addUserForm.sitemap = response.data.result.sitemap;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    changeRight(module, value, type) {
      if (type == 'view') {
        module.rights.edit = module.rights.edit == null ? null : false;
        module.rights.approve = module.rights.approve == null ? null : false;
      } else if (type == 'edit') {
        if (value) {
          module.rights.view = module.rights.view == null ? null : true;
          module.rights.approve = module.rights.approve == null ? null : false;
        } else {
          module.rights.approve = module.rights.approve == null ? null : false;
        }
      } else if (type == 'approve' && value) {
        module.rights.view = module.rights.view == null ? null : true;
        module.rights.edit = module.rights.edit == null ? null : true;
      }
    },
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.addUserForm;

        ApiService.post('/Users/AddPerOrganisation', {
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          userRoleId: form.roleId,
          sitemap: form.sitemap,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              if (response.data.result.userAlreadyExists) {
                this.dialogMessageTitle = 'De koppeling is toegevoegd.';
                this.dialogMessageText =
                  'Deze gebruiker is reeds gekoppeld aan een andere organisatie, daarom zijn de overige gegevens overgenomen van de bestaande gebruiker.';
                this.dialogMessage = true;
                this.isEditing = false;
              } else {
                this.isEditing = false;
                router.back();
              }
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch((error) => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
    closeDialogMessage() {
      router.back();
    },
  },
  watch: {
    dialogMessage(val) {
      !val && this.closeDialogMessage();
    },
    addUserForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
