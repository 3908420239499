<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="flex align-center pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="6"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-col
                cols="12"
                md="6"
                sm="10"
              >
                <!-- Form -->
                <v-form
                  @submit.prevent
                  v-model="formValid"
                  class="d-flex flex-column"
                  ref="form"
                >
                  <h1 class="primary--text mb-5">{{ this.user.firstName }} {{ this.user.lastName }}</h1>
                  <h4 class="primary--text mb-2 font-weight-regular">koppeling toevoegen</h4>

                  <v-card
                    class="pa-6 mb-10"
                    flat
                  >
                    <v-row>
                      <!-- Type organisatie -->
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-autocomplete
                          label="type organisatie"
                          v-on:change="changeOrganisationType"
                          v-model="addUserLinkForm.organisationType"
                          :items="organisationTypeOptions"
                          :rules="formValidationRules.required"
                          required
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Organisatie -->
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-autocomplete
                          label="organisatie"
                          v-model="addUserLinkForm.organisationId"
                          :items="organisationOptions"
                          :rules="formValidationRules.required"
                          required
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Userrol -->
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-autocomplete
                          label="gebruikersrol"
                          v-model="addUserLinkForm.roleId"
                          :items="userRoleOptions"
                          :rules="formValidationRules.required"
                          required
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-alert
                    v-if="formError != ''"
                    text
                    color="error"
                    >{{ formError }}</v-alert
                  >

                  <v-btn
                    rounded
                    large
                    elevation="0"
                    @click="submit"
                    :loading="formLoading"
                    color="secondary"
                    class="text-lowercase mb-5 align-self-start"
                    >Opslaan
                    <v-icon
                      small
                      right
                      >mdi-content-save</v-icon
                    ></v-btn
                  >
                </v-form>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';

export default {
  name: 'AdminUsersLinkAdd',
  props: ['userId'],
  components: {
    Help,
  },
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
      },
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      addUserLinkForm: {
        organisationType: '',
        organisationId: '',
        roleId: '',
      },
      organisationTypeOptions: [],
      organisationOptions: [],
      userRoleOptions: [],
      theaters: [],
      impresariaten: [],
      producenten: [],
      formValidationRules: {},
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruikers',
          disabled: false,
          href: '/admin/gebruikers',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'toevoegen koppeling',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getUser();
    this.getOrganisations();
    this.getOrganisationTypes();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getUser: function () {
      ApiService.get('/Users/Get', {
        userId: this.userId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const user = response.data.result;

            this.user.firstName = user.firstName != null ? user.firstName : '';
            this.user.lastName = user.lastName != null ? user.lastName : '';

            this.breadcrumbs[2].text = this.user.firstName + ' ' + this.user.lastName;
            this.breadcrumbs[2].href = '/admin/gebruikers/' + this.userId + '/';
            this.breadcrumbs[3].href = '/admin/gebruikers/' + this.userId + '/link/add';
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getOrganisations: function () {
      ApiService.get('/Theaters/GetAllDropDown')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.theaters.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });

      ApiService.get('/Producers/GetAllDropDown')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.producenten.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });

      ApiService.get('/Agencies/GetAllDropDown')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.impresariaten.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getUserRoles: function () {
      ApiService.get('/UserRoles/GetAll', {
        organisationTypeId: this.addUserLinkForm.organisationType,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.userRoleOptions.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getOrganisationTypes: function () {
      ApiService.get('/OrganisationTypes/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.organisationTypeOptions.push({
              value: element.organisationTypeId,
              text: element.organisationTypeName,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    changeOrganisationType: function () {
      this.organisationOptions = [];
      this.addUserLinkForm.organisationId = '';

      this.userRoleOptions = [];
      this.addUserLinkForm.roleId = '';
      this.getUserRoles();

      if (this.addUserLinkForm.organisationType == 1) {
        this.organisationOptions = this.impresariaten;
      } else if (this.addUserLinkForm.organisationType == 2) {
        this.organisationOptions = this.theaters;
      } else if (this.addUserLinkForm.organisationType == 3) {
        this.organisationOptions = this.producenten;
      }
    },
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.addUserLinkForm;

        ApiService.post('/UserRoles/Add', {
          organisationTypeId: form.organisationType,
          organisationId: form.organisationId,
          roleId: form.roleId,
          userId: parseInt(this.userId),
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();

            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch((error) => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    addUserLinkForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
