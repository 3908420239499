import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth.module.js';
import storage from './storage.module';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth: auth,
    storage: storage,
  },
});

export default store;
