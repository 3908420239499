<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="1146"
            ></help>
          </v-col>
          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <filter-period
                :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                selectedDefault="thisSeason"
                @changePeriod="filterPeriod = $event"
              ></filter-period>
              <v-autocomplete
                v-if="genres.length > 0"
                v-model="tablePublicMonitorFilter.genre"
                :items="genres"
                label="genre selecteren"
                clearable
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
              <v-autocomplete
                v-if="user.organisationTypeId !== 2"
                v-model="tablePublicMonitorFilter.theater"
                :items="theaters"
                label="selecteer een theater"
                clearable
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
              <v-autocomplete
                v-if="areas.length > 0"
                v-model="tablePublicMonitorFilter.area"
                :items="areas"
                label="selecteer zaal"
                clearable
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
              <v-text-field
                v-model="tablePublicMonitorFilter.productionName"
                label="productie zoeken"
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tablePublicMonitorFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-on="on"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="primary--text">filter overzicht</h5></v-card-title>
                  <filter-period
                    :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                    selectedDefault="thisSeason"
                    @changePeriod="filterPeriod = $event"
                  ></filter-period>
                  <v-autocomplete
                    v-if="genres.length > 0"
                    v-model="tablePublicMonitorFilter.genre"
                    :items="genres"
                    label="genre selecteren"
                    clearable
                    hide-details
                    dense
                    outlined
                    background-color="#fff"
                    class="ma-1 input-sm"
                  ></v-autocomplete>
                  <v-autocomplete
                    v-if="user.organisationTypeId !== 2"
                    v-model="tablePublicMonitorFilter.theater"
                    :items="theaters"
                    label="selecteer een theater"
                    clearable
                    hide-details
                    dense
                    outlined
                    background-color="#fff"
                    class="ma-1 input-sm"
                  ></v-autocomplete>
                  <v-autocomplete
                    v-if="areas.length > 0"
                    v-model="tablePublicMonitorFilter.area"
                    :items="areas"
                    label="selecteer zaal"
                    clearable
                    hide-details
                    dense
                    outlined
                    background-color="#fff"
                    class="ma-1 input-sm"
                  ></v-autocomplete>
                  <v-text-field
                    v-model="tablePublicMonitorFilter.productionName"
                    label="productie zoeken"
                    hide-details
                    dense
                    outlined
                    background-color="#fff"
                    class="ma-1 input-sm"
                  ></v-text-field>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tablePublicMonitorFilterDialog = false"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      outlined
                      dark
                      @click="clearFilterWidgets()"
                      >reset filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex justify-space-between my-4">
            <div class="d-flex align-center">
              <h2 class="primary--text font-weight-bold mb-2">
                {{ this.editReportNameForm.name }}
                <v-btn
                  fab
                  x-small
                  depressed
                  color="info"
                  class="ml-1"
                >
                  <v-icon
                    color="primary"
                    @click="changeReportName = true"
                    >mdi-square-edit-outline</v-icon
                  >
                </v-btn>
              </h2>
            </div>
            <div class="d-flex align-center">
              <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ms-2"
                @click="selectAllPerformances"
              >
                alles selecteren
                <v-icon
                  small
                  right
                  >mdi-check</v-icon
                >
              </v-btn>
              <v-btn
                v-if="this.selected.length > 0"
                color="primary"
                outlined
                rounded
                depressed
                class="font-weight-bold text-lowercase ms-2"
                @click="deselectAllPerformances"
              >
                niets selecteren
                <v-icon
                  small
                  right
                  >mdi-close</v-icon
                >
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <div class="d-flex justify-start">
          <p class="primary--text font-weight-bold mb-0">selectie maken</p>
        </div>
        <!-- Table -->
        <v-data-table
          v-model="selected"
          :headers="tablePublicMonitorHeadersFiltered"
          :items="tableProductionPerformancesFiltered"
          :mobile-breakpoint="0"
          :loading="tableProductionPerformancesLoading"
          :page.sync="tablePublicMonitorPage"
          :items-per-page="tablePublicMonitorItemsPerPage"
          :item-value="tableProductionPerformancesFiltered.id"
          :sort-by.sync="sortBy"
          hide-default-footer
          @page-count="tablePublicMonitorPageCount = $event"
          class="mt-5"
          fixed-header
          :height="tableProductionPerformancesFiltered.length > 10 ? '45vh' : ''"
          show-select
        >
          <template v-slot:[`item.startDate`]="{ item }">
            {{ item.startDateView }}
          </template>
          <v-data-footer></v-data-footer>
        </v-data-table>

        <!-- Table settings -->
        <v-row class="pt-2">
          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableProductionPerformancesFiltered.length > 25"
              @click="tablePublicMonitorItemsPerPage = 25"
              :color="tablePublicMonitorItemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tableProductionPerformancesFiltered.length > 25"
              @click="tablePublicMonitorItemsPerPage = 50"
              :color="tablePublicMonitorItemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tableProductionPerformancesFiltered.length > 50"
              @click="tablePublicMonitorItemsPerPage = 100"
              :color="tablePublicMonitorItemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tablePublicMonitorPageCount > 1"
              v-model="tablePublicMonitorPage"
              :length="tablePublicMonitorPageCount"
              :total-visible="7"
              :elevation="0"
            ></v-pagination>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <template v-if="selected.length > 0">
              <div class="pt-5 d-flex justify-start">
                <p class="primary--text font-weight-bold mb-0">huidige selectie</p>
              </div>
              <!-- Table -->
              <v-data-table
                :headers="tablePublicMonitorReportSelectedPerformances"
                :items="selected"
                :mobile-breakpoint="0"
                :page.sync="tablePublicMonitorSelectedReportsPage"
                :items-per-page="tablePublicMonitorSelectedReportsItemsPerPage"
                hide-default-footer
                @page-count="tablePublicMonitorSelectedReportsPageCount = $event"
                :height="selected.length > 10 ? '45vh' : ''"
                class="table-action mt-5"
              >
                <template v-slot:[`item.startDate`]="{ item }">
                  {{ item.startDateView }}
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    fab
                    x-small
                    depressed
                    color="error"
                    class="ml-1"
                    @click="(deleteItem = item), (deleteItemDialog = true);"
                  >
                    <v-icon color="#fff">mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>

              <v-row class="pt-2">
                <v-col
                  cols="12"
                  sm="4"
                  class="d-flex align-center justify-center justify-sm-start"
                >
                  <v-btn
                    v-if="selected.length > 25"
                    @click="tablePublicMonitorSelectedReportsItemsPerPage = 25"
                    :color="tablePublicMonitorSelectedReportsItemsPerPage == 25 ? 'primary' : '#fff'"
                    height="45"
                    width="45"
                    elevation="0"
                    x-small
                    class="pa-0 mr-1 font-weight-bold body-2"
                    >25</v-btn
                  >
                  <v-btn
                    v-if="selected.length > 25"
                    @click="tablePublicMonitorSelectedReportsItemsPerPage = 50"
                    :color="tablePublicMonitorSelectedReportsItemsPerPage == 50 ? 'primary' : '#fff'"
                    height="45"
                    width="45"
                    elevation="0"
                    x-small
                    class="pa-0 mr-1 font-weight-bold body-2"
                    >50</v-btn
                  >
                  <v-btn
                    v-if="selected.length > 50"
                    @click="tablePublicMonitorSelectedReportsItemsPerPage = 100"
                    :color="tablePublicMonitorSelectedReportsItemsPerPage == 100 ? 'primary' : '#fff'"
                    height="45"
                    width="45"
                    elevation="0"
                    x-small
                    class="pa-0 mr-1 font-weight-bold body-2"
                    >100</v-btn
                  >
                </v-col>

                <!-- Table pagination -->
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-pagination
                    v-if="tablePublicMonitorSelectedReportsPageCount > 1"
                    v-model="tablePublicMonitorSelectedReportsPage"
                    :length="tablePublicMonitorSelectedReportsPageCount"
                    :total-visible="7"
                    :elevation="0"
                  ></v-pagination>
                </v-col>
              </v-row>
            </template>
            <v-btn
              rounded
              large
              elevation="0"
              @click="submitReport(false)"
              color="secondary"
              class="text-lowercase mt-5"
              :loading="reportSubmitLoading"
              >rapportage bijwerken
              <v-icon
                small
                right
                >mdi-chart-bar</v-icon
              ></v-btn
            >
            <v-btn
              rounded
              large
              elevation="0"
              @click="submitReport(true)"
              color="primary"
              class="text-lowercase mt-5 ml-2"
              :loading="reportSubmitLoading"
              >rapportage bijwerken en bekijken
              <v-icon
                small
                right
                >mdi-arrow-right</v-icon
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Edit report name dialog starts here -->
    <v-dialog
      v-model="changeReportName"
      persistent
      width="500"
    >
      <v-form
        @submit.prevent
        v-model="formValid"
        class="d-flex flex-column"
        ref="form"
      >
        <v-card
          class="pa-6"
          flat
        >
          <v-card-title class="primary--text pl-0 mb-2">Wijzig rapportage naam</v-card-title>
          <v-text-field
            class="primary--text"
            label="wijzig rapportage naam"
            v-model="editReportNameForm.name"
            :rules="formValidationRules.required"
            outlined
            dense
            required
          >
          </v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              rounded
              large
              elevation="0"
              @click="setReportName"
              :loading="formLoading"
              color="secondary"
              class="text-lowercase mb-5 align-self-start"
              >Opslaan
              <v-icon
                small
                right
                >mdi-content-save</v-icon
              ></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.deleteItem.productionTitle }} verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je {{ this.deleteItem.productionTitle }} met id {{ this.deleteItem.id }} wil
          verwijderen?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(deleteItemDialog = false), (deleteItem = '');"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemSelected()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { TokenService } from '@/services/storage.service';
import ApiService from '@/services/api.service';
import router from '@/router/router.js';
import Help from '@/components/Help';
import functions from '../../services/functions.service';
import FilterPeriod from '../../components/FilterPeriod';

export default {
  name: 'PublicMonitorReportsEdit',
  props: ['reportNumber'],
  components: {
    Help,
    FilterPeriod,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'publieksmonitor',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'rapportages',
          disabled: false,
          href: '/publieksmonitor/rapportages',
          bold: false,
        },
      ],
      formValid: false,
      formError: '',
      formLoading: false,
      formValidationRules: {},
      editReportName: true,
      editReportNameForm: {
        name: '',
      },
      filterPeriod: {
        from: '',
        to: '',
      },
      tableProductionPerformancesFiltered: [],
      tableProductionPerformancesLoading: true,
      performances: [],
      genres: [],
      selected: [],
      theaters: [],
      areas: [],
      sortBy: 'startDate',
      tablePublicMonitorHeaders: [
        { text: 'naam', value: 'productionTitle' },
        { text: 'datum en tijd', value: 'startDate' },
        { text: 'aantal kaartkopers', value: 'purchases' },
        { text: 'genre', value: 'productionGenre' },
        { text: 'theater', value: 'theaterName', showFor: [1, 3] },
        { text: 'zaal', value: 'areaName' },
        { text: '', value: 'action', sortable: false, align: 'end' },
      ],
      tablePublicMonitorPage: 1,
      tablePublicMonitorPageCount: 0,
      tablePublicMonitorItemsPerPage: 25,
      tablePublicMonitorSelectedReportsPage: 1,
      tablePublicMonitorSelectedReportsPageCount: 0,
      tablePublicMonitorSelectedReportsItemsPerPage: 25,
      tablePublicMonitorFilter: {
        theater: '',
        area: '',
        productionName: '',
        genre: '',
      },
      reportName: '',
      tablePublicMonitorReportSelectedPerformances: [
        { text: 'id', value: 'id' },
        { text: 'naam', value: 'productionTitle' },
        { text: 'datum en tijd', value: 'startDate' },
        { text: 'genre', value: 'productionGenre' },
        { text: 'zaal', value: 'areaName' },
        { text: '', value: 'action', sortable: false, align: 'end' },
      ],
      deleteItemDialog: false,
      changeReportName: false,
      reportSubmitLoading: false,
      deleteItem: '',
      tablePublicMonitorFilterDialog: false,
    };
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.rights = this.getComponentRights('PublicMonitor');
    this.getReport();
  },
  methods: {
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageViewWithoutDecimals(value);
    },
    getDateTimeView(date) {
      return functions.getDateTimeView(date);
    },
    removeDup(arr) {
      let result = [];
      arr.forEach((item, index) => {
        if (arr.indexOf(item) == index) result.push(item);
      });
      return result;
    },
    getProductionPerformances: function () {
      const vm = this;
      this.performances = [];
      this.tableProductionPerformancesFiltered = [];
      this.tableProductionPerformancesLoading = true;

      ApiService.get('/PublicMonitor/GetPerformances', {
        startDate: functions.jsDateToStringDate(this.filterPeriod.from),
        endDate: functions.jsDateToStringDate(this.filterPeriod.to),
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.performances = response.data.result;

          for (var i = 0; i < this.performances.length; i++) {
            vm.$set(this.performances[i], 'startDateView', functions.getDateTimeView(this.performances[i].startDate));

            vm.$set(this.performances[i], 'productionTitle', this.performances[i].production.title);
            vm.$set(this.performances[i], 'productionGenre', this.performances[i].production.genre);

            if (this.performances[i].theater != null) {
              vm.$set(this.performances[i], 'theaterName', this.performances[i].theater.name);

              vm.theaters.push({
                value: this.performances[i].theater.id,
                text: this.performances[i].theater.name,
              });
            }

            if (this.performances[i].area != null) {
              vm.$set(this.performances[i], 'areaName', this.performances[i].area.name);

              vm.areas.push({
                value: this.performances[i].area.id,
                text: this.performances[i].area.name,
              });
            }

            vm.genres.push({
              value: this.performances[i].production.genre,
              text: this.performances[i].production.genre,
            });
          }

          this.tableProductionPerformancesFiltered = this.performances;
          this.tableProductionPerformancesLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getReport: function () {
      const vm = this;

      ApiService.get('/PublicMonitor/GetReport', {
        reportId: this.reportNumber,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          if (response.data.success) {
            const report = response.data.result;
            const reportPerformances = report.performances;

            this.editReportNameForm.name = report.name;

            for (var i = 0; i < reportPerformances.length; i++) {
              vm.$set(
                reportPerformances[i],
                'startDateView',
                functions.getDateTimeView(reportPerformances[i].startDate)
              );
              vm.$set(reportPerformances[i], 'productionTitle', reportPerformances[i].title);

              vm.$set(reportPerformances[i], 'productionGenre', reportPerformances[i].genre);

              vm.$set(reportPerformances[i], 'areaName', reportPerformances[i].area);

              //remove duplicates from selected
              if (!this.selected.some((el) => el.id === reportPerformances[i].id)) {
                this.selected.push(reportPerformances[i]);
              }
            }
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    filterProductions: function () {
      const vm = this;

      if (
        this.tablePublicMonitorFilter.productionName == '' &&
        (this.tablePublicMonitorFilter.theater == '' || this.tablePublicMonitorFilter.theater == undefined) &&
        (this.tablePublicMonitorFilter.area == '' || this.tablePublicMonitorFilter.area == undefined) &&
        (this.tablePublicMonitorFilter.genre == '' || this.tablePublicMonitorFilter.genre == undefined)
      ) {
        this.tablePublicMonitorFilterActive = false;
      } else {
        this.tablePublicMonitorFilterActive = true;
      }

      this.tableProductionPerformancesFiltered = this.performances.filter(function (performance) {
        if (performance.area == null) {
          performance.area = '';
        } else if (performance.genre == null) {
          performance.genre = '';
        }

        return (
          cleanWords(performance.production.title).indexOf(cleanWords(vm.tablePublicMonitorFilter.productionName)) !==
            -1 &&
          (performance.theater.id == vm.tablePublicMonitorFilter.theater ||
            vm.tablePublicMonitorFilter.theater == '' ||
            vm.tablePublicMonitorFilter.theater == undefined) &&
          (performance.area.id == vm.tablePublicMonitorFilter.area ||
            vm.tablePublicMonitorFilter.area == '' ||
            vm.tablePublicMonitorFilter.area == undefined) &&
          (performance.production.genre == vm.tablePublicMonitorFilter.genre ||
            vm.tablePublicMonitorFilter.genre == '' ||
            vm.tablePublicMonitorFilter.genre == undefined)
        );
      });

      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    deleteItemSelected: function () {
      for (var i = 0; i < this.selected.length; i++) {
        if (this.selected[i].id === this.deleteItem.id) {
          this.selected.splice(i--, 1);
          this.deleteItemDialog = false;
          this.deleteItem = '';
        }
      }
    },
    selectAllPerformances: function () {
      this.selected = this.selected.concat(this.tableProductionPerformancesFiltered);
    },
    deselectAllPerformances: function () {
      this.selected = this.selected.filter(
        (x) => !this.tableProductionPerformancesFiltered.filter((y) => y.id === x.id).length
      );
    },
    submitReport(goToDetail) {
      this.reportSubmitLoading = true;
      let performanceIds = [];
      this.selected.forEach(function (performance) {
        performanceIds.push(performance.id);
      });

      ApiService.post('/PublicMonitor/SaveReport', {
        id: this.reportNumber,
        name: this.editReportNameForm.name,
        performanceIds: performanceIds,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success && !goToDetail) {
            router.push({
              name: 'PublicMonitorReports',
            });
          } else if (response.data.success && goToDetail) {
            const reportStartDate = response.data.result.startDate;
            const reportEndDate = response.data.result.endDate;

            router.push({
              name: 'PublicMonitorReportsDetail',
              params: { reportNumber: this.reportNumber },
              query: { startDate: reportStartDate, endDate: reportEndDate },
            });
          } else {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.reportSubmitLoading = false;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          this.formError =
            'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
          this.reportSubmitLoading = false;
          console.log(error);
        });
    },
    setReportName() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        this.changeReportName = false;
        this.formLoading = false;
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),

    tablePublicMonitorHeadersFiltered() {
      return this.tablePublicMonitorHeaders.filter(
        (h) => !h.showFor || h.showFor.includes(this.user.organisationTypeId)
      );
    },
  },
  watch: {
    tablePublicMonitorFilter: {
      handler: 'filterProductions',
      deep: true,
    },
    filterPeriod: function () {
      this.getProductionPerformances();
    },
  },
};
</script>

<style>
.v-simple-checkbox .v-icon {
  color: #021e72;
}
</style>
