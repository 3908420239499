<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="62"
            ></help>
          </v-col>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field
                v-model="tableNotificationsFilter.title"
                label="zoek op titel"
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tableNotificationsFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-on="on"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="primary--text">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="tableNotificationsFilter.title"
                      label="zoek op titel"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tableNotificationsFilterDialog = false"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      outlined
                      dark
                      @click="clearFilterNotifications()"
                      >reset filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableNotificationsHeaders"
          :items="tableNotificationsFiltered"
          :mobile-breakpoint="0"
          :loading="tableNotificationsLoading"
          :page.sync="tableNotificationsPaging.page"
          :items-per-page="tableNotificationsPaging.itemsPerPage"
          hide-default-footer
          @page-count="tableNotificationsPaging.pageCount = $event"
          @click:row="goToDetail"
          class="table-action row-is-clickable"
          fixed-header
          :height="tableNotificationsFiltered.length > 10 ? '60vh' : ''"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              fab
              x-small
              depressed
              color="warning"
              class="ml-1"
              :to="{
                name: 'LibraryNotificationsDetail',
                params: { notificationId: item.id },
              }"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
          </template>

          <v-data-footer></v-data-footer>
        </v-data-table>

        <!-- Table settings -->
        <v-row
          class="text-center pt-2"
          align="center"
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption primary--text ma-0 pa-0">
              totaal: {{ this.tableNotificationsFiltered.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableNotificationsFiltered.length > 25"
              @click="tableNotificationsPaging.itemsPerPage = 25"
              :color="tableNotificationsPaging.itemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tableNotificationsFiltered.length > 25"
              @click="tableNotificationsPaging.itemsPerPage = 50"
              :color="tableNotificationsPaging.itemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tableNotificationsFiltered.length > 50"
              @click="tableNotificationsPaging.itemsPerPage = 100"
              :color="tableNotificationsPaging.itemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tableNotificationsPaging.pageCount > 1"
              v-model="tableNotificationsPaging.page"
              :length="tableNotificationsPaging.pageCount"
              :total-visible="7"
              :elevation="0"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';
import functions from '../../../services/functions.service';

import Help from '../../../components/Help.vue';

export default {
  name: 'LibraryNotifications',
  components: {
    Help,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'notificaties',
          disabled: false,
          href: '/beheer/notificaties',
          bold: true,
        },
      ],
      notifications: [],
      tableNotificationsFiltered: [],
      tableNotificationsLoading: true,
      tableNotificationsHeaders: [
        { text: 'titel', value: 'title' },
        { text: 'datum', value: 'showDate' },
        { text: '', value: 'action', sortable: false, align: 'end' },
      ],
      tableNotificationsFilter: {
        title: '',
      },
      tableNotificationsPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableNotificationsFilterDialog: false,
    };
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryNotifications');

    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tableNotificationsFilter,
    });
    this.tableNotificationsFilter = this.getFilter(this.$options.name);

    this.getNotifications();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'LibraryNotificationsDetail',
        params: { notificationId: row.id },
      });
    },
    getNotifications: function () {
      const vm = this;

      ApiService.get('/Notifications/GetAllPerOrganisation')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.notifications = response.data.result;

          for (var i = 0; i < this.notifications.length; i++) {
            vm.$set(this.notifications[i], 'showDate', functions.getDateTimeView(this.notifications[i].showDate));
          }

          this.tableNotificationsFiltered = this.notifications;

          // Paging
          this.checkFilter({
            viewName: this.$options.name + '-paging',
            filterObject: this.tableNotificationsPaging,
          });
          this.tableNotificationsPaging = this.getFilter(this.$options.name + '-paging');

          this.filterNotifications();

          this.tableNotificationsLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterNotifications: function () {
      const vm = this;
      this.tableNotificationsFiltered = this.notifications.filter(function (notification) {
        return cleanWords(notification.title).indexOf(cleanWords(vm.tableNotificationsFilter.title)) !== -1;
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterNotifications: function () {
      this.tableNotificationsFilter.title = '';
      this.tableNotificationsFilterDialog = false;
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),

    ...mapGetters('storage', ['getFilter']),
  },
  beforeDestroy() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tableNotificationsFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tableNotificationsPaging,
    });
  },
  watch: {
    tableNotificationsFilter: {
      handler: 'filterNotifications',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
