export default function getData(widgetData, labelKey, valueKey) {
  this.chart.loaded = false;

  const { values: dataValues, comparison: dataComparison } = widgetData;

  if (!(dataValues.length > 0)) {
    return;
  }

  const labels = dataValues.map((e) => e[labelKey]);
  const dataTickets = dataValues.map((e) => e.rate);
  const dataPurchases = dataValues.map((e) => e[valueKey] || e.purchases);
  const dataTicketsCompared = dataComparison.map((e) => e.rate);
  const dataPurchasesCompared = dataComparison.map((e) => e[valueKey] || e.purchases);
  const backgroundColorBundle = ['#fff453', '#0abfb8', '#021e73', '#D92853', '#782562', '#FF8F2E', '#D64933'];
  const colorBundle = ['#021e73', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'];

  labels.forEach((element, index) => {
    if (element === 'Onbekend') {
      backgroundColorBundle[index] = '#EAEAEA';
      colorBundle[index] = '#000';
    }
  });

  // Set chart options
  this.chart.options = {
    layout: {
      padding: 0,
    },
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      x: {
        stacked: true,
        max: 100,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        textAlign: 'left',
        title: {
          display: true,
          color: '#021e72',
          font: {
            size: 14,
          },
          padding: 10,
        },
      },
      datalabels: {
        formatter: function (value) {
          if (value > 5) {
            return Math.ceil(value);
          } else {
            return '';
          }
        },
      },
    },
  };

  var datasets = labels.map(function (label, index) {
    return {
      label: label,
      backgroundColor: backgroundColorBundle[index],
      data: [dataTickets[index], dataTicketsCompared != null ? dataTicketsCompared[index] : null],
      dataPurchases: [dataPurchases[index], dataPurchasesCompared != null ? dataPurchasesCompared[index] : null],
      stack: 'Stack 1',
      datalabels: {
        color: colorBundle[index],
      },
      barThickness: 60,
      tooltip: {
        enabled: true,
        intersect: true,
        mode: 'index',
        position: 'nearest',
        callbacks: {
          label: function (tooltipItem) {
            var dataPercentage = parseFloat(tooltipItem.dataset.data[tooltipItem.dataIndex]).toFixed(2);
            var data = tooltipItem.dataset.dataPurchases[tooltipItem.dataIndex];
            var label = `${tooltipItem.dataset.label}: ${dataPercentage}% / ${data}`;
            return label;
          },
        },
      },
    };
  });

  // Set chart data
  this.chart.data = {
    labels: ['eigen theater', 'vergelijking'],
    datasets: datasets,
  };

  // Load chart
  this.chart.loaded = true;
}
