<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="22"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <h1 class="primary--text mb-5">{{ this.agency.name }}</h1>
                  <h4 class="primary--text mb-2 font-weight-regular">gegevens impresariaat</h4>

                  <v-card
                    class="pa-6 mb-10"
                    flat
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>voorwaarden DIP geaccepteerd:</small> <br />
                          <strong><span v-if="agency.agreedToTerms">Ja</span><span v-else>Nee</span></strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>branchevereniging:</small><br /><strong>{{ agency.branchAssociation }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>toon publieksmonitor:</small> <br /><strong
                            ><span v-if="agency.hasPublicMonitorAccess">Ja</span><span v-else>Nee</span></strong
                          >
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>

                  <h4 class="primary--text mb-2 font-weight-regular">adres</h4>
                  <v-card
                    class="pa-6 mb-1"
                    flat
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="primary--text mb-0">
                          <small>straat en huisnummer:</small> <br />
                          <strong>{{ agency.street + ' ' + agency.number }}</strong>
                        </p>
                        <hr class="accent-hr my-2" />
                        <p class="primary--text mb-0">
                          <small>postcode:</small> <br />
                          <strong>{{ agency.zipCode }}</strong>
                        </p>
                        <hr class="accent-hr my-2" />
                        <p class="primary--text mb-0">
                          <small>plaats:</small> <br />
                          <strong>{{ agency.city }}</strong>
                        </p>
                        <hr class="accent-hr my-2" />
                        <p class="primary--text mb-0">
                          <small>provincie:</small> <br />
                          <strong>{{ agency.province }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>

                  <p class="grey--text text--lighten-1 mt-0">
                    <small
                      ><i>{{ this.agency.lastEditedBy }}</i></small
                    >
                  </p>

                  <div>
                    <!-- Bewerken -->
                    <v-btn
                      rounded
                      large
                      elevation="0"
                      outlined
                      color="primary"
                      class="text-lowercase mb-5 mr-5 align-self-start"
                      :to="{ name: 'AdminAgenciesEdit' }"
                      >Gegevens Bewerken
                      <v-icon
                        small
                        right
                        >mdi-square-edit-outline</v-icon
                      ></v-btn
                    >

                    <!-- Verwijderen -->
                    <v-btn
                      rounded
                      large
                      elevation="0"
                      @click="deleteItemDialog = true"
                      color="error"
                      class="text-lowercase mb-5 align-self-start"
                      >Verwijderen
                      <v-icon
                        small
                        right
                        >mdi-delete</v-icon
                      ></v-btn
                    >
                  </div>
                </v-col>
              </v-row>

              <!-- Active User overview -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
                    <h4 class="primary--text font-weight-regular">gebruikers</h4>
                    <v-btn
                      rounded
                      small
                      elevation="0"
                      color="primary"
                      class="text-lowercase"
                      :to="{ name: 'AdminAgenciesLinkAdd' }"
                      >gebruiker toevoegen</v-btn
                    >
                  </v-row>

                  <!-- Table -->
                  <v-data-table
                    :headers="tableAgenciesUsersHeaders"
                    :items="tableAgenciesUsersFiltered"
                    :mobile-breakpoint="0"
                    :loading="tableAgenciesUsersLoading"
                    :items-per-page="-1"
                    hide-default-footer
                    class="table-action"
                  >
                    <template v-slot:[`item.action`]="{ item }">
                      <v-btn
                        fab
                        x-small
                        depressed
                        color="info"
                        class="ml-1"
                        :to="{ name: 'AdminAgenciesLinkEdit', params: { linkId: item.linkId, agencyId: agencyId } }"
                      >
                        <v-icon color="primary">mdi-square-edit-outline</v-icon>
                      </v-btn>
                      <v-btn
                        fab
                        x-small
                        depressed
                        color="error"
                        class="ml-1"
                        @click="(deleteItemLink = item), (deleteItemLinkDialog = true);"
                      >
                        <v-icon color="#fff">mdi-delete</v-icon>
                      </v-btn>
                    </template>

                    <v-data-footer></v-data-footer>
                  </v-data-table>
                </v-col>
              </v-row>

              <!-- Producer link overview -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
                    <h4 class="primary--text font-weight-regular">gekoppelde producenten</h4>
                    <v-btn
                      rounded
                      small
                      elevation="0"
                      color="primary"
                      class="text-lowercase"
                      :to="{ name: 'AdminAgenciesProducerLinkAdd' }"
                      >producer toevoegen</v-btn
                    >
                  </v-row>

                  <!-- Table -->
                  <v-data-table
                    :headers="tableProducerLinkHeaders"
                    :items="tableProducerLinkFiltered"
                    :mobile-breakpoint="0"
                    :loading="tableProducerLinkLoading"
                    :items-per-page="-1"
                    hide-default-footer
                    class="table-action"
                  >
                    <template v-slot:[`item.action`]="{ item }">
                      <v-btn
                        fab
                        x-small
                        depressed
                        color="error"
                        class="ml-1"
                        @click="(deleteProducerLinkItem = item), (deleteProducerLinkDialog = true);"
                      >
                        <v-icon color="#fff">mdi-delete</v-icon>
                      </v-btn>
                      <v-btn
                        fab
                        x-small
                        depressed
                        color="warning"
                        class="ml-1"
                        :to="{ name: 'AdminProducersDetail', params: { producerId: item.producerId } }"
                      >
                        <v-icon color="primary">mdi-eye</v-icon>
                      </v-btn>
                    </template>

                    <v-data-footer></v-data-footer>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.agency.name }} verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je {{ this.agency.name }} wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemAgency()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog link starts here -->
    <v-dialog
      v-model="deleteItemLinkDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">Gebruikersrol verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je deze gebruikersrol wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemLinkDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemLinkAgency()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog producer link starts here -->
    <v-dialog
      v-model="deleteProducerLinkDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">Koppeling producent verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je deze koppeling wil verwijderen? Het is daarna niet meer terug te vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteProducerLinkDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteProducerLink()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';

import Help from '../../../components/Help.vue';

export default {
  name: 'AdminAgenciesDetail',
  props: ['agencyId'],
  components: {
    Help,
  },
  data() {
    return {
      agency: {
        name: '',
        agreedToTerms: false,
        hasPublicMonitorAccess: false,
        street: '',
        number: '',
        zipCode: '',
        city: '',
        province: '',
        branchAssociation: '',
        lastEditedBy: '',
      },
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'impresariaten',
          disabled: false,
          href: '/admin/impresariaten',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      deleteItemDialog: false,
      deleteItemLinkDialog: false,
      tableAgenciesUsersFiltered: [],
      tableAgenciesUsersLoading: true,
      tableAgenciesUsersHeaders: [
        { text: 'naam', value: 'userFullName' },
        { text: 'rol', value: 'roleName' },
        { text: '', value: 'action', sortable: false, align: 'end' },
      ],
      deleteProducerDialog: false,
      deleteProducerLinkDialog: false,
      tableProducerLinkFiltered: [],
      tableProducerLinkLoading: true,
      tableProducerLinkHeaders: [
        { text: 'id', value: 'producerId' },
        { text: 'naam', value: 'producerName' },
        { text: '', value: 'action', sortable: false, align: 'end' },
      ],
    };
  },
  mounted() {
    this.getAgency();
    this.getAgencyUsers();
    this.getProducerLinks();
  },
  methods: {
    getAgency: function () {
      ApiService.get('/Agencies/Get', {
        agencyId: this.agencyId,
      })
        .then((response) => {
          if (response.data.success) {
            const agency = response.data.result;
            this.breadcrumbs[2].text = agency.name;
            this.agency.agreedToTerms = agency.agreedToTerms;
            this.agency.hasPublicMonitorAccess = agency.hasPublicMonitorAccess;
            this.agency.name = agency.name;
            this.agency.street = agency.street;
            this.agency.number = agency.number;
            this.agency.zipCode = agency.zipCode;
            this.agency.city = agency.city;
            this.agency.province = agency.province.name;
            this.agency.lastEditedBy = agency.lastEditedBy;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getAgencyUsers: function () {
      ApiService.get('/Agencies/GetUsers', {
        agencyId: this.agencyId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableAgenciesUsersFiltered = response.data.result;
            this.tableAgenciesUsersLoading = false;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    getProducerLinks: function () {
      ApiService.get('/AgencyProducerLinks/GetPerAgency', {
        agencyId: this.agencyId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableProducerLinkFiltered = response.data.result;
            this.tableProducerLinkLoading = false;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    deleteItemAgency: function () {
      ApiService.delete('/Agencies/Delete', {
        agencyId: this.agencyId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.agency.name + ' is verwijderd';
            this.deleteItemDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/admin/impresariaten' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    deleteItemLinkAgency: function () {
      ApiService.delete('/UserRoles/Delete', {
        linkId: this.deleteItemLink.linkId,
        organisationTypeId: this.deleteItemLink.organisationTypeId,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableAgenciesUsersFiltered.map((item) => item.linkId).indexOf(this.deleteItemLink.linkId);
            this.tableAgenciesUsersFiltered.splice(i, 1);

            this.snackbarText = 'Gebruiker is verwijderd';
            this.deleteItemLinkDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    deleteProducerLink: function () {
      ApiService.delete('/AgencyProducerLinks/Delete', {
        linkId: this.deleteProducerLinkItem.linkId,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableProducerLinkFiltered
              .map((item) => item.linkId)
              .indexOf(this.deleteProducerLinkItem.linkId);
            this.tableProducerLinkFiltered.splice(i, 1);

            this.snackbarText = 'Koppeling producer is verwijderd';
            this.deleteProducerLinkDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
  },
  computed: {},
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
