<template>
  <div>
    <!-- Agreement Staffel -->
    <v-card
      class="py-6 px-3 pb-0 mb-4"
      flat
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p
              :class="contract.isTheaterSurchargeRejected ? 'error--text ' : 'primary--text '"
              class="font-weight-medium mb-0"
            >
              theatertoeslag
            </p>
            <h3
              :class="contract.isTheaterSurchargeRejected ? 'error--text ' : 'primary--text '"
              class="border-bottom pb-4 mb-4"
            >
              {{ getEuroSignView(contract.theaterFee) }}
            </h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p
              :class="contract.isCopyrightRejected ? 'error--text ' : 'primary--text '"
              class="font-weight-medium mb-0"
            >
              auteursrechten
            </p>
            <h3
              :class="contract.isCopyrightRejected ? 'error--text ' : 'primary--text '"
              class="border-bottom pb-4 mb-4"
            >
              {{ getPercentageView(contract.copyrightPercentage) }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p
              :class="contract.isCopyrightCalculationRejected ? 'error--text ' : 'primary--text '"
              class="font-weight-medium mb-0"
            >
              AR percentageberekening
            </p>
            <h3
              :class="contract.isCopyrightCalculationRejected ? 'error--text ' : 'primary--text '"
              class="border-bottom pb-4 mb-4"
            >
              <span v-if="contract.calculateCopyrightPercentage">{{
                parseFloat(getDecimalDatabase(contract.copyrightPercentage)) +
                '/1' +
                parseFloat(getDecimalDatabase(contract.copyrightPercentage)).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })
              }}</span>
              <span v-else>{{ parseFloat(getDecimalDatabase(contract.copyrightPercentage)) + '/100' }}</span>
            </h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p
              :class="contract.isProviderGuaranteeRejected ? 'error--text ' : 'primary--text '"
              class="font-weight-medium mb-0"
            >
              garantie aan aanbieder (excl. BTW)
            </p>
            <h3
              :class="contract.isProviderGuaranteeRejected ? 'error--text ' : 'primary--text '"
              class="border-bottom pb-4 mb-4"
            >
              {{ getEuroSignView(contract.providerGuarantee) }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <p
              :class="contract.isConsumerGuaranteeRejected ? 'error--text ' : 'primary--text '"
              class="font-weight-medium mb-0"
            >
              garantie aan afnemer (excl. BTW)
            </p>
            <h3
              :class="contract.isConsumerGuaranteeRejected ? 'error--text ' : 'primary--text '"
              class="border-bottom pb-4 mb-4"
            >
              {{ getEuroSignView(contract.consumerGuarantee) }}
            </h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0 mb-8"
          >
            <p
              :class="contract.isCombinedBudgetRejected ? 'error--text ' : 'primary--text '"
              class="font-weight-medium mb-0"
            >
              gezamelijke pot (excl. BTW)
            </p>
            <h3
              :class="contract.isCombinedBudgetRejected ? 'error--text ' : 'primary--text '"
              class="border-bottom pb-4 mb-0"
            >
              {{ getEuroSignView(contract.combinedBudget) }}
            </h3>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      class="pa-6 pb-0 mb-4"
      flat
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="py-0"
        >
          <p
            :class="contract.isTrancheRejected ? 'error--text ' : 'primary--text '"
            class="font-weight-medium mb-0"
          >
            partage aanbieder 1e tranche
          </p>
          <h3
            :class="contract.isTrancheRejected ? 'error--text ' : 'primary--text '"
            class="border-bottom pb-4 mb-4"
          >
            {{ getPercentageView(contract.partageProviderPercentage1) }} -
            {{ getEuroSignView(contract.partageProviderAmount1) }}
          </h3>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
        >
          <p
            :class="contract.isTrancheRejected ? 'error--text ' : 'primary--text '"
            class="font-weight-medium mb-0"
          >
            partage aanbieder 2e tranche
          </p>
          <h3
            :class="contract.isTrancheRejected ? 'error--text ' : 'primary--text '"
            class="border-bottom pb-4 mb-4"
          >
            <template v-if="+contract.partageProviderPercentage2 || contract.partageProviderAmount2 !== '0,00'">
              {{ getPercentageView(contract.partageProviderPercentage2) }} -
              {{ getEuroSignView(contract.partageProviderAmount2) }}
            </template>
            <template v-else>-</template>
          </h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="6"
          class="py-0"
        >
          <p
            :class="contract.isTrancheRejected ? 'error--text ' : 'primary--text '"
            class="font-weight-medium mb-0"
          >
            partage aanbieder 3e tranche
          </p>
          <h3
            :class="contract.isTrancheRejected ? 'error--text ' : 'primary--text '"
            class="border-bottom pb-4 mb-4"
          >
            <template v-if="+contract.partageProviderPercentage3 || contract.partageProviderAmount3 !== '0,00'">
              {{ getPercentageView(contract.partageProviderPercentage3) }} -
              {{ getEuroSignView(contract.partageProviderAmount3) }}
            </template>
            <template v-else>-</template>
          </h3>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
        >
          <p
            :class="contract.isTrancheRejected ? 'error--text ' : 'primary--text '"
            class="font-weight-medium mb-0"
          >
            partage aanbieder 4e tranche
          </p>
          <h3
            :class="contract.isTrancheRejected ? 'error--text ' : 'primary--text '"
            class="border-bottom pb-4 mb-4"
          >
            <template v-if="+contract.partageProviderPercentage4 || contract.partageProviderAmount4 !== '0,00'">
              {{ getPercentageView(contract.partageProviderPercentage4) }} -
              {{ getEuroSignView(contract.partageProviderAmount4) }}
            </template>
            <template v-else>-</template>
          </h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="6"
          class="py-0"
        >
          <p
            :class="contract.isTrancheRejected ? 'error--text ' : 'primary--text '"
            class="font-weight-medium mb-0"
          >
            partage aanbieder overig
          </p>
          <h3
            :class="contract.isTrancheRejected ? 'error--text ' : 'primary--text '"
            class="border-bottom pb-4 mb-4"
          >
            {{ getPercentageView(contract.partageProviderPercentageRest) }}
          </h3>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import functions from '../../../../services/functions.service';

export default {
  props: ['contract'],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    getEuroSignView(value) {
      if (value != '') {
        return functions.getEuroSignView(parseFloat(functions.getEuroCurrencyDatabase(value)));
      } else {
        return '-';
      }
    },
    getDecimalDatabase(value) {
      if (value != '') {
        return functions.getDecimalDatabase(value);
      } else {
        return '-';
      }
    },
    getPercentageView(value) {
      if (value != '') {
        return functions.getPercentageView(this.getDecimalDatabase(value));
      } else {
        return '-';
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {},
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #e1e7f2;
}
</style>
