export default function createExportData(all, type) {
  const vm = all;
  vm.exportData = [];
  vm.exportDataColumns = [];

  var intervalExport = setInterval(function () {
    if (!isLoading(vm, type)) {  
      clearInterval(intervalExport);

      // create export columns
      for (var i = 1; i < 32; i++) {
        vm.exportDataColumns.push({ label: '', field: 'column' + i });
      }

      // create export data
      let date = new Date();
      vm.exportData.push({
        column1: vm.user.organisationName,
        column3: `Gegenereerd op: ${date.getDate()} - ${date.getMonth() + 1} - ${date.getFullYear()}  ${date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`,
      });
      vm.exportData.push({});

      // First info
      if (type === 'report') {
        createFirstInfoForReport(vm);
      } else {
        createFirstInfoForPerformancesAndProductions(vm);
      }

      // Bezetting
      vm.exportData.push({ column1: 'bezetting' });
      vm.exportData.push({
        column2: 'capaciteit',
        column3: 'aantal verkochte kaarten ',
        column4: 'aantal kaartkopers',
        column5: 'bezettingspercentage',
      });

      vm.exportData.push({
        column1: vm.user.organisationName,
        column2: vm.publicMonitorOccupancyRate.capacity,
        column3: vm.publicMonitorOccupancyRate.totalTickets,
        column4: vm.publicMonitorOccupancyRate.totalPurchases,
        column5: vm.publicMonitorOccupancyRate.rate,
      });

      vm.exportData.push({});

      createSection(vm, 'leeftijdsopbouw', vm.publicMonitorCustomerAges.values, ['age', 'purchases']);
      createSection(vm, 'moment van aankoop', vm.publicMonitorPurchasePeriods.values, ['period', 'purchases']);
      createSection(vm, 'prijssoorten', vm.publicMonitorTicketSorts.values, ['type', 'purchases']);
      createSection(vm, 'loyaliteit', vm.publicMonitorLoyalties.values, ['years', 'purchases']);
      createSection(vm, 'kaarten per klant', vm.publicMonitorTicketsPerCustomer.values, ['frequency', 'tickets']);
      createSection(vm, 'Aantal aankopen in de afgelopen 12 maanden', vm.publicMonitorTicketSalesOverTime.values, ['day', 'tickets']);
      createSection(vm, 'Geslacht kaartkopers', vm.publicMonitorBuyerGenders.values, ['gender', 'purchases']);
      createSection(vm, 'Geografische spreiding ', vm.publicMonitorBuyerDistances.values, ['distance', 'purchases']);
      createSection(vm, 'Dag van aankoop', vm.publicMonitorDayOfPurchases.values, ['day', 'tickets']);
      createSection(vm, 'Verkoop per gemeente', vm.mapDataMunicipalities.values, ['name', 'purchases']);
      createSection(vm, 'Kaartverkoop over tijd', vm.publicMonitorTicketSalesOverTime.values, ['day', 'tickets']);

      vm.exportData.push({});
      vm.exportData.push({});


      if (vm.performances) {
        // Voorstellingen
        vm.exportData.push({ column1: 'voorstellingen' });

        vm.performances.forEach((item) => {
          vm.exportData.push({
            column2: 'startdatum',
            column3: 'verkocht',
            column4: 'zaal',
            column5: 'gem. tickets',
            column6: 'gem. leeftijd',
          });

          vm.exportData.push({
            column2: item.startDate,
            column3: item.tickets,
            column4: item.area,
            column5: item.averageTickets,
            column6: item.averageAge,
          });

          vm.exportData.push({});
        });
      }
    }
  }, 100);

  function isLoading(vm, type) {
    if (type === 'report') {
      return vm.tableReportPerformancesLoading;
    } else if (type === 'performance') {
      return vm.performancesWidgetsLoading;
    } else if (type === 'production') {
      return vm.tableProductionPerformancesLoading;
    }
  }

  function createFirstInfoForReport(vm) {
    vm.exportData.push({ column1: 'basis informatie' });
    vm.exportData.push({
      column2: 'aantal voorstellingen',
      column3: 'eerste speeldatum',
      column4: 'laatste speeldatum',
    });

    vm.exportData.push({
      column1: vm.user.organisationName,
      column2: vm.performances.length,
      column3: vm.report.firstStartDate,
      column4: vm.report.lastStartDate,
    });
    vm.exportData.push({});
  }

  function createFirstInfoForPerformancesAndProductions(vm) {
    vm.exportData.push({ column1: 'basis informatie' });
    vm.exportData.push({
      column2: 'productienummer',
      column3: 'titel',
      column4: 'genre',
      column5: 'eerste speeldatum',
      column6: 'laatste speeldatum',
    });

    vm.exportData.push({
      column1: vm.user.organisationName,
      column2: vm.production.productionNumber ? vm.production.productionNumber : '',
      column3: vm.production.title,
      column4: vm.production.genre,
      column5: vm.production.firstStartDate,
      column6: vm.production.lastStartDate,
    });
    vm.exportData.push({});
  }

  function createSection(vm, sectionName, values, properties) {
    vm.exportData.push({ column1: sectionName });
    createExport(values, properties);
  }

  function createExport(object, properties) {
    const obj = {};
    object.forEach((item, index) => {
      properties.forEach((property, propIndex) => {
        const column = 'column' + (index + 2 + propIndex);
        obj[column] = item[property];
      });
    });
    vm.exportData.push(obj);
  }
}
