<template>
  <v-col
    cols="12"
    sm="4"
    class="d-flex justify-center justify-sm-end"
  >
    <v-btn
      color="primary"
      rounded
      depressed
      class="font-weight-bold text-lowercase"
    >
      <vue-excel-xlsx
        class="v-btn font-weight-bold text-lowercase"
        :data="data"
        :columns="columns"
        :file-name="filename"
        :sheet-name="sheetname"
        >exporteer selectie <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
      >
    </v-btn>
  </v-col>
</template>

<script>
export default {
  name: 'downloadExcel',
  props: {
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    filename: {
      type: String,
      required: true,
    },
    sheetname: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
