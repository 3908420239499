<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pl-0"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="1"
            ></help>
          </v-col>

          <!-- Filter -->
          <v-col class="d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field
                v-model="tableUsersFilter.place"
                label="zoek op plaatsnaam"
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-text-field
                v-model="tableUsersFilter.name"
                label="op naam zoeken"
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tableUsersFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-on="on"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="primary--text">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="tableUsersFilter.place"
                      label="zoek op plaatsnaam"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                    <v-text-field
                      v-model="tableUsersFilter.name"
                      label="op naam zoeken"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tableUsersFilterDialog = false"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      outlined
                      dark
                      @click="clearFilterUsers()"
                      >reset filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableUsersHeaders"
          :items="tableUsersFiltered"
          :mobile-breakpoint="0"
          :loading="tableUsersLoading"
          :page.sync="tableUsersPage"
          :items-per-page="tableUsersItemsPerPage"
          hide-default-footer
          @page-count="tableUsersPageCount = $event"
          class="table-action table-expand"
          item-key="key"
          single-expand
          show-expand
          fixed-header
          :height="tableUsersFiltered.length > 10 ? '60vh' : ''"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0"
            >
              <v-data-table
                :headers="tableUsersDetailHeaders"
                :items="item.users"
                :mobile-breakpoint="0"
                hide-default-footer
                :items-per-page="-1"
                class="table-action"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    fab
                    x-small
                    depressed
                    color="warning"
                    class="ml-1"
                    @click="
                      switchRole({
                        userId: item.userId,
                        userRoleId: item.roleId,
                        organisationId: item.organisationId,
                        organisationTypeId: item.organisationTypeId,
                      })
                    "
                  >
                    <v-icon color="primary">mdi-login-variant</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </td>
          </template>

          <v-data-footer></v-data-footer>
        </v-data-table>

        <!-- Tabel settings -->
        <v-row
          class="text-center pt-2"
          no-gutters
          align="center"
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption primary--text ma-0 pa-0">
              totaal: {{ this.tableUsersFiltered.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableUsersFiltered.length > 25"
              @click="tableUsersItemsPerPage = 25"
              :color="tableUsersItemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tableUsersFiltered.length > 25"
              @click="tableUsersItemsPerPage = 50"
              :color="tableUsersItemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tableUsersFiltered.length > 50"
              @click="tableUsersItemsPerPage = 100"
              :color="tableUsersItemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tableUsersPageCount > 1"
              v-model="tableUsersPage"
              :length="tableUsersPageCount"
              :total-visible="7"
              :elevation="0"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';
import functions from '../../../services/functions.service';

import Help from '../../../components/Help.vue';

export default {
  name: 'AdminLogin',
  components: {
    Help,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      title: 'Gebruikers',
      breadcrumbs: [
        {
          text: 'inloggen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      users: [],

      tableUsersFiltered: [],
      tableUsersLoading: true,
      tableUsersHeaders: [
        { text: 'naam', value: 'name' },
        { text: 'plaatsnaam', value: 'placeName' },
        { text: 'type organisatie', value: 'organisationType' },
        { text: 'aantal accounts', value: 'userCount' },
        { text: 'organisatie id', value: 'id' },
        { text: '', value: 'data-table-expand', align: 'end' },
      ],
      tableUsersFilter: {
        name: '',
        place: '',
      },
      tableUsersPage: 1,
      tableUsersPageCount: 0,
      tableUsersItemsPerPage: 25,
      tableUsersFilterDialog: false,
      tableUsersDetailHeaders: [
        { text: 'naam', value: 'userName' },
        { text: 'e-mail', value: 'email' },
        { text: 'rol', value: 'role' },
        { text: 'laatste login', value: 'lastLoginDate' },
        { text: '', value: 'action', sortable: false, align: 'end' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('AdminGebruikers');
    this.getUsers();
  },
  methods: {
    ...mapActions('auth', ['switchRole']),
    getUsers: function () {
      const vm = this;

      ApiService.get('/Account/GetOrganisationSelection')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.users = response.data.result;

          for (var i = 0; i < this.users.length; i++) {
            vm.$set(this.users[i], 'key', this.users[i].id + ' - ' + this.users[i].name);
            for (var j = 0; j < this.users[i].users.length; j++) {
              vm.$set(
                this.users[i].users[j],
                'lastLoginDate',
                functions.getDateTimeView(this.users[i].users[j].lastLoginDate)
              );
            }
          }

          this.tableUsersFiltered = this.users;
          this.tableUsersLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterUsers: function () {
      const vm = this;
      this.tableUsersFiltered = this.users.filter(function (user) {
        return (
          cleanWords(user.name).indexOf(cleanWords(vm.tableUsersFilter.name)) !== -1 &&
          cleanWords(user.placeName).indexOf(cleanWords(vm.tableUsersFilter.place)) !== -1
        );
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterUsers: function () {
      this.tableUsersFilter.name = '';
      this.tableUsersFilter.place = '';
      this.tableUsersFilterDialog = false;
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
  watch: {
    tableUsersFilter: {
      handler: 'filterUsers',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
