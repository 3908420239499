<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row
          v-if="rights.edit"
          align="center"
          class="px-3 mb-4"
        >
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Een nieuwe gebruiker aanmaken?</span>
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
              :to="{ name: 'LibraryUsersAdd' }"
            >
              Aanmaken nieuwe gebruiker
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="42"
            ></help>
          </v-col>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field
                v-model="tableUsersFilter.name"
                label="op naam zoeken"
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-text-field
                v-model="tableUsersFilter.email"
                label="op e-mail zoeken"
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tableUsersFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-on="on"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="primary--text">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="tableUsersFilter.name"
                      label="op naam zoeken"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                    <v-text-field
                      v-model="tableUsersFilter.email"
                      label="op e-mail zoeken"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tableUsersFilterDialog = false"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      outlined
                      dark
                      @click="clearFilterUsers()"
                      >reset filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableUsersHeaders"
          :items="tableUsersFiltered"
          :mobile-breakpoint="0"
          :loading="tableUsersLoading"
          :page.sync="tableUsersPaging.page"
          :items-per-page="tableUsersPaging.itemsPerPage"
          hide-default-footer
          @page-count="tableUsersPaging.pageCount = $event"
          @click:row="goToDetail"
          class="table-action row-is-clickable"
          fixed-header
          :height="tableUsersFiltered.length > 10 ? '60vh' : ''"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-if="item.twoFactorEnabled" color="primary" v-bind="attrs" v-on="on">
                  mdi-two-factor-authentication
                </v-icon>
              </template>
              <span>Twee-factor ingeschakeld</span>
            </v-tooltip>
            <v-btn
              fab
              x-small
              depressed
              color="warning"
              class="ml-1"
              :to="{ name: 'LibraryUsersDetail', params: { userId: item.id } }"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>

            <v-btn
              v-if="rights.edit"
              fab
              x-small
              depressed
              color="info"
              class="ml-1"
              :to="{ name: 'LibraryUsersEdit', params: { userId: item.id } }"
            >
              <v-icon color="primary">mdi-square-edit-outline</v-icon>
            </v-btn>

            <v-btn
              v-if="rights.edit && user.userId != item.id"
              fab
              x-small
              depressed
              color="error"
              class="ml-1"
              @click="(deleteItem = item), (deleteItemDialog = true);"
            >
              <v-icon color="#fff">mdi-delete</v-icon>
            </v-btn>
          </template>

          <v-data-footer></v-data-footer>
        </v-data-table>

        <!-- Table settings -->
        <v-row
          class="text-center pt-2"
          align="center"
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption primary--text ma-0 pa-0">
              totaal: {{ this.tableUsersFiltered.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableUsersFiltered.length > 25"
              @click="tableUsersPaging.itemsPerPage = 25"
              :color="tableUsersPaging.itemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tableUsersFiltered.length > 25"
              @click="tableUsersPaging.itemsPerPage = 50"
              :color="tableUsersPaging.itemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tableUsersFiltered.length > 50"
              @click="tableUsersPaging.itemsPerPage = 100"
              :color="tableUsersPaging.itemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tableUsersPaging.pageCount > 1"
              v-model="tableUsersPaging.page"
              :length="tableUsersPaging.pageCount"
              :total-visible="7"
              :elevation="0"
            ></v-pagination>
          </v-col>

          <!-- Table download -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex justify-center justify-sm-end"
          >
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
            >
              <vue-excel-xlsx
                class="v-btn font-weight-bold text-lowercase"
                :data="tableUsersFiltered"
                :columns="downloadExportColumns"
                :filename="'dip-gebruikers-export'"
                :sheetname="'Gebruikers'"
                >exporteer selectie <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.deleteItem.name }} verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je het account voor {{ this.deleteItem.name }} wil verwijderen? Het is daarna niet meer
          terug te vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(deleteItemDialog = false), (deleteItem = '');"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemUser()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
      elevation="0"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';

import Help from '../../../components/Help.vue';

export default {
  name: 'LibraryUsers',
  components: {
    Help,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruikers',
          disabled: false,
          href: '/beheer/gebruikers',
          bold: true,
        },
      ],
      users: [],
      tableUsersFiltered: [],
      tableUsersLoading: true,
      tableUsersHeaders: [
        { text: 'naam', value: 'name' },
        { text: 'e-mailadres', value: 'email' },
        { text: 'gebruikersrol', value: 'role' },
        { text: 'mails', value: 'mails' },
        { text: '', value: 'action', sortable: false, align: 'end' },
      ],
      tableUsersFilter: {
        name: '',
        email: '',
      },
      tableUsersPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableUsersFilterDialog: false,
      deleteItemDialog: false,
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
      editUserDialog: false,
      downloadExportColumns: [
        { label: 'ID', field: 'id' },
        { label: 'naam', field: 'name' },
        { label: 'e-mail', field: 'email' },
        { label: 'rol', field: 'role' },
        { label: 'contracten', field: 'contractMails' },
        { label: 'subsidies', field: 'subventionMails' },
        { label: 'voorstellingen', field: 'performanceMails' },
        { label: 'producties', field: 'productionMails' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryUsers');

    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tableUsersFilter,
    });
    this.tableUsersFilter = this.getFilter(this.$options.name);

    this.getUsers();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'LibraryUsersDetail',
        params: { userId: row.id },
      });
    },
    getUsers: function () {
      ApiService.get('/Users/GetAllPerOrganisation')
        .then((response) => {
          const vm = this;
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.users = response.data.result;
          this.tableUsersFiltered = this.users;

          for (var i = 0; i < this.users.length; i++) {
            vm.$set(this.users[i], 'contractMails', this.users[i].receiveContractMails == true ? 'ja' : 'nee');

            vm.$set(this.users[i], 'performanceMails', this.users[i].receivePerformanceMails == true ? 'ja' : 'nee');

            vm.$set(this.users[i], 'productionMails', this.users[i].receiveProductionMails == true ? 'ja' : 'nee');

            vm.$set(this.users[i], 'subventionMails', this.users[i].receiveSubventionMails == true ? 'ja' : 'nee');

            let emailSettings = [];

            if (this.users[i].receiveContractMails) {
              emailSettings.push('contracten');
            }

            if (this.users[i].receivePerformanceMails) {
              emailSettings.push('voorstellingen');
            }

            if (this.users[i].receiveProductionMails) {
              emailSettings.push('producties');
            }

            if (this.users[i].receiveSubventionMails) {
              emailSettings.push('subsidies');
            }

            vm.$set(this.users[i], 'mails', emailSettings.join(', '));
          }

          // Paging
          this.checkFilter({
            viewName: this.$options.name + '-paging',
            filterObject: this.tableUsersPaging,
          });
          this.tableUsersPaging = this.getFilter(this.$options.name + '-paging');

          this.filterUsers();

          this.tableUsersLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterUsers: function () {
      const vm = this;
      this.tableUsersFiltered = this.users.filter(function (user) {
        return (
          cleanWords(user.name).indexOf(cleanWords(vm.tableUsersFilter.name)) !== -1 &&
          cleanWords(user.email).indexOf(cleanWords(vm.tableUsersFilter.email)) !== -1
        );
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterUsers: function () {
      this.tableUsersFilter.name = '';
      this.tableUsersFilter.email = '';
      this.tableUsersFilterDialog = false;
    },
    deleteItemUser: function () {
      ApiService.delete('/Users/DeletePerOrganisation', {
        linkId: this.deleteItem.linkId,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.users.map((item) => item.id).indexOf(this.deleteItem.id);
            this.users.splice(i, 1);
            this.filterUsers();

            this.snackbarText = this.deleteItem.name + ' is verwijderd';
            this.deleteItemDialog = false;
            this.deleteItem = '';
            this.snackbarColor = 'success';
            this.snackbar = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),

    ...mapGetters('storage', ['getFilter']),
  },
  beforeDestroy() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tableUsersFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tableUsersPaging,
    });
  },
  watch: {
    tableUsersFilter: {
      handler: 'filterUsers',
      deep: true,
    },
  },
};
</script>
