var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"py-6 px-3 pb-0 mb-4",attrs:{"flat":""}},[_c('v-container',[_c('v-row',[_c('detail-part',{attrs:{"label":"theatertoeslag","contract":_vm.contract,"is-rejected":_vm.contract.isTheaterSurchargeRejected,"getter":c => c.theaterFee,"format":_vm.getEuroSignView}})],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isCopyrightRejected ? 'error--text ' : 'primary--text '},[_vm._v(" auteursrechten ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.copyrightPercentage &&
              _vm.contract.previousApproved.copyrightPercentage != null &&
              _vm.contract.previousApproved.copyrightPercentage != _vm.contract.copyrightPercentage
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.contract.isCopyrightRejected
                  ? 'error--text no-dash'
                  : 'primary--text text-decoration-line-through mb-0 pr-2'},[_vm._v(" "+_vm._s(_vm.getPercentageView(_vm.contract.previousApproved.copyrightPercentage))+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(" "+_vm._s(_vm.getPercentageView(_vm.contract.copyrightPercentage))+" ")])]):_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.contract.isCopyrightRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.getPercentageView(_vm.contract.copyrightPercentage))+" ")])]),(_vm.contract.copyrightPercentage)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isCopyrightCalculationRejected ? 'error--text ' : 'primary--text '},[_vm._v(" AR percentageberekening ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.copyrightPercentage &&
              _vm.contract.previousApproved.copyrightPercentage != null &&
              _vm.contract.previousApproved.copyrightPercentage != _vm.contract.copyrightPercentage
            )?_c('div',{staticClass:"contract-diff mb-4"},[(_vm.contract.calculateCopyrightPercentage)?_c('div',[_c('h3',{staticClass:"primary--text text-decoration-line-through mb-0 pr-2"},[_vm._v(" "+_vm._s(parseFloat(_vm.getDecimalDatabase(_vm.contract.previousApproved.copyrightPercentage)) + '/1' + parseFloat(_vm.getDecimalDatabase(_vm.contract.previousApproved.copyrightPercentage)).toLocaleString( 'en-US', { minimumIntegerDigits: 2, useGrouping: false } ))+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(" "+_vm._s(parseFloat(_vm.getDecimalDatabase(_vm.contract.copyrightPercentage)) + '/1' + parseFloat(_vm.getDecimalDatabase(_vm.contract.copyrightPercentage)).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false, }))+" ")])]):_c('div',[_c('h3',{staticClass:"primary--text text-decoration-line-through mb-0 pr-2"},[_vm._v(" "+_vm._s(parseFloat(_vm.getDecimalDatabase(_vm.contract.previousApproved.copyrightPercentage)) + '/100')+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(" "+_vm._s(parseFloat(_vm.getDecimalDatabase(_vm.contract.copyrightPercentage)) + '/100')+" ")])])]):_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.contract.isCopyrightCalculationRejected ? 'error--text ' : 'primary--text '},[(_vm.contract.calculateCopyrightPercentage)?_c('span',[_vm._v(_vm._s(parseFloat(_vm.getDecimalDatabase(_vm.contract.copyrightPercentage)) + '/1' + parseFloat(_vm.getDecimalDatabase(_vm.contract.copyrightPercentage)).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false, })))]):_c('span',[_vm._v(_vm._s(parseFloat(_vm.getDecimalDatabase(_vm.contract.copyrightPercentage)) + '/100'))])])]):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isProviderPartageRejected ? 'error--text ' : 'primary--text '},[_vm._v(" partage aanbieder ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.partageProviderPercentage &&
              _vm.contract.previousApproved.partageProviderPercentage != null &&
              _vm.contract.previousApproved.partageProviderPercentage != _vm.contract.partageProviderPercentage
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.contract.isProviderPartageRejected
                  ? 'error--text no-dash'
                  : 'primary--text text-decoration-line-through mb-0 pr-2'},[_vm._v(" "+_vm._s(_vm.getPercentageView(_vm.contract.previousApproved.partageProviderPercentage))+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(" "+_vm._s(_vm.getPercentageView(_vm.contract.partageProviderPercentage))+" ")])]):_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.contract.isProviderPartageRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.getPercentageView(_vm.contract.partageProviderPercentage))+" ")])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isProviderGuaranteeRejected ? 'error--text ' : 'primary--text '},[_vm._v(" garantie aan aanbieder (excl. BTW) ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.providerGuarantee &&
              _vm.contract.previousApproved.providerGuarantee != null &&
              _vm.contract.previousApproved.providerGuarantee != _vm.contract.providerGuarantee
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.contract.isProviderPartageRejected
                  ? 'error--text no-dash'
                  : 'primary--text text-decoration-line-through mb-0 pr-2'},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.previousApproved.providerGuarantee))+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(_vm._s(_vm.getEuroSignView(_vm.contract.providerGuarantee)))])]):_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.contract.isProviderGuaranteeRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.providerGuarantee))+" ")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isConsumerGuaranteeRejected ? 'error--text ' : 'primary--text '},[_vm._v(" garantie aan afnemer (excl. BTW) ")]),(
              _vm.contract.previousApproved &&
              _vm.contract.previousApproved.consumerGuarantee != null &&
              _vm.contract.previousApproved.consumerGuarantee != _vm.contract.consumerGuarantee
            )?_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{class:_vm.contract.isConsumerGuaranteeRejected
                  ? 'error--text no-dash'
                  : 'primary--text text-decoration-line-through mb-0 pr-2'},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.previousApproved.consumerGuarantee))+" ")]),_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(_vm._s(_vm.getEuroSignView(_vm.contract.consumerGuarantee)))])]):_c('h3',{staticClass:"border-bottom pb-4 mb-4",class:_vm.contract.isConsumerGuaranteeRejected ? 'error--text ' : 'primary--text '},[_vm._v(" "+_vm._s(_vm.getEuroSignView(_vm.contract.consumerGuarantee))+" ")])]),_c('v-col',{staticClass:"py-0 mb-8",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"font-weight-medium mb-0",class:_vm.contract.isGuaranteeCalculationRejected ? 'error--text ' : 'primary--text '},[_vm._v(" garantieberekening ")]),_c('div',{staticClass:"contract-diff mb-4"},[_c('h3',{staticClass:"mb-0 pr-2",class:{
                'error--text no-dash': _vm.contract.isConsumerGuaranteeRejected,
                'primary--text': !_vm.contract.isConsumerGuaranteeRejected,
                'text-decoration-line-through': _vm.isGuaranteeCalculationRejected.newValue != null,
                'pb-4 border-bottom': _vm.isGuaranteeCalculationRejected.newValue == null,
              }},[_vm._v(" "+_vm._s(_vm.isGuaranteeCalculationRejected.oldValue ? 'garantie per voorstelling' : 'garantie op totale recette')+" ")]),(_vm.isGuaranteeCalculationRejected.newValue != null)?_c('h3',{staticClass:"prev-approved--text pb-4 border-bottom"},[_vm._v(" "+_vm._s(_vm.isGuaranteeCalculationRejected.newValue ? 'garantie per voorstelling' : 'garantie op totale recette')+" ")]):_vm._e()])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }