<template>
  <div>
    <!-- Theater Contact -->
    <v-card
      class="py-6 px-3 pb-0 mb-4"
      flat
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
            v-if="contract.theaterContact && contract.theaterContact.fullName != ''"
          >
            <p class="primary--text font-weight-medium mb-0">contactpersoon afnemer</p>

            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.theaterContact &&
                contract.previousApproved.theaterContact.fullName != '' &&
                contract.previousApproved.theaterContact.fullName != contract.theaterContact.fullName
              "
            >
              <h3 class="primary--text mb-0 pr-2">{{ contract.previousApproved.theaterContact.fullName }}</h3>
              <h3 class="error--text pb-4 border-bottom">{{ contract.theaterContact.fullName }}</h3>
            </div>
            <h3
              v-else
              class="primary--text border-bottom pb-4 mb-4"
            >
              {{ contract.theaterContact.fullName }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0 mb-8"
            v-if="contract.theaterContact && contract.theaterContact.fullName != ''"
          >
            <p class="primary--text font-weight-medium mb-0">e-mailadres afnemer</p>

            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.theaterContact &&
                contract.previousApproved.theaterContact.email != '' &&
                contract.previousApproved.theaterContact.email != contract.theaterContact.email
              "
            >
              <h3 class="primary--text mb-0 pr-2">{{ contract.previousApproved.theaterContact.email }}</h3>
              <h3 class="error--text pb-4 border-bottom">{{ contract.theaterContact.email }}</h3>
            </div>
            <h3
              v-else
              class="primary--text border-bottom pb-4 mb-0"
            >
              {{ contract.theaterContact.email }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0 mb-8"
            v-else-if="contract.theaterContactEmail != ''"
          >
            <p class="primary--text font-weight-medium mb-0">e-mailadres afnemer</p>

            <div
              class="contract-diff mb-4"
              v-if="
                contract.previousApproved &&
                contract.previousApproved.theaterContactEmail != '' &&
                contract.previousApproved.theaterContactEmail != contract.theaterContactEmail
              "
            >
              <h3 class="primary--text mb-0 pr-2">{{ contract.previousApproved.theaterContactEmail }}</h3>
              <h3 class="error--text pb-4 border-bottom">{{ contract.theaterContactEmail }}</h3>
            </div>
            <h3
              v-else
              class="primary--text border-bottom pb-4 mb-0"
            >
              {{ contract.theaterContactEmail }}
            </h3>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['contract'],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {},
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #e1e7f2;
}
</style>
