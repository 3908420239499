import { render, staticRenderFns } from "./ContactProvider.vue?vue&type=template&id=24e86643&scoped=true"
import script from "./ContactProvider.vue?vue&type=script&lang=js"
export * from "./ContactProvider.vue?vue&type=script&lang=js"
import style0 from "./ContactProvider.vue?vue&type=style&index=0&id=24e86643&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24e86643",
  null
  
)

export default component.exports