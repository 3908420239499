<template>
  <div>
    <div class="d-flex flex-column">
      <general :contract="contract"></general>
      <contact-theater :contract="contract"></contact-theater>
      <contact-provider :contract="contract"></contact-provider>

      <agreement-rent
        v-if="contract.type == 'Huur'"
        :contract="contract"
      ></agreement-rent>
      <agreement-partage
        v-else-if="contract.type == 'Partage'"
        :contract="contract"
      ></agreement-partage>
      <agreement-tranche
        v-else-if="contract.type == 'Staffel'"
        :contract="contract"
      ></agreement-tranche>
      <agreement-suppletion
        v-else-if="contract.type == 'Suppletie'"
        :contract="contract"
      ></agreement-suppletion>
      <agreement-buyout
        v-else-if="contract.type == 'Uitkoop'"
        :contract="contract"
      ></agreement-buyout>

      <prices :contract="contract"></prices>
      <surcharges :contract="contract"></surcharges>
      <deployment-technicians :contract="contract"></deployment-technicians>
      <extra-agreements :contract="contract"></extra-agreements>
      <default-agreement :contract="contract"></default-agreement>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import AgreementRent from './details/AgreementRent.vue';
import AgreementPartage from './details/AgreementPartage.vue';
import AgreementTranche from './details/AgreementTranche.vue';
import AgreementSuppletion from './details/AgreementSuppletion.vue';
import AgreementBuyout from './details/AgreementBuyout.vue';

import ContactProvider from './details/ContactProvider.vue';
import ContactTheater from './details/ContactTheater.vue';
import DefaultAgreement from './details/DefaultAgreement.vue';
import DeploymentTechnicians from './details/DeploymentTechnicians.vue';
import ExtraAgreements from './details/ExtraAgreements.vue';
import General from './details/General.vue';
import Prices from './details/Prices.vue';
import Surcharges from './details/Surcharges.vue';

export default {
  props: ['contract'],
  components: {
    AgreementRent,
    AgreementPartage,
    AgreementTranche,
    AgreementSuppletion,
    AgreementBuyout,

    General,
    ContactTheater,
    ContactProvider,
    DefaultAgreement,
    DeploymentTechnicians,
    ExtraAgreements,
    Prices,
    Surcharges,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState('auth', ['user']),
  },
};
</script>
