import { render, staticRenderFns } from "./DividePerformancesProvince.vue?vue&type=template&id=ced00ad0&scoped=true"
import script from "./DividePerformancesProvince.vue?vue&type=script&lang=js"
export * from "./DividePerformancesProvince.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ced00ad0",
  null
  
)

export default component.exports